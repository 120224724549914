export interface ProgressChartItems {
  date: string;
  score: number;
}

export interface ProgressChartOptions {
  target: number;
  items: ProgressChartItems[];
}

function getDefaults(): ProgressChartOptions {
  return {
    target: 0,
    items: [],
  };
}

export default class ProgressChart {
  target: number;
  items: ProgressChartItems[];

  constructor(options: Partial<ProgressChartOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.target = opts.target;
    this.items = opts.items;
  }
}
