import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';

import { useInfiniteQuery } from '@tanstack/react-query';

import Classification, { ClassificationApiName, ClassificationOptions } from 'models/Classification';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const classificationsQueryKeys = {
  index: ['classifications'] as const,
};

type IndexParams = BaseIndexQueryParams & {
  type: ClassificationApiName;
  client?: string;
};

export async function indexClassifications(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<ClassificationOptions>>(`classifications`, { params, signal }).then((res) => ({
    ...res.data,
    data: res.data.data.map((x) => new Classification(x)),
  }));
}

export const useInfiniteClassifications = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: [...classificationsQueryKeys.index, { ...params, search }],
    queryFn: ({ signal, pageParam }) =>
      indexClassifications({ pageSize: 10, ...params, search, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
