import React, { memo } from 'react';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCHistogram from 'highcharts/modules/histogram-bellcurve';
import HighchartsReact from 'highcharts-react-official';

import { colors } from 'styles/theme/colors';

import { mergeChartConfigs } from './helpers';

ChartModuleMore(Highcharts);
HCHistogram(Highcharts);

type HistogramProps = {
  formatTooltipSupertext?: (x: string | number | undefined) => string | undefined;
  formatTooltipSubtext?: (y: number | null | undefined) => string | undefined;
  config?: Highcharts.Options;
};

const Histogram = memo(
  ({
    config = {},
    formatTooltipSupertext = (x: string | number | undefined) => x as undefined,
    formatTooltipSubtext = (y: number | null | undefined) => y as undefined,
  }: HistogramProps) => {
    const baseConfig: Highcharts.Options = {
      chart: {
        type: 'column',
        spacing: [10, 0, 5, 0],
        style: {
          fontFamily: 'Lato',
        },
      },
      accessibility: {
        enabled: false,
      },
      title: undefined,
      credits: { enabled: false },
      legend: {
        enabled: false,
      },

      xAxis: {
        crosshair: false,
        labels: {
          rotation: 0,
          style: {
            fontSize: '8px',
            color: colors.black50,
            fontWeight: 'normal',
            bottom: '5px',
          },
        },
        lineWidth: 0,
      },
      yAxis: {
        gridLineWidth: 0,
        min: 0,
        title: undefined,
        labels: {
          style: {
            fontSize: '8px',
            color: colors.black50,
            fontWeight: 'normal',
          },
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.05,
          borderWidth: 0,
          groupPadding: 0,
          shadow: false,
        },
        series: {
          color: colors.primaryBlue,
          // Default inactive opacity is 0.2 (Highcharts default).
          inactiveOtherPoints: true,
        },
      },
      tooltip: {
        backgroundColor: '#4b4d5c',
        padding: 16,
        style: {
          color: colors.white,
        },
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          return `
        <b style="font-size: 12px; font-weight: bold;">${formatTooltipSupertext(this.x)}</b>
        <br />
        <div style="padding-top: 4px;">
        <span style="font-size: 12px;">${formatTooltipSubtext(this.y)}</span></div>`;
        },
      },
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={mergeChartConfigs(baseConfig, config)}
        containerProps={{ style: { flex: '1 1 0' } }}
      />
    );
  }
);

export default Histogram;
