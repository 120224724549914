export default function ActivityEscalationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M15.5840622,13.7278296 L10.1062055,3.45792944 C9.78102805,2.84735685 8.90581635,2.84735685 8.57977404,3.45792944 L3.10278222,13.7278296 C2.79576626,14.3038089 3.21348094,15 3.86643038,15 L14.8212789,15 C15.4733635,15 15.8910781,14.3038089 15.5840622,13.7278296 Z M9.3434222,13.2703326 C8.866034,13.2703326 8.4785885,12.8828871 8.4785885,12.4054989 C8.4785885,11.9281107 8.866034,11.5406652 9.3434222,11.5406652 C9.8208104,11.5406652 10.2082559,11.9281107 10.2082559,12.4054989 C10.2082559,12.8828871 9.8208104,13.2703326 9.3434222,13.2703326 Z M10.2082559,10.6758315 L8.4785885,10.6758315 L8.4785885,7.2164967 L10.2082559,7.2164967 L10.2082559,10.6758315 Z'
          id='activity-escalation-icon-path-1'
        />
      </defs>
      <g id='Olio-UI-Guide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='New-Patient-Story-Components' transform='translate(-179.000000, -5429.000000)'>
          <g id='Group-24' transform='translate(164.000000, 5410.000000)'>
            <g id='Group-9' transform='translate(15.000000, 19.000000)'>
              <rect id='Rectangle' fillOpacity='0.25' fill='#D10D44' x='0' y='0' width='24' height='24' rx='3' />
              <g id='Icon/Note-Copy' transform='translate(3.000000, 3.000000)'>
                <mask id='activity-escalation-icon-mask-2' fill='white'>
                  <use xlinkHref='#activity-escalation-icon-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Group' mask='url(#activity-escalation-icon-mask-2)' fill='#D10D44'>
                  <g id='↳-🎨Color'>
                    <rect id='Rectangle' x='0' y='0' width='18' height='18' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
