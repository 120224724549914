import { ClassificationOptions } from 'models/Classification';
import { GroupOptions } from 'models/Group';
import { UserOptions } from 'models/User';
import UserPreference, {
  getDefaults as getUserPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from 'models/UserPreference';

export interface NotificationOptions extends UserPreferenceOptions {
  type: UserPreferenceType.NOTIFICATION;
  value: {
    active: boolean;
    groupTypeApiName: string;
    scopes: {
      caseManagers: Partial<UserOptions>[];
      episodes: Partial<ClassificationOptions>[];
      locations: Partial<GroupOptions>[];
      planTypes: Partial<ClassificationOptions>[];
      utilizationManagers: Partial<UserOptions>[];
    };
    notificationSelections: {
      [key: string]: {
        [key: string]: {
          email: boolean;
        };
      };
    };
  };
}

export function getDefaults(): NotificationOptions {
  return {
    ...getUserPreferenceDefaults(),
    type: UserPreferenceType.NOTIFICATION,
    value: {
      active: false,
      groupTypeApiName: '',
      scopes: {
        episodes: [],
        caseManagers: [],
        locations: [],
        planTypes: [],
        utilizationManagers: [],
      },
      notificationSelections: {},
    },
  };
}

export default class Notification extends UserPreference {
  declare value: {
    active: boolean;
    groupTypeApiName: string;
    scopes: {
      caseManagers: Partial<UserOptions>[];
      episodes: Partial<ClassificationOptions>[];
      locations: Partial<GroupOptions>[];
      planTypes: Partial<ClassificationOptions>[];
      utilizationManagers: Partial<UserOptions>[];
    };
    notificationSelections: {
      [key: string]: {
        [key: string]: {
          email: boolean;
        };
      };
    };
  };

  constructor(options: Partial<NotificationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.value = {
      ...opts.value,
      scopes: {
        episodes: opts.value.scopes?.episodes ?? [],
        caseManagers: opts.value.scopes?.caseManagers ?? [],
        locations: opts.value.scopes?.locations ?? [],
        planTypes: opts.value.scopes?.planTypes ?? [],
        utilizationManagers: opts.value.scopes?.utilizationManagers ?? [],
      },
    };
  }
}
