import styled, { css } from 'styled-components';

const formStyles = css`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  ${formStyles};
`;
