import { ReactNode, useEffect } from 'react';
import { DropzoneState, FileError, FileRejection, useDropzone } from 'react-dropzone';

import { useToastActions } from 'stores/toastStore';

const ALLOWED_TYPES: { [key: string]: string[] } = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'image/*': ['.jpeg', '.jpg', '.png', '.tiff'],
};

type FileDropZoneInput = {
  children: (state: DropzoneState) => ReactNode;
  onFilesSelected: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;

  allowedTypes?: { [key: string]: string[] };
  currentFileCount?: number;
  disabled?: boolean;
  maxFiles?: number;
  noClick?: boolean;
  noDrag?: boolean;
  notifyRejections?: boolean;
};

export default function FileDropZone(props: FileDropZoneInput) {
  const {
    allowedTypes = ALLOWED_TYPES,
    children,
    currentFileCount = 0,
    disabled = false,
    maxFiles = 10,
    noClick = false,
    noDrag = false,
    notifyRejections = true,
    onFilesSelected,
  } = props;

  const { addToast } = useToastActions();

  const remainingAllowedFilesCount = maxFiles - currentFileCount;

  const quantityValidator: (file: File) => FileError | null = (file: File) => {
    if (currentFileCount >= maxFiles) {
      return {
        code: 'too-many-files',
        message: 'Too many files',
        file: file,
      };
    }
    return null;
  };

  const dropzoneProps = useDropzone({
    onDrop: onFilesSelected,
    accept: allowedTypes,
    maxFiles: remainingAllowedFilesCount,
    noClick,
    noDrag,
    disabled,
    validator: quantityValidator,
  });

  useEffect(() => {
    if (notifyRejections && dropzoneProps.fileRejections.length) {
      let tooManyFilesNotified = false;

      dropzoneProps.fileRejections.forEach((rejection) => {
        rejection.errors.forEach((err) => {
          let text = '';
          switch (err.code) {
            case 'too-many-files':
              if (!tooManyFilesNotified) {
                tooManyFilesNotified = true;
                text = `A maximum of ${maxFiles} files can be uploaded at a time.`;
                addToast({ text });
              }
              break;
            case 'file-invalid-type':
              text = `${rejection.file.name} is not valid. File must be one of the following types; ${Object.keys(
                allowedTypes
              ).join(', ')}`;
              addToast({ text });
              break;
            default:
          }
        });
      });
    }
  }, [notifyRejections, dropzoneProps.fileRejections, maxFiles, allowedTypes, addToast]);

  return <>{children(dropzoneProps)}</>;
}
