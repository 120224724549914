import { format } from 'date-fns';

import Activity from 'models/Activity';

export interface ChartItem {
  date: Date;
  score: number;
}

export interface ChartOptions {
  title: string;
  yAxis: string;
  question?: { kind?: string };
  data: ChartItem[];
  min?: number;
  max?: number;
  allowDecimals?: boolean;
}

function getDefaults(): ChartOptions {
  return {
    title: '',
    yAxis: '',
    data: [],
  };
}

export default class Chart {
  title: string;
  yAxis: string;
  data: ChartItem[];
  question?: { kind?: string };
  min: number;
  max: number;
  allowDecimals: boolean;

  constructor(options: Partial<ChartOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.data = [...opts.data].sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    this.question = opts.question;
    this.title = opts.title;
    this.yAxis = opts.yAxis;
    this.min = opts.min || this.findMin();
    this.max = opts.max || this.findMax();
    this.allowDecimals = opts.allowDecimals || this.shouldAllowDecimals();
  }

  get scores() {
    return this.data.filter((item) => item.score).map((item) => Number(item.score));
  }

  get dates() {
    return this.data.filter((item) => item.score).map((item) => format(item.date, 'MM/dd'));
  }

  dataByAxes() {
    return this.data
      .filter((item) => item.score)
      .map((item) => {
        return { x: format(item.date, 'MM/dd'), y: Number(item.score) };
      });
  }

  findMax() {
    if (this.isRangeQuestion()) return 10;
    return Math.max(...this.scores) + 10;
  }

  findMin() {
    if (this.isRangeQuestion()) return 0;
    const calculatedMin = Math.min(...this.scores) - 10;

    return calculatedMin < 0 ? 0 : calculatedMin;
  }

  shouldAllowDecimals() {
    if (this.isRangeQuestion()) return false;
    return true;
  }

  isRangeQuestion() {
    return this.question && this.question.kind === 'range';
  }
}

export function getChartData(activities: Activity[], questions): Chart[] {
  if (!questions || !activities) return [];

  return questions
    .filter((q) => q.config?.chart?.chartName)
    .map((question) => {
      const data = activities
        .map((progressUpdate) => {
          const matchingQuestion = progressUpdate.data.questions.find((plq) => plq.id === question.id);
          return (
            matchingQuestion && {
              date: progressUpdate.createdAt,
              score: matchingQuestion.answer.text,
            }
          );
        })
        .filter((q) => q);

      return new Chart({
        data,
        title: question.config.chart.chartName,
        yAxis: question.config.chart.yAxis,
        min: question.config.min,
        max: question.config.max,
        question: question,
      });
    })
    .filter((chart) => chart.data.length > 0);
}
