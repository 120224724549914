import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { dateAtTime } from 'lib/date';
import Escalation from 'models/Escalation';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { useAcknowledgeEscalation } from 'services/api/escalation';
import { colors } from 'styles/theme/colors';
import { Label } from 'styles/typography';
import CheckboxOffIcon from 'svg/CheckboxOffIcon';
import CheckboxOnIcon from 'svg/CheckboxOnIcon';
import CircleCheckIcon from 'svg/CircleCheckIcon';
import PriorityFlag from 'svg/PriorityFlag';
import WarnIcon from 'svg/WarnIcon';

import Button from './Button';
import StandardModal, { Actions, ModalContent } from './StandardModal';

type Props = {
  escalation: Escalation;
  locationEpisode: LocationEpisode;
  profile: Profile;
};

export function EscalationFooter(props: Props) {
  const { escalation, locationEpisode, profile } = props;
  const [showModal, setShowModal] = useState(false);
  const [tog, setChecked] = useState(false);

  const { mutate, isPending: updatingEscalation } = useAcknowledgeEscalation();

  const canAcknowledge =
    profile.permissions.escalationEdit &&
    (escalation.isDefault ? profile.isAcute : profile.allowedGroupIds.includes(locationEpisode.groupId));

  const { acknowledged, acknowledgedAt, acknowledgedBy } = escalation;
  const showLabel = !canAcknowledge && !acknowledged;
  const showCheckbox = canAcknowledge && !acknowledged;

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setChecked(false);
  }, []);

  const handleAcknowledgeEscalation = () => {
    mutate(
      { id: escalation.id },
      {
        onSuccess: () => {
          handleCloseModal();
        },
      }
    );
  };

  const handleChecked = useCallback(() => {
    if (escalation.isDefault) {
      setShowModal(true);
    } else {
      handleAcknowledgeEscalation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escalation]);

  const color = escalation.isPriorityNote ? colors.black : colors.white;
  const backgroundColor = escalation.isPriorityNote ? colors.white : colors.accentRed;
  const escalationColor = escalation.isPriorityNote ? colors.black : colors.white;
  const acknowledgedText = `Acknowledged by ${acknowledgedBy} on ${dateAtTime(acknowledgedAt)}`;

  return (
    <EscalationBar $isPriority={escalation.isPriorityNote} $isAcknowledged={acknowledged}>
      {showLabel && (
        <EscalationLabelContainer>
          {escalation.isPriorityNote ? (
            <>
              <PriorityFlag color={colors.accentRed} />
              <Label>Marked as priority </Label>
            </>
          ) : (
            <>
              <WarnIcon width={12} height={12} color={colors.white} />
              <EscalationLabel>Escalation</EscalationLabel>
            </>
          )}
        </EscalationLabelContainer>
      )}

      {showCheckbox && (
        <StyledCheckbox $color={color} onClick={handleChecked}>
          <CheckboxContainer $backgroundColor={backgroundColor}>
            {tog && <CheckboxOnIcon color={colors.accentRed} height={12} width={12} />}
            {!tog && <CheckboxOffIcon color={color} height={12} width={12} />}
          </CheckboxContainer>
          <Label>I acknowledge that I have received and read this note</Label>
        </StyledCheckbox>
      )}

      {acknowledged && (
        <EscalationActionContainer>
          <CircleCheckIcon color={escalationColor} width={12} height={12} />
          <AcknowledgedLabel color={escalationColor}>{acknowledgedText}</AcknowledgedLabel>
        </EscalationActionContainer>
      )}
      {/* icon={ blueCheck } */}
      {showModal && (
        <StandardModal title='Confirm Acknowledgement' onCancel={handleCloseModal}>
          <ModalContent>Are you sure you want to acknowledge this escalation?</ModalContent>
          <Actions>
            <Button variant='ghost' onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button onClick={handleAcknowledgeEscalation} loading={updatingEscalation}>
              Confirm
            </Button>
          </Actions>
        </StandardModal>
      )}
    </EscalationBar>
  );
}

const EscalationBar = styled.div<{
  $isPriority: boolean;
  $isAcknowledged: boolean;
}>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ $isPriority, $isAcknowledged }) => {
    if ($isPriority) {
      return $isAcknowledged ? colors.black05 : colors.accentRed10;
    }
    return colors.accentRed;
  }};
  padding: 10px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;

const EscalationLabel = styled(Label)`
  color: white;
`;

const EscalationLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  & > svg {
    margin-right: 6px;
  }
`;

const StyledCheckbox = styled.div<{ $color: string }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  & label {
    margin-left: 10px;
    color: ${({ $color }) => $color};
    cursor: pointer;
  }
`;
const CheckboxContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex: 0;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const EscalationActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AcknowledgedLabel = styled(Label)<{ color: string }>`
  margin-left: 6px;
  color: ${({ color }) => color};
`;
