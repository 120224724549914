import { useEffect, useState } from "react";

export function useWasTrue(val: boolean) {
  const [wasTrue, setWasTrue] = useState(false);

  useEffect(() => {
    if (val) {
      setWasTrue(true);
    }
  }, [val]);

  return wasTrue;
}
