import { SuggestionDataItem } from 'react-mentions';

import { BodySmall, BodySmallBold } from 'styles/typography';

export default function renderSuggestionDataItem(sug: SuggestionDataItem) {
  const { display } = sug;
  const userName = display!.split(' - ')[0];
  const groupName = display!.split(' - ')[1];
  return (
    <>
      <BodySmallBold>{userName}</BodySmallBold>
      <BodySmall>&nbsp;- {groupName}</BodySmall>
    </>
  );
}
