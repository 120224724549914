import styled from 'styled-components';

import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import StandardModal, { ColorThemeName } from 'components/shared/StandardModal';
import { H3 } from 'styles/typography';

export type ConfirmPatientRemovalModalProps = {
  patientName: string;
  onCancel: () => void;
  onConfirm: () => void;
  colorTheme?: ColorThemeName;
};

export default function ConfirmPatientRemovalModal(props: ConfirmPatientRemovalModalProps) {
  const { patientName, onCancel, onConfirm, colorTheme } = props;

  return (
    <StandardModal colorTheme={colorTheme} onCancel={onCancel} title='Confirm Patient Removal'>
      <Content>
        <H3>Are you sure you want to remove {patientName}?</H3>
        <Paragraph>
          Once a patient is removed, they can no longer be seen on the Patient Portfolio or All Patients pages.
        </Paragraph>
      </Content>
      <StyledButtonGroup>
        <Button variant='ghost' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='danger' onClick={onConfirm}>
          Remove Patient
        </Button>
      </StyledButtonGroup>
    </StandardModal>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled.p`
  display: block;
`;
