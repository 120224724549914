import Patient, { PatientOptions } from 'models/Patient';
import http from 'services/http';

export async function createPatient(payload: Record<string, any>) {
  return http.post<PatientOptions>(`/patients`, payload).then((response) => new Patient(response.data));
}

export async function updatePatient({ id, ...params }: Record<string, any>) {
  return http.put<PatientOptions>(`/patients/${id}`, params).then((response) => new Patient(response.data));
}
