import styled from 'styled-components';

import { Label, LabelBold } from 'styles/typography';

export const Blurb = styled.div<{ color?: string; $border?: boolean }>`
  background-color: white;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border: 1px solid var(--black-10);
`;

export const DividedBlurb = styled(Blurb)`
  > * + * {
    border-top: 1px solid var(--black-10);
  }
`;

export const ExpandCollapse = styled(Label)`
  cursor: pointer;
  color: var(--primary-blue);
  user-select: none;
  font-size: 14px;
  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 12px;
  }
`;

export const RedBold = styled(LabelBold)`
  color: var(--accent-red);
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

export const SkeletonBlurb = styled.div`
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  background: linear-gradient(-45deg, #e6e6e6, #e6e6e6 50%, #eee 75%, #e6e6e6 75%);
  background-size: 200% 200%;
  background-attachment: fixed;
  animation: gradient 2s ease infinite;
`;
