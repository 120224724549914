import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import ButtonGroup from 'components/shared/ButtonGroup';
import { colors } from 'styles/theme/colors';
import { devices } from 'styles/theme/devices';
import { dimensions } from 'styles/theme/dimensions';
import { BodySmallBold } from 'styles/typography';

export enum ColorThemeName {
  Primary = 'primary',
  PrimaryRed = 'primaryRed',
  Danger = 'danger',
  Info = 'info',
}
type ColorTheme = {
  background: string;
  font: string;
  closeButton: string;
};

type StandardModalType = {
  title: string;
  children: ReactNode[] | ReactNode;
  onCancel?: () => void;
  colorTheme?: ColorThemeName;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
};
export default function StandardModal(props: StandardModalType) {
  const { colorTheme, title, onCancel, children, showCloseButton = true, closeOnBackdropClick = false } = props;

  const colorMap: { [Property in ColorThemeName]: ColorTheme } = {
    primary: {
      background: colors.black05,
      font: colors.primaryBlue,
      closeButton: colors.black75,
    },
    primaryRed: {
      background: colors.black05,
      font: colors.accentRed,
      closeButton: colors.black75,
    },
    danger: {
      background: colors.accentRed,
      font: colors.white,
      closeButton: colors.white,
    },
    info: {
      background: colors.black75,
      font: colors.white,
      closeButton: colors.white,
    },
  };
  const colortheme = colorMap[colorTheme ?? 'primary'] || colorMap.primary;

  // The modal is a child of the backdrop. When close on backdrop click is
  // enabled clicking the modal itself also triggers the close. This method
  // prevents the event from bubbling up through the DOM so our modal clicks
  // do not trigger a close. Only clicking the backdrop should do that.
  const handleBackdropClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!closeOnBackdropClick || (e.target as HTMLDivElement).dataset.component !== 'modalBackdrop') {
      return;
    }

    onCancel?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Backdrop data-component='modalBackdrop' data-cy='standardModal' onClick={handleBackdropClick}>
      <StyledModal>
        <StyledHeader data-cy='modalHeader' colortheme={colortheme}>
          <HeaderContent>{title}</HeaderContent>
          {showCloseButton && (
            <CloseButton onClick={onCancel} colortheme={colortheme}>
              &times;
            </CloseButton>
          )}
        </StyledHeader>
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </Backdrop>
  );
}

const StyledHeader = styled.div<{ colortheme: ColorTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  height: 64px;
  font-size: 20px;
  font-weight: var(--font-weight);

  @media ${({ theme }) => theme.devices.desktop} {
    height: 100px;
    font-size: 24px;
  }

  padding: 20px;
  background-color: ${({ colortheme }) => colortheme.background};

  color: ${({ colortheme }) => colortheme.font};

  @media ${devices.desktop} {
    border-radius: ${dimensions.borderRadius} ${dimensions.borderRadius} 0px 0px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div<{ colortheme: ColorTheme }>`
  position: absolute;
  right: 0px;
  top: 0px;

  height: 48px;
  width: 48px;

  color: ${({ colortheme }) => colortheme.closeButton};
  font-size: 32px;
  font-weight: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledModal = styled.div`
  z-index: 2;
  background-color: ${colors.white};
  position: relative;
  margin-top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media ${devices.desktop} {
    flex: 0;
    flex-basis: 480px;
    margin-top: 80px;
    max-width: 560px;
    border-radius: ${dimensions.borderRadius};
    min-height: auto;
  }
`;

const Backdrop = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.black50};
  overflow: auto;
`;

const StyledContent = styled.div`
  padding: 24px 40px 40px;
  flex: 1;
`;

export const ModalContent = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

export const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export const PatientName = styled(BodySmallBold)`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;
