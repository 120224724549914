import { getDaysInMonth } from 'date-fns/getDaysInMonth';

import { FormMeta, FormValues } from 'components/intake/types';
import DatePicker from 'components/shared/form/DatePicker';
import FormSection from 'components/shared/form/FormSection';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import Input from 'components/shared/form/Input';
import InputGroup from 'components/shared/form/InputGroup';
import SectionHeader from 'components/shared/form/SectionHeader';
import { useForm } from 'context/form';

export const GENDERS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Unknown', value: 'unknown' },
];

export type PatientInfoSectionProps = {
  disabled: boolean;
};

export function getMaxDays(year?: string, month?: string) {
  const yearValue = Number(year || new Date().getFullYear());
  const monthValue = month ? Number(month) - 1 : new Date().getMonth();

  return getDaysInMonth(new Date(yearValue, monthValue));
}

function PatientInfoSection({ disabled }: PatientInfoSectionProps) {
  const { values, errors, setValue, validateField } = useForm<FormValues, FormMeta>();

  return (
    <FormSection>
      <SectionHeader id='PatientInfo'>Patient Information</SectionHeader>
      <InputGroup title='Patient Name' error={errors.name}>
        <Input
          placeholder='Patient Name'
          name='name'
          value={values.name ?? ''}
          onChange={(event) => setValue('name', event.target.value)}
          $hasError={!!errors.name}
          disabled={disabled}
        />
      </InputGroup>
      <InputGroup title='Date of Birth' error={errors.dateOfBirth} data-cy='dob'>
        <DatePicker
          maxDate={new Date()}
          selected={values.dateOfBirth ?? null}
          onChange={(date) => {
            setValue('dateOfBirth', date);
          }}
          $hasError={!!errors.actualDischargeDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          onBlur={() => validateField('dateOfBirth', values)}
          disabled={disabled}
        />
      </InputGroup>
      <InputGroup title='Gender' error={errors.sex} data-cy='gender'>
        <Select
          inputId='gender-select'
          placeholder='Select Gender'
          name='sex'
          value={values.sex}
          onChange={(value) => setValue('sex', value)}
          hasError={!!errors.sex}
          options={GENDERS}
          disabled={disabled}
          getOptionValue={(prop) => prop.value}
          getOptionLabel={(prop) => prop.label}
        />
      </InputGroup>
    </FormSection>
  );
}

export default PatientInfoSection;
