export default function InfoIconHollow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_410_8495)'>
        <path
          d='M6 11.625C9.1066 11.625 11.625 9.1066 11.625 6C11.625 2.8934 9.1066 0.375 6 0.375C2.8934 0.375 0.375 2.8934 0.375 6C0.375 9.1066 2.8934 11.625 6 11.625Z'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 3.75C6.41421 3.75 6.75 3.41421 6.75 3C6.75 2.58579 6.41421 2.25 6 2.25C5.58579 2.25 5.25 2.58579 5.25 3C5.25 3.41421 5.58579 3.75 6 3.75Z'
          fill='#0F1226'
        />
        <path d='M6 9.375V5.25' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_410_8495'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
