import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { QuestionKind, QuestionType } from 'models/QuestionTemplate';

import NumericQuestion from './NumericQuestion';
import RangeQuestion from './RangeQuestion';
import StatusQuestion from './StatusQuestion';
import YesNoQuestion from './YesNoQuestion';

export type AnswerType = QuestionType & {
  answer: {
    text: string;
    weight: 'positive' | 'negative' | 'n/a';
  };
};

interface QuestionListProps {
  questions: QuestionType[] | AnswerType[];
  setAllQuestionsAnswered?: (b: boolean) => void;
  setSomeQuestionChanged?: (b: boolean) => void;
  profile?: Profile;
  latestStatus?: string;
  locationEpisode?: LocationEpisode;
  noteValues?: ActivityInputValues;
  setNoteValues?: React.Dispatch<React.SetStateAction<ActivityInputValues>>;
  resetNoteValues?: () => void;
  setIsUploading?: (b: boolean) => void;
}

export type QuestionListRefType = {
  getAnswers: () => AnswerType[];
  reset: () => void;
};

function QuestionList(props: QuestionListProps, _ref: React.Ref<QuestionListRefType>) {
  const {
    questions: _questions,
    setAllQuestionsAnswered,
    setSomeQuestionChanged,
    profile,
    latestStatus = '',
    locationEpisode,
    noteValues,
    setNoteValues,
    resetNoteValues,
    setIsUploading,
  } = props;

  const questions = _questions.sort((a, b) => a.order - b.order);

  const [values, setValues] = useState<string[]>(questions.map((x) => (x as AnswerType).answer?.text ?? ''));

  const [inadequateExplanation, setInadequateExplanation] = useState(false);

  useEffect(() => {
    if (questions.length != values.length) setValues(questions.map((x) => (x as AnswerType).answer?.text ?? ''));
  }, [questions, values.length]);

  useEffect(() => {
    setAllQuestionsAnswered?.(
      values.every((x, i) => !!x && !(questions[i].kind == QuestionKind.STATUS && inadequateExplanation))
    );
    setSomeQuestionChanged?.(values.some((x, i) => (questions[i] as AnswerType).answer?.text != x));
  }, [inadequateExplanation, questions, setAllQuestionsAnswered, setSomeQuestionChanged, values]);

  const replace = (v: string, i: number) => {
    const newvalues = [...values];
    newvalues[i] = v;
    setValues(newvalues);
  };

  useImperativeHandle(_ref, () => ({
    getAnswers: () => {
      return questions.map((question, i) => ({
        ...question,
        answer: {
          text: values[i],
          weight:
            question.kind !== QuestionKind.YES_NO
              ? 'n/a'
              : question.config.positive?.toLowerCase() === values[i].toLowerCase()
                ? 'positive'
                : 'negative',
        },
      })) as AnswerType[];
    },
    reset: () => {
      setValues(questions.map((x) => (x as AnswerType).answer?.text ?? ''));
      resetNoteValues?.();
    },
  }));

  return (
    <>
      {questions.map((question, i) => {
        const common = {
          text: question.text,
          index: i + 1,
          value: values[i],
          setValue: (v: string) => replace(v, i),
        };
        if (question.kind == QuestionKind.NUMERIC) {
          return <NumericQuestion key={i} {...common} />;
        }
        if (question.kind == QuestionKind.YES_NO) {
          return <YesNoQuestion key={i} {...common} />;
        }
        if (question.kind == QuestionKind.RANGE) {
          return <RangeQuestion key={i} max={10} {...common} />;
        }
        if (question.kind == QuestionKind.STATUS) {
          return (
            <StatusQuestion
              key={i}
              {...common}
              profile={profile!}
              latestStatus={latestStatus}
              locationEpisode={locationEpisode!}
              setInadequateExplanation={setInadequateExplanation}
              noteValues={noteValues}
              setNoteValues={setNoteValues}
              setIsUploading={setIsUploading}
            />
          );
        }
        return <></>;
      })}
    </>
  );
}

export default forwardRef(QuestionList);
