import React from 'react';
import styled from 'styled-components';

import { Blurb, SkeletonBlurb } from 'components/Containers';
import { Label } from 'styles/typography';

export default function PatientOverviewSkeleton() {
  return (
    <Blurb>
      <HeadSection>
        <HeadSectionText>
          <HeaderContainer>
            <SkeletonBlank $height={17} $width={100} />
          </HeaderContainer>
          <HeadDate>
            <SkeletonBlank $height={12} $width={130} />
          </HeadDate>
        </HeadSectionText>
        <SkeletonBlank $height={28} $width={100} />
      </HeadSection>
      {[4, 2].map((length, i) => (
        <TableSection key={i}>
          <TableHeader>
            <SkeletonBlank $height={12} $width={150 + i * 70} />
          </TableHeader>
          {Array.from({ length })
            .fill(0)
            .map((_, j) => (
              <Row key={j}>
                <Column>
                  <SkeletonBlank $height={14} $width={14} $rounding={3} />
                  <SkeletonBlank $height={12} $width={80} />
                </Column>
                <Column>
                  <SkeletonBlank $height={12} $width={130} />
                </Column>
              </Row>
            ))}
        </TableSection>
      ))}
    </Blurb>
  );
}

const HeadSection = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
`;

const HeadSectionText = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const HeadDate = styled(Label)`
  margin-top: 6px;
  color: var(--black-50);
`;
const TableSection = styled.div`
  border-top: 1px solid var(--black-10);
  padding-bottom: 8px;
`;
const TableHeader = styled.div`
  padding: 24px;
  color: var(--black-50);
`;
const Row = styled.div`
  display: flex;
  padding: 0 24px 16px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
  $rounding?: number;
}>`
  border-radius: ${({ $rounding, $height }) => ($rounding ?? $height) + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
`;
