export default function PriorityCountIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='12' height='12' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.05322 3.08673L6.35295 12.2663'
        stroke='#0F1226'
        strokeWidth='0.952519'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9269 5.18384C9.77733 7.10869 7.16761 4.75069 5.5709 7.56424L3.06079 3.21652C4.65673 0.402198 7.28652 2.79109 9.41598 0.836121L11.9269 5.18384Z'
        stroke='#0F1226'
        strokeWidth='0.952519'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
