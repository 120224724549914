import { MutableRefObject, ReactNode, useCallback } from 'react';
import { Mention, MentionItem, MentionsInput, SuggestionDataItem } from 'react-mentions';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';

import renderSuggestionDataItem from './renderSuggestionDataItem';
import useTaggableUsersQuery from './useTaggableUsersQuery';

const renderSuggestion = (
  sug: SuggestionDataItem,
  _search: string,
  _highlightedDisplay: ReactNode,
  _index: number,
  focused: boolean
) => <UserTagContainer $focused={focused}>{renderSuggestionDataItem(sug)}</UserTagContainer>;

type TaggableTextAreaType = {
  disabled: boolean;
  inputRef: MutableRefObject<any>;
  locationEpisodeId?: string;
  placeholder?: string;
  taggable?: boolean;
  values: { text: string; plaintext: string; mentions: MentionItem[] };
  onChange: (value: { text: string; plaintext: string; mentions: MentionItem[] }) => void;
};

export function TaggableTextArea(props: TaggableTextAreaType) {
  const { values, onChange, disabled, inputRef, locationEpisodeId, placeholder, taggable } = props;

  const fetchTaggableUsers = useTaggableUsersQuery(locationEpisodeId);

  const displayTransform = useCallback((_: any, display: any) => {
    const userName = display.split(' - ')[0];

    return `@${userName}`;
  }, []);

  const handleChange = (
    _event: { target: { value: string } },
    text: string,
    plaintext: string,
    mentions: MentionItem[]
  ) => {
    onChange({ text, plaintext, mentions });
  };

  const text = values.text ?? '';
  const taggablePlaceholderText = '. Type @ to tag a user';
  const fullPlaceholderText =
    (placeholder ?? 'Start typing here to leave a note') + (taggable ? `${taggablePlaceholderText}` : '');

  return (
    <TextAreaWrapper>
      <TextArea
        disabled={disabled}
        value={text}
        placeholder={fullPlaceholderText}
        inputRef={inputRef}
        onChange={handleChange}
        allowSpaceInQuery
        suggestionsPortalHost={document.body}>
        <Mention
          appendSpaceOnAdd
          trigger='@'
          data={taggable ? fetchTaggableUsers : []}
          markup='@{{__display__||__id__}}'
          displayTransform={displayTransform}
          renderSuggestion={renderSuggestion}
        />
      </TextArea>
    </TextAreaWrapper>
  );
}

const TextArea = styled(MentionsInput)`
  width: 100%;
  min-height: 100%;
  textarea {
    &::placeholder {
      color: ${colors.black25};
    }
    vertical-align: top;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    color: transparent;
    caret-color: ${colors.black};
  }
`;

const UserTagContainer = styled.div<{ $focused: boolean }>`
  display: flex;
  padding: 10px 12px;
  background-color: ${({ $focused }) => ($focused ? colors.primaryBlue10 : colors.white)};
`;

const TextAreaWrapper = styled.div`
  height: 100%;

  ${TextArea} {
    &__highlighter {
      font-size: 14px !important;
      line-height: 24px !important;

      border: none !important;
      outline: none;
    }

    &__highlighter strong {
      color: ${colors.primaryBlue};
      background-color: ${colors.primaryBlue10};
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      font-weight: 700 !important;
      letter-spacing: normal !important;
    }

    &__highlighter__substring {
      visibility: inherit !important;
      color: ${colors.black};
    }
  }
`;
