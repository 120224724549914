import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import Tooltip from 'components/shared/Tooltip';
import Upload from 'hooks/useDirectUpload/Upload';
//since we export Attachment as the default export, we can import it as AttachmentModel
import AttachmentModel from 'models/Attachment';
import { updateAttachment } from 'services/api/attachments';
import { colors } from 'styles/theme/colors';
import { BodySmallBold } from 'styles/typography';
import TrashIcon from 'svg/TrashIcon';

import DocumentLink from './DocumentLink';
import DocumentTypeMenu from './DocumentTypeMenu';
import ProgressBar from './ProgressBar';

const AttachmentContainer = styled.div<{
  $error: boolean;
  $showUploadProgress: boolean;
}>`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.black02};
  border: 0.5px solid ${({ theme, $error }) => ($error ? theme.colors.accentRed : theme.colors.black15)};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  padding: ${({ $showUploadProgress }) => ($showUploadProgress ? '8px 16px' : '4px 16px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32px;
`;

const AttachmentInformation = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;

  > :first-child {
    flex: 1;
    width: 0;
  }
`;

const Filename = styled(BodySmallBold)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const ErrorText = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.accentRed};
  font-size: 12px;
  flex: 1 0 auto;
  margin-right: 8px;
`;

const ProgressContainer = styled.div`
  margin-top: 4px;
`;

type Props = {
  attachment: AttachmentModel | Upload;
  error?: boolean;
  disabled?: boolean;
  uploadProgress?: number;
  onDeleteAttachment?: (id: string) => void;
  canModifyDocumentType?: boolean;
  onDocumentTypeChange?: (id: string, docType: string | null) => void;
};

export default function Attachment({
  attachment,
  error = false,
  disabled = false,
  uploadProgress,
  onDeleteAttachment,
  canModifyDocumentType = true,
  onDocumentTypeChange,
}: Props) {
  const showDocTypeIcon = !error && !uploadProgress;
  const showUploadProgress = !!uploadProgress && !error;

  const { mutate } = useMutation({
    mutationFn: updateAttachment,
  });

  const [activeDocType, setDocType] = useState(attachment.docType);
  useEffect(() => {
    setDocType(attachment.docType);
  }, [attachment.docType]);

  const changeDocType = canModifyDocumentType
    ? onDocumentTypeChange ??
      ((id: string, docType: string | null) => {
        mutate(
          { id, docType },
          {
            onSuccess: () => {
              setDocType(docType);
            },
          }
        );
      })
    : undefined;

  return (
    <AttachmentContainer $error={error} $showUploadProgress={showUploadProgress}>
      <AttachmentInformation>
        <DocumentLink entry={attachment} disabled={disabled}>
          <Filename>{attachment.filename}</Filename>
        </DocumentLink>

        {error && <ErrorText>(Upload Error)</ErrorText>}

        {showDocTypeIcon && (
          <DocumentTypeMenu
            activeDocType={activeDocType}
            setActiveDocType={(s: string | null) => changeDocType?.(attachment.id, s)}
            disabled={disabled}
          />
        )}

        {onDeleteAttachment && (
          <div>
            <Tooltip text='Remove Attachment'>
              <TrashIcon
                onClick={() => onDeleteAttachment(attachment.id)}
                width={12}
                height={12}
                color={colors.black}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        )}
      </AttachmentInformation>
      {showUploadProgress && (
        <ProgressContainer>
          <ProgressBar value={uploadProgress} showLabel={false} />
        </ProgressContainer>
      )}
    </AttachmentContainer>
  );
}
