export default function ThumbsUpIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='update-progress-(stoplight)' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Form' transform='translate(-380.000000, -287.000000)' fill='currentColor' fillRule='nonzero'>
          <g id='Group-18' transform='translate(290.000000, 108.000000)'>
            <g id='Group-31' transform='translate(52.000000, 132.000000)'>
              <g id='Group-33' transform='translate(0.500000, 35.000000)'>
                <g id='thumb-up' transform='translate(37.500000, 12.000000)'>
                  <path
                    d='M2.66666667,7.33333333 L0.666666667,7.33333333 C0.298476833,7.33333333 0,7.63181017 0,8 L0,15.3333333 C0,15.7015232 0.298476833,16 0.666666667,16 L2.66666667,16 L2.66666667,7.33333333 Z'
                    id='ThumbsUp'
                  />
                  <path
                    d='M15.026,6.93333333 C14.5194776,6.34114297 13.7792653,6.00014356 13,5.99999999 L8.66666667,5.99999999 L8.66666667,2.66666667 C8.66666667,1.196 8.13733333,-1.40418663e-08 6.66666667,-1.40418663e-08 C6.36595193,-1.40418663e-08 6.1024213,0.201205003 6.02333333,0.491333333 L4,7.33333333 L4,16.0000292 L12.284,16.0000292 C13.6025755,16.0061407 14.72549,15.0428311 14.92,13.7386667 L15.6373333,9.072 C15.7547938,8.3040966 15.5315612,7.52314731 15.026,6.93333333 Z'
                    id='ThumbsUp'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
