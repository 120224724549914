import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface PatientMatchCollaborationOptions extends ActivityOptions {
  type: ActivityType.PATIENT_MATCH_COLLABORATION;
  data: Partial<
    BaseActivityDataOptions & {
      collaboratorType: string;
      collaboratorName: string;
    }
  >;
}

export function getDefaults(): PatientMatchCollaborationOptions {
  const activityDefaults = getActivityDefaults();
  return {
    ...activityDefaults,
    type: ActivityType.PATIENT_MATCH_COLLABORATION,
    data: {
      ...activityDefaults.data,
      collaboratorType: '',
      collaboratorName: '',
    },
  };
}

export default class PatientMatchCollaboration extends Activity {
  declare type: ActivityType.PATIENT_MATCH_COLLABORATION;
  declare data: BaseActivityData & { collaboratorType: string; collaboratorName: string };

  constructor(options: Partial<PatientMatchCollaborationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Patient Match Collaboration';
  }
}
