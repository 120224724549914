import { PropsWithChildren } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClientProvider } from '@tanstack/react-query';

import AnalyticsProvider from 'components/AnalyticsProvider';
import AppLoadingWrapper from 'components/AppLoadingWrapper';
import AxiosProvider from 'components/AxiosProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import Toaster from 'components/shared/Toaster';
import AuthProvider from 'context/auth';
import { EnvironmentProvider, useEnvironment } from 'context/environment';
import { ProfileProvider } from 'context/profile';
import { CrossDomainLocalStorageCache } from 'lib/cache';
import { queryClient } from 'services/http';

import SessionMonitor from './SessionMonitor';
import ThemeProvider from './ThemeProvider';

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
};

export function RootProviders({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { environment } = useEnvironment();
  if (!environment) return <></>;

  return (
    <Auth0Provider
      domain={environment.AUTH0_ISSUER_BASE_URL}
      clientId={environment.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: typeof window !== 'undefined' ? `${window.location.origin}/auth/callback` : undefined,
        scope: 'openid profile email offline_access',
        audience: environment.AUTH0_AUDIENCE,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cache={new CrossDomainLocalStorageCache()}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
      cookieDomain={environment.AUTH0_COOKIE_DOMAIN}>
      <AuthProvider>
        <AxiosProvider>
          <ProfileProvider>
            <AnalyticsProvider>
              <AppLoadingWrapper>
                <Toaster />
                {children}
              </AppLoadingWrapper>
            </AnalyticsProvider>
          </ProfileProvider>
        </AxiosProvider>
      </AuthProvider>
    </Auth0Provider>
  );
}

export default function Root() {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <EnvironmentProvider>
            <RootProviders>
              <Outlet />
              <SessionMonitor />
            </RootProviders>
          </EnvironmentProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
