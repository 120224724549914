import React from 'react';

const TagIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  ({ width = 16, height = 18, ...props }, forwardedRef) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 12 12'
        overflow='visible'
        {...props}
        ref={forwardedRef}>
        <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
          <path d='M.35 1.15h4.9l5.271 5.271a1.05 1.05 0 0 1 0 1.485l-3.415 3.415a1.05 1.05 0 0 1-1.485 0L.351 6.05v-4.9Z' />
          <path d='M3.5 5.35a1.05 1.05 0 1 0 0-2.1 1.05 1.05 0 0 0 0 2.1Z' />
        </g>
      </svg>
    );
  }
);

export default TagIcon;
