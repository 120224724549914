import { MultiSelect, MultiSelectType } from 'components/shared/form/InfiniteScrollDropdown';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import { FilterOption } from 'stores/types';

export type FilterMultiSelectProps = Partial<MultiSelectType<FilterOption>> & {
  name: string;
  getOptions?: {
    hook: any;
    params?: object;
    optionsToPrepend?: FilterOption[];
  };
};

const FilterMultiSelect = (props: FilterMultiSelectProps) => {
  const { name, getOptions, getOptionLabel, getOptionValue, placeholder, value, onChange } = props;

  const asyncOptions = getOptions?.hook(getOptions?.params);

  return (
    <div data-cy={`${name}Filter`}>
      <MultiSelect
        {...asyncOptions}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        placeholder={placeholder}
        onChange={(val: FilterOption[]) => onChange?.(new FilterValueArray(val))}
        value={value ?? []}
        prependedOptions={getOptions?.optionsToPrepend || []}
      />
    </div>
  );
};

export default FilterMultiSelect;
