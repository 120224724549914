import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import { useMenuContext } from 'components/shared/menu';
import { calculateMenuPosition } from 'components/shared/menu/helpers';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

export type MenuPosition = 'left' | 'right' | 'center';

export interface MenuContentProps {
  children: React.ReactNode;
  hasMore?: boolean;
  loading?: boolean;
  onEndReached?: () => void;
  position?: MenuPosition;
  style?: React.CSSProperties;
}

export default function MenuContent(props: MenuContentProps) {
  const { children, hasMore = false, loading = false, onEndReached, position = 'left', style } = props;
  const { open, triggerRef, menuRef, toggle } = useMenuContext();
  const { customRef, isIntersecting } = useIntersectionObserver();

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isIntersecting && hasMore && !loading) {
      onEndReached?.();
    }
  }, [hasMore, isIntersecting, loading, onEndReached]);

  const calculateCoordinates = useCallback(() => {
    if (triggerRef.current && menuRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      setCoordinates(calculateMenuPosition(triggerRect, menuRect, position));
    }
  }, [menuRef, position, triggerRef]);

  // Close if the user scrolls outside of the menu
  const handleOutsideScroll = useCallback(
    (event: WheelEvent) => {
      if (!open) return;

      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        toggle(false);
      }
    },
    [menuRef, toggle, open]
  );

  useLayoutEffect(() => {
    calculateCoordinates();

    // disabled because we want to run when any of the following dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef.current, position, triggerRef.current, open]);

  useLayoutEffect(() => {
    window.addEventListener('wheel', handleOutsideScroll);
    window.addEventListener('resize', calculateCoordinates);

    return () => {
      window.removeEventListener('wheel', handleOutsideScroll);
      window.removeEventListener('resize', calculateCoordinates);
    };
  }, [calculateCoordinates, handleOutsideScroll]);

  if (children && open) {
    // children can be `false` if using a `boolean &&` pattern to conditionally render children
    return createPortal(
      <ContentContainer ref={menuRef} style={{ top: coordinates.y, left: coordinates.x, ...(style ?? {}) }} autoFocus>
        {children}
        <HiddenContentChild ref={customRef} />
        {loading && <StyledCircularProgress />}
      </ContentContainer>,
      document.body
    );
  }

  return <></>;
}

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  height: 30px;
  margin: auto;
  width: 30px;
`;

const HiddenContentChild = styled.div`
  border: none;
  height: 1px; /* Needs height to trigger the intersection */
`;

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  max-height: 186px;
  max-width: 256px;
  min-width: 148px;
  overflow-y: auto;
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.boxShadow};
  border: 1px solid ${({ theme }) => theme.colors.black05};
  padding: 4px;
  position: absolute;
  z-index: 4;
`;
