import { useRef, useState } from 'react';
import ReactDatePicker, { DatePickerProps } from 'react-datepicker';
import styled from 'styled-components';

import { InputStyles } from 'components/shared/form/Input';
import { colors } from 'styles/theme/colors';
import CalendarIcon2 from 'svg/CalendarIcon2';

type Props = Omit<DatePickerProps, 'onChange'> & {
  selected: Date | null;
  placeholder?: string;
  disabled?: boolean;
  $hasError?: boolean;
  clearable?: boolean;
  onChange: (date: Date | null) => void;

  // selectsRange and selectsMultiple are typed as `never`
  // to prevent a type error with the onChange prop
  // passed into the ReactDatePicker component
  selectsRange?: never;
  selectsMultiple?: never;
};

export default function DatePicker(props: Props) {
  const dateInput = useRef<any>(null);
  const { disabled, $hasError, placeholder, clearable = true, selected, onChange, ...rest } = props;

  const [dateValue, setDateValue] = useState(selected);

  const onChangeHandler = (date: Date | null) => {
    let outputValue = date;

    if (!clearable && !date) {
      outputValue = dateValue;
    }

    setDateValue(date);

    onChange(outputValue);
  };

  return (
    <DatePickerWrapper $hasError={$hasError ?? false} disabled={disabled ?? false}>
      <div>
        <ReactDatePicker
          {...rest}
          placeholderText={placeholder ?? 'MM/DD/YYYY'}
          disabled={disabled}
          selected={selected}
          ref={dateInput}
          popperProps={{ strategy: 'fixed' }}
          onChange={onChangeHandler}
          calendarClassName='olio-datepicker'
        />
      </div>
      <CalendarIconWrapper>
        <CalendarIconContainer onClick={() => dateInput.current?.handleFocus?.()}>
          <CalendarIcon2 width={14} height={14} color={disabled ? colors.black25 : colors.black} />
        </CalendarIconContainer>
      </CalendarIconWrapper>
    </DatePickerWrapper>
  );
}

export const DatePickerWrapper = styled.div<{
  $hasError: boolean;
  disabled: boolean;
}>`
  display: flex;
  input {
    ${InputStyles}
    width: 200px;
  }
`;

const CalendarIconWrapper = styled.div`
  position: relative;
  width: 0;
`;

const CalendarIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;
