import { FilterOption } from 'stores/types';

export default class FilterValueArray extends Array<FilterOption> {
  constructor(values: FilterOption[] | number) {
    // Base array class has 2 overloads
    // 1. Array(val, val, val)
    // 2. Array(number)
    if (values instanceof Array) super(...values);
    else super(values);
    Object.setPrototypeOf(this, FilterValueArray.prototype);
  }

  removeFilter(id: string) {
    return new FilterValueArray(this.filter((filterValue) => filterValue.id !== id));
  }

  serialize() {
    return this;
  }
}
