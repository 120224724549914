export default function PatientAuthApprovedIcon({ width = 40, height = 40, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='40' height='40' rx='3' fill='#2EA310' fillOpacity='0.1' />
      <path
        d='M26.5827 19.1671C25.5114 19.1671 24.4642 19.4848 23.5735 20.08C22.6828 20.6751 21.9885 21.5211 21.5786 22.5108C21.1686 23.5005 21.0613 24.5896 21.2703 25.6403C21.4793 26.6909 21.9952 27.6561 22.7527 28.4136C23.5102 29.1711 24.4753 29.6869 25.526 29.8959C26.5767 30.1049 27.6657 29.9976 28.6554 29.5877C29.6452 29.1777 30.4911 28.4835 31.0862 27.5928C31.6814 26.702 31.9991 25.6548 31.9991 24.5836C31.9969 23.1477 31.4255 21.7713 30.4102 20.756C29.3949 19.7407 28.0185 19.1693 26.5827 19.1671ZM29.0825 22.9836L26.666 26.2085C26.5585 26.3516 26.4215 26.47 26.2643 26.5556C26.1071 26.6412 25.9333 26.6921 25.7548 26.7047C25.5762 26.7174 25.397 26.6915 25.2293 26.6289C25.0616 26.5663 24.9093 26.4684 24.7828 26.3418L23.5328 25.0919C23.4714 25.0347 23.4222 24.9657 23.388 24.889C23.3538 24.8123 23.3355 24.7296 23.334 24.6457C23.3325 24.5618 23.348 24.4784 23.3794 24.4006C23.4108 24.3228 23.4576 24.2521 23.5169 24.1927C23.5763 24.1334 23.647 24.0866 23.7248 24.0552C23.8026 24.0237 23.886 24.0083 23.9699 24.0098C24.0538 24.0112 24.1366 24.0296 24.2132 24.0638C24.2899 24.0979 24.3589 24.1472 24.4161 24.2086L25.4994 25.2919C25.5205 25.3117 25.5454 25.3271 25.5726 25.3371C25.5998 25.3471 25.6288 25.3516 25.6577 25.3502C25.6872 25.3478 25.7158 25.3391 25.7417 25.3247C25.7675 25.3103 25.7901 25.2906 25.8077 25.2669L28.0826 22.2337C28.1318 22.168 28.1935 22.1127 28.2642 22.0709C28.3348 22.0291 28.4129 22.0016 28.4942 21.99C28.5754 21.9784 28.6582 21.9829 28.7377 22.0032C28.8172 22.0236 28.8919 22.0594 28.9576 22.1087C29.0232 22.1579 29.0785 22.2196 29.1203 22.2902C29.1622 22.3609 29.1897 22.439 29.2013 22.5203C29.2129 22.6015 29.2084 22.6843 29.188 22.7638C29.1676 22.8433 29.1318 22.918 29.0825 22.9836Z'
        fill='#2EA310'
      />
      <path
        d='M16.1665 18.5422H21.5829C21.7486 18.5422 21.9076 18.4763 22.0248 18.3591C22.142 18.2419 22.2078 18.083 22.2078 17.9172C22.2078 17.7515 22.142 17.5925 22.0248 17.4753C21.9076 17.3581 21.7486 17.2922 21.5829 17.2922H16.1665C16.0007 17.2922 15.8418 17.3581 15.7246 17.4753C15.6073 17.5925 15.5415 17.7515 15.5415 17.9172C15.5415 18.083 15.6073 18.2419 15.7246 18.3591C15.8418 18.4763 16.0007 18.5422 16.1665 18.5422Z'
        fill='#2EA310'
      />
      <path
        d='M16.1665 21.4587H18.6664C18.8321 21.4587 18.9911 21.3929 19.1083 21.2757C19.2255 21.1585 19.2913 20.9995 19.2913 20.8337C19.2913 20.668 19.2255 20.509 19.1083 20.3918C18.9911 20.2746 18.8321 20.2088 18.6664 20.2088H16.1665C16.0007 20.2088 15.8418 20.2746 15.7246 20.3918C15.6073 20.509 15.5415 20.668 15.5415 20.8337C15.5415 20.9995 15.6073 21.1585 15.7246 21.2757C15.8418 21.3929 16.0007 21.4587 16.1665 21.4587Z'
        fill='#2EA310'
      />
      <path
        d='M16.1665 24.3752H18.458C18.6238 24.3752 18.7827 24.3094 18.8999 24.1922C19.0172 24.075 19.083 23.916 19.083 23.7503C19.083 23.5845 19.0172 23.4256 18.8999 23.3084C18.7827 23.1912 18.6238 23.1253 18.458 23.1253H16.1665C16.0007 23.1253 15.8418 23.1912 15.7246 23.3084C15.6073 23.4256 15.5415 23.5845 15.5415 23.7503C15.5415 23.916 15.6073 24.075 15.7246 24.1922C15.8418 24.3094 16.0007 24.3752 16.1665 24.3752Z'
        fill='#2EA310'
      />
      <path
        d='M16.3748 15.209H23.4578C23.5131 15.209 23.5661 15.187 23.6051 15.148C23.6442 15.1089 23.6661 15.0559 23.6661 15.0007V12.5008C23.6661 12.4455 23.6442 12.3925 23.6051 12.3535C23.5661 12.3144 23.5131 12.2925 23.4578 12.2925H21.9996V12.0841C21.9984 11.7625 21.9231 11.4454 21.7794 11.1576C21.6358 10.8697 21.4277 10.6189 21.1713 10.4246C20.9149 10.2303 20.6172 10.0978 20.3013 10.0373C19.9853 9.97682 19.6597 9.99003 19.3497 10.0759C18.9043 10.2103 18.5151 10.4866 18.2413 10.8627C17.9675 11.2387 17.8242 11.694 17.8331 12.1591V12.2925H16.3748C16.3196 12.2925 16.2666 12.3144 16.2275 12.3535C16.1885 12.3925 16.1665 12.4455 16.1665 12.5008V15.0007C16.1665 15.0559 16.1885 15.1089 16.2275 15.148C16.2666 15.187 16.3196 15.209 16.3748 15.209ZM19.9163 11.2508C20.0399 11.2508 20.1608 11.2875 20.2635 11.3562C20.3663 11.4248 20.4464 11.5224 20.4937 11.6366C20.541 11.7508 20.5534 11.8765 20.5293 11.9977C20.5052 12.119 20.4456 12.2303 20.3582 12.3177C20.2708 12.4051 20.1595 12.4647 20.0382 12.4888C19.917 12.5129 19.7914 12.5005 19.6772 12.4532C19.563 12.4059 19.4654 12.3258 19.3967 12.223C19.328 12.1203 19.2914 11.9994 19.2914 11.8758C19.2935 11.7107 19.36 11.553 19.4768 11.4363C19.5935 11.3195 19.7512 11.253 19.9163 11.2508Z'
        fill='#2EA310'
      />
      <path
        d='M21.1662 28.4251C21.1482 28.3971 21.1236 28.3741 21.0945 28.3581C21.0654 28.3421 21.0328 28.3336 20.9996 28.3334H14.0832C13.9727 28.3334 13.8668 28.2895 13.7886 28.2114C13.7105 28.1332 13.6666 28.0272 13.6666 27.9167V14.3757C13.6666 14.2652 13.7105 14.1592 13.7886 14.0811C13.8668 14.0029 13.9727 13.959 14.0832 13.959H14.7082C14.7635 13.959 14.8164 13.9371 14.8555 13.898C14.8946 13.859 14.9165 13.806 14.9165 13.7507V12.5008C14.9165 12.4455 14.8946 12.3925 14.8555 12.3535C14.8164 12.3144 14.7635 12.2924 14.7082 12.2924H13.6666C13.2246 12.2924 12.8007 12.468 12.4881 12.7806C12.1756 13.0931 12 13.517 12 13.959V28.3334C12 28.7754 12.1756 29.1993 12.4881 29.5119C12.8007 29.8244 13.2246 30 13.6666 30H22.0995C22.142 29.9989 22.1834 29.9859 22.2188 29.9623C22.2542 29.9387 22.2822 29.9055 22.2995 29.8667C22.3151 29.8265 22.3179 29.7825 22.3074 29.7407C22.297 29.6989 22.2738 29.6614 22.2412 29.6333C21.8368 29.274 21.4761 28.8685 21.1662 28.4251Z'
        fill='#2EA310'
      />
      <path
        d='M27.5909 17.9922C27.6199 18.0002 27.6505 18.0005 27.6797 17.9932C27.7089 17.9859 27.7357 17.9712 27.7575 17.9505C27.7808 17.9312 27.7995 17.907 27.8125 17.8796C27.8254 17.8523 27.8323 17.8225 27.8325 17.7922V13.959C27.8325 13.517 27.657 13.0931 27.3444 12.7806C27.0319 12.468 26.608 12.2924 26.166 12.2924H25.1243C25.0691 12.2924 25.0161 12.3144 24.977 12.3535C24.938 12.3925 24.916 12.4455 24.916 12.5008V13.7507C24.916 13.806 24.938 13.859 24.977 13.898C25.0161 13.9371 25.0691 13.959 25.1243 13.959H25.7493C25.8598 13.959 25.9658 14.0029 26.0439 14.0811C26.1221 14.1592 26.166 14.2652 26.166 14.3757V17.7089C26.1663 17.7371 26.1724 17.7649 26.1839 17.7907C26.1954 17.8165 26.2119 17.8397 26.2326 17.8589C26.2733 17.8968 26.327 17.9177 26.3826 17.9172H26.5826C26.9201 17.9166 27.2572 17.9417 27.5909 17.9922Z'
        fill='#2EA310'
      />
    </svg>
  );
}
