import { useMemo } from 'react';
import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';

import Chart from 'models/Chart';
import ProgressChart from 'models/ProgressChart';
import { colors } from 'styles/theme/colors';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';

export type PatientProgressChartProps = {
  chartData: ProgressChart;
};
export function PatientProgressChart({ chartData }: PatientProgressChartProps) {
  const dates = chartData.items.map((item) => format(item.date, 'MM/dd'));
  const scores = chartData.items.map((item) => item.score);
  const visible = chartData.items.length > 0;

  const config = useMemo(
    () => ({
      accessibility: {
        enabled: false,
      },
      chart: {
        type: 'line',
        height: '240',
        width: '370',
      },
      title: {
        text: '',
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.4,
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: dates,
        title: {
          text: '',
        },
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: 'Progress',
        },
        min: 0,
        allowDecimals: false,
        tickPixelInterval: 44,
        tickAmount: chartData.target + 1,
        minorGridLineWidth: 0,
        gridLineDashStyle: 'dot',
        gridLineColor: '#A7B0B9',
        labels: {
          step: 2,
        },
      },
      series: [
        {
          data: scores,
          showInLegend: false,
          name: 'Progress',
          color: '#6733D0',
          marker: {
            radius: 4,
          },
        },
      ],
    }),
    [chartData.target, dates, scores]
  );

  return (
    visible && (
      <ProgressChartContainer>
        <ProgressChartHeader $accent={true}>Patient Progress</ProgressChartHeader>
        <ProgressChartInnerContainer>
          <HighchartsContainer>
            <HighchartsReact highcharts={Highcharts} options={config} />
          </HighchartsContainer>
          <Indicators>
            <Up>
              <ThumbsUpIcon color={colors.accentGreen} />
            </Up>
            <Down>
              <ThumbsDownIcon color={colors.accentRed} />
            </Down>
          </Indicators>
        </ProgressChartInnerContainer>
      </ProgressChartContainer>
    )
  );
}

export type OlioLineChartProps = {
  chart: Chart;
};
export function OlioLineChart({ chart }: OlioLineChartProps) {
  const scores = chart.scores;
  const dates = chart.data.map((item) => format(item.date, 'MM/dd'));
  const config = {
    chart: {
      type: 'line',
      height: '240',
      width: '370',
    },
    title: {
      text: '',
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.4,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dates,
      title: {
        text: '',
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: chart.yAxis,
      },
      min: chart.min,
      ceiling: chart.max,
      allowDecimals: chart.allowDecimals,
      tickPixelInterval: 44,
      tickAmount: 11,
      minorTickInterval: (chart.max - chart.min) / 10,
      minorTickLength: 0,
      minorGridLineDashStyle: 'dot',
      minorGridLineColor: '#A7B0B9',
      gridLineWidth: 0,
      labels: {
        step: 2,
      },
    },
    series: [
      {
        data: scores,
        name: chart.title,
        showInLegend: false,
        color: '#3253EF',
        marker: {
          radius: 4,
        },
      },
    ],
  };

  return (
    <ProgressChartContainer>
      <ProgressChartHeader $accent={false}>{chart.title}</ProgressChartHeader>
      <ProgressChartInnerContainer>
        <HighchartsReact highcharts={Highcharts} options={config} />
        <RightSpacer />
      </ProgressChartInnerContainer>
    </ProgressChartContainer>
  );
}

const HighchartsContainer = styled.div`
  max-width: 95%;
`;
const Down = styled.div`
  position: absolute;
  bottom: 50px;
`;
const Up = styled.div`
  position: absolute;
  top: 0;
`;
const Indicators = styled.div`
  position: relative;
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  width: 20px;
`;
const ProgressChartInnerContainer = styled.div`
  display: flex;
`;

const ProgressChartContainer = styled.div`
  height: 250px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.white};
`;

const ProgressChartHeader = styled.div<{ $accent: boolean }>`
  border-radius: ${({ theme }) => `${theme.dimensions.borderRadius} ${theme.dimensions.borderRadius} 0 0`};
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 16px;
  text-align: center;
  border-bottom: 1px solid #edeeef;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ $accent, theme }) => ($accent ? theme.colors.accentPurple : theme.colors.primaryBlue)};
`;
const RightSpacer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`;
