import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import IFrameContainer from 'components/IFrameContainer';
import MobileNavigation from 'components/MobileNavigation';
import { SelectViewModal } from 'components/modals/SelectViewModal';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import SideNavbar from 'components/SideNavbar';
import { UserBar } from 'components/UserBar';
import { useProfile } from 'context/profile';
import { useSelectViewActions, useSelectViewStore } from 'stores/selectViewStore';

function MainLayout() {
  const { profile } = useProfile();

  const selectViewOpen = useSelectViewStore((state) => state.selectViewOpen);
  const { setSelectViewOpen } = useSelectViewActions();

  return (
    <>
      <LayoutContainer>
        <DesktopOnly>
          <UserBar />
        </DesktopOnly>
        <MainContainer data-cy='mainContainer'>
          <SideNavbar />
          <ContentContainer data-cy='contentContainer'>
            <Outlet />
          </ContentContainer>
        </MainContainer>
        <IFrameContainer />
      </LayoutContainer>
      <MobileNavigation />
      {selectViewOpen && <SelectViewModal profile={profile} setShow={(b) => setSelectViewOpen(b)} />}
    </>
  );
}

export default withAuthenticationRequired(MainLayout);

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;

const ContentContainer = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.black05};
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;

  @media not ${({ theme }) => theme.devices.desktop} {
    height: 100dvh;
  }
`;

// NOTE: This height was changed from 100% to 100vh when we included Google Tag Manager.
// For some reason the height of the body was not being set to 100% when the GTM script was included.
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
