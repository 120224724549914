import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import CaretDown from 'svg/CaretDown';
import CaretUp from 'svg/CaretUp';

const StyledNavLink = styled(NavLink).attrs({
  draggable: false,
})`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white50};
  font-family: 'Lato';
  text-decoration: none;

  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  &:visited {
    color: 'initial';
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    & > svg {
      opacity: 1;
    }
  }

  & > svg,
  & > div {
    margin-right: 10px;
    opacity: 0.5;
  }

  padding: 14px;

  // @media (max-width: 860px) {
  //   & > .link-title {
  //     display: none;
  //   }
  //   padding: 14px 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   & > svg,
  //   & > div {
  //     margin-right: 0;
  //   }
  // }

  .caret {
    margin-right: 10px;
    margin-left: auto;
    align-self: center;
  }
`;

function SideNavLink(
  props: PropsWithChildren<{
    icon: JSX.Element;
    title: string;
    to: string;
    visible: boolean;
    domain?: string;
  }>
) {
  const [isOpen, setIsOpen] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const to = props.domain ? `${props.domain}${props.to}` : props.to;

  const handleParentClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const active = [...containerRef.current.children].some(
      (child: any) => child.href && child.className.includes('active')
    );

    setIsActive(active);
    setIsOpen(active);
  }, [location.pathname]);

  return (
    props.visible && (
      <Container className={isActive ? 'active' : ''} ref={containerRef}>
        {props.to ? (
          <StyledNavLink to={to} {...(props.domain ? { target: '_blank' } : {})}>
            {props.icon ? props.icon : <Spacer />}
            <span className='link-title'>{props.title}</span>
          </StyledNavLink>
        ) : (
          <StyledNavLink onClick={handleParentClick} className={isActive ? 'active' : ''} as='div' to={{}}>
            {props.icon ? props.icon : <Spacer />}
            <span className='link-title'>{props.title}</span>
            <div className='caret'>{isOpen ? <CaretUp /> : <CaretDown />}</div>
          </StyledNavLink>
        )}

        {isOpen &&
          React.Children.map(props.children, (child: any) => {
            const { title, visible, to, domain: nestedDomain, ...rest } = child.props;
            const nestedTo = nestedDomain ? `${nestedDomain}${to}` : to;

            return (
              visible && (
                <StyledNavLink to={nestedTo} {...rest}>
                  <Spacer />
                  <span className='link-title'>{title}</span>
                </StyledNavLink>
              )
            );
          })}
      </Container>
    )
  );
}

SideNavLink.defaultProps = {
  icon: null,
  title: '',
  to: '',
  visible: true,
};

export default SideNavLink;

const Container = styled.div`
  border: 2px solid transparent;

  &.active {
    border-left-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white15};
  }
`;

const Spacer = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
`;
