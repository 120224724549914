import { SKILLED_NURSING_FACILITY } from 'constants/locationTypes';

export enum AcuteLocationType {
  AFFILIATE = 'affiliate',
  HOSPITAL = 'hospital',
  PHYSICIAN_GROUP = 'physician_group',
  PAYER = 'payer',
}

export type AllLocationType = AcuteLocationType | string;

export interface LocationTypeOptions {
  kind: AllLocationType;
  name: string;
}
function getDefaults(): LocationTypeOptions {
  return {
    kind: '' as AllLocationType,
    name: '',
  };
}
/**
 * @class LocationType
 * @classdesc Represents a location type in the system
 * @property {AllLocationType} kind - The kind of location type
 * @property {string} name - The name of the location type
 * @param {Partial<LocationTypeOptions>} [options={}]
 * @example
 * const locationType = new LocationType({
 *  kind: 'skilled_nursing_facility',
 * name: 'SNF',
 * });
 */
export default class LocationType {
  kind: AllLocationType;
  name: string;
  constructor(opts: Partial<LocationTypeOptions> = {}) {
    const options = { ...getDefaults(), ...opts };
    this.kind = options.kind;
    this.name = options.name;
  }

  get isSNF() {
    return this.kind === SKILLED_NURSING_FACILITY;
  }
}
