import { useProfile } from 'context/profile';

type PermissionGuardProps = {
  permission: string | string[];
  children: React.ReactNode;
};

export default function PermissionGuard({ permission, children }: PermissionGuardProps) {
  const { profile } = useProfile();
  const permissions = Array.isArray(permission) ? permission : [permission];

  if (!profile?.hasAll(permissions)) {
    return null;
  }

  return <>{children}</>;
}
