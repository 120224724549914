import { useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';

import { datadogLogs } from '@datadog/browser-logs';

import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import { useAuth } from 'context/auth';
import { useEnvironment } from 'context/environment';

function SessionMonitor() {
  const { environment } = useEnvironment();
  const { token, renewSession, logout } = useAuth();
  const [renewingSession, setRenewingSession] = useState(false);
  const [lastSessionRenewal, setLastSessionRenewal] = useState(new Date().getTime());
  const [showModal, setShowModal] = useState(false);

  const sessionRenewed = () => {
    setRenewingSession(false);
    setLastSessionRenewal(new Date().getTime());
  };

  // Upon user activity try and renew their access token based on the SESSION_RENEWAL_TIMEOUT
  // We need to do this periodically so the users access token doesnt timeout while
  // they are using the app
  const handleAction = async () => {
    if (renewingSession) return;

    const currentTime = new Date().getTime();
    const timeLapsed = currentTime - lastSessionRenewal;

    if (timeLapsed >= (environment?.SESSION_RENEWAL_TIMEOUT || 4200000)) {
      setRenewingSession(true);

      try {
        // always bypass cache in this instance and go straight to the
        // authorization server for a new access token
        await renewSession();
      } catch {
        datadogLogs.logger.info('Session Not Renewed, Logging Out');
        logout();
      } finally {
        sessionRenewed();
      }
    }
  };

  // The user has chosen to continue their session so reset the
  // lastSessionRenewal time
  const handleActive = () => {
    sessionRenewed();
  };

  const formatTime = (millis) => {
    const date = new Date(millis);

    return `${date.getMinutes()}:${date.getSeconds()}`;
  };

  const captureIdleMessage = () => {
    datadogLogs.logger.info('Idle Message', {
      ElapsedTime: `${formatTime(getElapsedTime())}`,
      LastActiveTime: `LastActiveTime: ${getLastActiveTime()} `,
    });
  };

  // When the user's idle timeout has been reached pop the modal forcing
  // them to either continue their session or logout
  const handleIdle = () => {
    captureIdleMessage();
    setShowModal(true);
  };

  const { activate, getElapsedTime, getLastActiveTime } = useIdleTimer({
    timeout: environment?.SESSION_IDLE_TIMEOUT || 900000,
    onIdle: handleIdle,
    onActive: handleActive,
    onAction: handleAction,
    debounce: 250,
    timers: workerTimers,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  // The user has chosen to logout or continue their session, either way reset
  // our idle timer component and close the modal
  const handleModalClose = () => {
    activate();
    setShowModal(false);
  };

  return token && <SessionTimeoutModal showModal={showModal} onClose={handleModalClose} />;
}

export default SessionMonitor;
