import { StyleSheetManager, ThemeProvider as SCThemeProvider } from 'styled-components';

import isPropValid from '@emotion/is-prop-valid';

import theme from 'styles/theme/theme';

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

function ThemeProvider({ children }) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
    </StyleSheetManager>
  );
}

export default ThemeProvider;
