export default function AuthApprovedThroughIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_3084_3033)'>
        <path
          d='M14.1 6.59563V1.90813C14.1 1.65949 14.0012 1.42103 13.8254 1.24521C13.6496 1.0694 13.4111 0.970627 13.1625 0.970627H1.91248C1.66384 0.970627 1.42538 1.0694 1.24956 1.24521C1.07375 1.42103 0.974976 1.65949 0.974976 1.90813V12.2206C0.974976 12.4693 1.07375 12.7077 1.24956 12.8835C1.42538 13.0594 1.66384 13.1581 1.91248 13.1581H5.66248'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M0.974976 3.78313H14.1' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M15.0375 11.2831C15.0375 12.0248 14.8175 12.7498 14.4055 13.3665C13.9934 13.9832 13.4078 14.4638 12.7225 14.7477C12.0373 15.0315 11.2833 15.1058 10.5559 14.9611C9.82846 14.8164 9.16027 14.4592 8.63583 13.9348C8.11138 13.4103 7.75423 12.7421 7.60953 12.0147C7.46484 11.2873 7.5391 10.5333 7.82293 9.84806C8.10676 9.16284 8.5874 8.57717 9.20409 8.16512C9.82077 7.75306 10.5458 7.53313 11.2875 7.53313'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.0375 8.47063L11.6187 11.8894C11.5752 11.9329 11.5235 11.9675 11.4666 11.9911C11.4097 12.0146 11.3487 12.0268 11.2872 12.0268C11.2256 12.0268 11.1646 12.0146 11.1077 11.9911C11.0508 11.9675 10.9991 11.9329 10.9556 11.8894L9.88123 10.8125'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3084_3033'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
