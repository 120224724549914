import { SortingState, TableOptions } from '@tanstack/react-table';

import type usePagination from 'hooks/usePagination';

import Table from './Table';

type ControlledTableProps<T> = Partial<TableOptions<T>> &
  Required<Pick<TableOptions<T>, 'columns' | 'data'>> & {
    loading: boolean;
    filters: unknown;
    pageCount: number;
    pageSize?: number;
    pagination: ReturnType<typeof usePagination>;
    sorting: { sortBy: SortingState; setSortBy: (sort: SortingState) => void };
  };

function ControlledTable<T>(props: ControlledTableProps<T>) {
  const { pageCount, pagination, sorting, state, ...tableProps } = props;

  return (
    <Table<T>
      initialState={{ pagination: { pageIndex: pagination.paging.page - 1 || 0 } }}
      state={{
        ...(state ? state : {}),
        pagination: { pageIndex: pagination.paging.page - 1 || 0, pageSize: pagination.paging.pageSize },
      }}
      onPageChange={pagination.setPageIndex}
      onSortChange={() => pagination.setPageIndex(0)}
      manualPagination
      manualSorting
      sorting={sorting}
      pageCount={pageCount}
      {...tableProps}
    />
  );
}

export default ControlledTable;
