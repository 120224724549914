export enum QuestionTemplateType {
  DISCHARGE = 'discharge',
  PROGRESS = 'progress',
}
export enum QuestionKind {
  NUMERIC = 'numeric',
  RANGE = 'range',
  STATUS = 'status',
  YES_NO = 'yes_no',
}

export interface QuestionType {
  id: string;
  kind: QuestionKind;
  text: string;
  order: number;
  config:
    | {
        positive?: string;
        chart?: {
          chartName: string;
          yAxis: string;
        };
      }
    | Record<string, never>;
}

export interface QuestionTemplateOptions {
  id: string;
  instructions: string;
  questions: QuestionType[];
  type: QuestionTemplateType;
  updatedAt: string;
}

function getDefaults(): QuestionTemplateOptions {
  return {
    id: '',
    instructions: '',
    questions: [],
    type: QuestionTemplateType.PROGRESS,
    updatedAt: new Date().toISOString(),
  };
}

/**
 * @class QuestionTemplate
 * @classdesc Represents a question template in the system
 * @property {string} id - The question template's id
 * @property {string} instructions - The question template's instructions
 * @property {QuestionType[]} questions - The question template's questions
 * @property {QuestionTemplateType} type - The question template's type
 * @property {string} updatedAt - The question template's update date
 * @param {Partial<QuestionTemplateOptions>} [options={}]
 * @example const questionTemplate = new QuestionTemplate({ id: '123' });
 */
export default class QuestionTemplate {
  id: string;
  instructions: string;
  questions: QuestionType[];
  type: QuestionTemplateType;
  updatedAt: string;

  constructor(options: Partial<QuestionTemplateOptions>) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.instructions = opts.instructions;
    this.questions = opts.questions;
    this.type = opts.type;
    this.updatedAt = opts.updatedAt;
  }

  get isProgress() {
    return this.type === QuestionTemplateType.PROGRESS;
  }

  get isDischarge() {
    return this.type === QuestionTemplateType.DISCHARGE;
  }
}
