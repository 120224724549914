type DataValues = { value: number | string | null }[];

export type DataRow = {
  dimensionValues: DataValues | [];
  metricValues: DataValues;
  grouping?: number;
};

export const isRollup = ({ dimensionValues }: DataRow) =>
  !dimensionValues.length || dimensionValues.every(({ value }) => !value);

export const parseDimensionValues = ({ dimensionValues }: DataRow, asNumber = true) =>
  dimensionValues.map(({ value }) => (asNumber ? Number(value ?? -1) : value));

export const parseMetricValues = ({ metricValues }: DataRow) => metricValues.map(({ value }) => Number(value ?? -1));

export const getRollup = (data: DataRow[], { dataParser = parseMetricValues } = {}) => {
  const rollup = data.find(isRollup);

  return rollup ? dataParser(rollup) : [];
};

export const getNonRollups = (data: DataRow[]) => data.filter((row: DataRow) => !isRollup(row));

export const getAverageOes = (data: DataRow[]) => {
  if (!data.length || getRollup(data)[0] === -1) return -1;

  const scores = getNonRollups(data);

  const totalScore = scores.reduce((sum: number, { metricValues }: DataRow) => sum + Number(metricValues[0].value), 0);

  return Math.round(totalScore / scores.length);
};
