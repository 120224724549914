import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  size?: number;
  thickness?: number;
}

export default function CircularProgress(props: Props) {
  return <Spinner data-cy='circular-progress' {...props} />;
}

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<Props>`
  position: relative;
  border-top: ${({ thickness }) => (thickness ? `${thickness}px` : '5px')} solid #ffffff;
  border-right: ${({ thickness }) => (thickness ? `${thickness}px` : '5px')} solid #ffffff;
  border-bottom: ${({ thickness }) => (thickness ? `${thickness}px` : '5px')} solid #ffffff;
  border-left: ${({ thickness }) => (thickness ? `${thickness}px` : '5px')} solid
    ${({ color }) => (color !== 'inherit' ? color : 'currentColor')};
  -webkit-animation: ${spin} 0.85s infinite linear;
  animation: ${spin} 0.85s infinite linear;

  border-radius: 50%;
  width: ${({ size }) => (size ? `${size}px` : '50px')};
  height: ${({ size }) => (size ? `${size}px` : '50px')};

  &:after {
    border-radius: 50%;
    width: ${({ size }) => (size ? `${size}px` : '50px')};
    height: ${({ size }) => (size ? `${size}px` : '50px')};
  }
`;
