function BedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        id='info-sections-with-icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-224.000000, -312.000000)' stroke='#3253EF'>
          <g id='hospital-bed' transform='translate(225.000000, 313.000000)'>
            <path
              d='M0.403225806,5.64516129 L2.82258065,5.64516129 C3.7133625,5.64516129 4.43548387,6.36728266 4.43548387,7.25806452 L4.43548387,8.06451613'
              id='Path'
            />
            <line x1='0.403225806' y1='12.0967742' x2='0.403225806' y2='2.41935484' id='Path' />
            <line x1='0.403225806' y1='10.483871' x2='12.5' y2='10.483871' id='Path' />
            <polyline id='Path' points='0.403225806 8.06451613 12.5 8.06451613 12.5 12.0967742' />
            <line x1='7.66129032' y1='0' x2='7.66129032' y2='4.83870968' id='Path' />
            <line x1='10.0806452' y1='2.41935484' x2='5.24193548' y2='2.41935484' id='Path' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BedIcon;
