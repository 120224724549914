import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useClickOutside } from '@react-awesome/use-click-outside';

import { useAuth } from 'context/auth';
import { useProfile } from 'context/profile';
import { useInfiniteEscalations } from 'services/api/escalation';
import { useSelectViewActions } from 'stores/selectViewStore';
import { useTaskModalActions, useTaskModalStore } from 'stores/taskModalStore';
import { colors } from 'styles/theme/colors';
import { theme } from 'styles/theme/theme';
import CaretDown from 'svg/CaretDown';
import CaretRight from 'svg/CaretRight';
import Logo from 'svg/Logo';
import OlioOLogo from 'svg/OlioOLogo';
import PriorityFlag from 'svg/PriorityFlag';
import UnsureIcon from 'svg/UnsureIcon';
import WarnIcon from 'svg/WarnIcon';

import TaskModal from './modals/Tasks/TaskModal';

export function UserBar() {
  const { logout } = useAuth();
  const { profile } = useProfile();
  const { setSelectViewOpen } = useSelectViewActions();
  const navigate = useNavigate();
  const viewedOnLogin = useTaskModalStore((state) => state.viewedOnLogin);
  const { setViewedOnLogin } = useTaskModalActions();

  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const isAcute = profile.isAcute;

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref.current, () => {
    setShowMenu(false);
  });

  const { data: escalationsCount } = useInfiniteEscalations({
    select: (res) => res.pages?.[0].meta.totalRecords,
    enabled: !!profile,
  });

  useEffect(() => {
    if (!profile.isAdmin && !viewedOnLogin && escalationsCount) {
      setViewedOnLogin();
      setShowModal(true);
    }
  }, [escalationsCount, profile.isAdmin, setViewedOnLogin, viewedOnLogin]);

  const handleLogout: MouseEventHandler = (e) => {
    e.preventDefault();
    setShowMenu(false);

    logout();
  };

  const handlePreferences = () => {
    setShowMenu(false);
    navigate('/preferences');
  };

  const handleSelectView = () => {
    setShowMenu(false);
    setSelectViewOpen(true);
  };

  return (
    <UserBarDiv data-cy='userBarDiv'>
      <Logo color='#fff' />
      <ActionsPalette>
        <Action
          onClick={() => {
            setShowModal(true);
          }}
          data-cy='tasksLink'>
          <TasksIconContainer $taskCount={escalationsCount ?? 0}>
            {isAcute ? (
              <WarnIcon color='#FFFFFF' width={16} height={16} />
            ) : (
              <PriorityFlag color='#FFFFFF' width={16} height={16} />
            )}
            {(escalationsCount ?? 0) > 0 && <TasksBadge>{escalationsCount}</TasksBadge>}
          </TasksIconContainer>
          <span>{isAcute ? 'Escalations' : 'Priorities'}</span>
        </Action>

        <Action
          onClick={() => {
            window.open('https://support.olio.health/hc/en-us', '_blank');
          }}
          data-cy='supportLink'>
          <UnsureIcon color={'#ffffff'} />
          <span>Help & Support</span>
        </Action>

        <ProfileContainer ref={ref}>
          <Action onClick={() => setShowMenu((prev) => !prev)} data-cy='userMenu'>
            <IconContainer>{showMenu ? <CaretDown color={'#fff'} /> : <CaretRight color={'#fff'} />}</IconContainer>
            {profile.fullName}
            {profile.actingClient && <ActingClientName>({profile.actingClient?.name})</ActingClientName>}
          </Action>
          {showMenu && (
            <Menu>
              <UserInfoContainer>
                <div>
                  <OlioOLogo color={theme.colors.primaryBlue} />
                </div>
                <UserInfo>
                  <UserName>{profile.name}</UserName>
                  <UserEmail>{profile.email}</UserEmail>
                </UserInfo>
              </UserInfoContainer>

              {profile.canChangeClientScope && (
                <MenuItem onClick={handleSelectView} data-cy='userMenuSelectClientView'>
                  Select View
                </MenuItem>
              )}
              <MenuItem onClick={handlePreferences} data-cy='userMenuNotificationPreferences'>
                Notification Preferences
              </MenuItem>
              <MenuItem onClick={handleLogout} data-cy='userMenuLogout'>
                Logout
              </MenuItem>
            </Menu>
          )}
        </ProfileContainer>
      </ActionsPalette>
      <TaskModal profile={profile!} setShow={setShowModal} show={showModal} />
    </UserBarDiv>
  );
}

const UserBarDiv = styled.div`
  background-color: var(--primary-blue);
  height: 48px;
  padding: 0px 24px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionsPalette = styled.div`
  display: flex;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
`;

const Action = styled.div`
  display: flex;
  color: white;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 0 12px;
`;

const TasksIconContainer = styled.div<{ $taskCount: number }>`
  display: flex;
  gap: 8px;
  padding-right: ${({ $taskCount }) => ($taskCount > 0 ? 7 : 0)}px;
  position: relative;
`;
const TasksBadge = styled.span`
  align-items: center;
  align-self: flex-end;
  background: ${colors.accentRed};
  border-radius: 50%;
  bottom: -8px;
  color: ${colors.white};
  display: flex;
  font-size: 10px;
  height: 14px;
  justify-content: center;
  margin-left: 1px;
  position: absolute;
  right: 0px;
  width: 14px;
`;

const Menu = styled.div`
  border: none;
  border-radius: 2px;
  background: ${(props) => props.theme.colors.white};
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: ${(props) => `calc(${props.theme.dimensions.mainHeaderHeight} - 5px)`};
  right: 24px;
  z-index: 100;
  font-size: 14px;
  line-height: 1.15;
  & > *:last-child {
    border-bottom: none;
  }
`;

const MenuItem = styled.div`
  color: ${(props) => props.theme.colors.black75};
  cursor: pointer;
  padding: 12px;
  min-width: 100px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};

  &:hover {
    background: ${(props) => props.theme.colors.black05};
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 12px;
  min-width: 100px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};
`;

const UserInfo = styled.div`
  margin-left: 8px;
  text-align: left;
`;

const UserName = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryBlue};
  padding-bottom: 5px;
`;

const UserEmail = styled.div`
  color: black;
`;

const IconContainer = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const ActingClientName = styled.div`
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
