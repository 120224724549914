import Client, { ClientOptions } from 'models/Client';
import GroupType, { GroupTypeOptions } from 'models/GroupType';

import LocationType, { AcuteLocationType, LocationTypeOptions } from './LocationType';

export interface GroupOptions {
  id: string;
  archived: false;
  name: string;
  address: {
    streetAddress: string;
    streetAddressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
  client: Partial<ClientOptions>;
  clientId: string;
  clientName: string;
  locationType: LocationTypeOptions;
  groupType: Partial<GroupTypeOptions>;
  groupTypeId: string;
  timezone: string;
  type: string;
  subType: string;
  groups: GroupOptions[];
  owners: GroupOptions[];
}

export function getDefaults(): GroupOptions {
  return {
    id: '',
    archived: false,
    name: '',
    address: {
      streetAddress: '',
      streetAddressLine2: '',
      city: '',
      state: '',
      zip: '',
    },
    client: {} as ClientOptions,
    clientId: '',
    clientName: '',
    locationType: new LocationType(),
    groupType: new GroupType(),
    groupTypeId: '',
    timezone: '',
    type: '',
    subType: '',
    groups: [],
    owners: [],
  };
}
/**
 * @class Group
 * @classdesc Represents a group in the system
 * @property {string} id - The group's id
 * @property {boolean} archived - Whether the group is archived
 * @property {string} name - The group's name
 * @property {string} clientName - The name of the client
 * @property {string} type - The type of the group
 * @property {object} address - The group's address
 * @property {string} address.streetAddress - The group's street address
 * @property {string} address.streetAddressLine2 - The group's street address line 2
 * @property {string} address.city - The group's city
 * @property {string} address.state - The group's state
 * @property {string} address.zip - The group's zip code
 * @property {Client} client - The client the group belongs to
 * @property {string} clientId - The client's id
 * @property {LocationType} locationType - The group's location type (different structure than the group type)
 * @property {GroupType} groupType - The group's group type
 * @property {string} groupTypeId - The group type's id
 * @property {string} timezone - The group's timezone
 * @property {Group[]} groups - The group's groups
 * @property {Group[]} owners - The group's owners
 * @method serialize - Serializes the group
 * @method isHospital - Whether the group is a hospital
 * @method isPayer - Whether the group is a payer
 * @method isPhysicianGroup - Whether the group is a physician group
 * @method isAffiliate - Whether the group is an affiliate
 * @method toFormValues - Converts the group to form values
 * @static acronymFor - Returns the acronym for a group type
 * @param {Partial<GroupOptions>} [opts={}] - The options for the group
 * @example const group = new Group({ id: '123', name: 'Group' });
 * @example group.serialize();
 *
 */
export default class Group {
  id: string;
  archived: false;
  name: string;
  clientName: string;
  address: {
    streetAddress: string;
    streetAddressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
  client: Client;
  clientId: string;
  locationType: LocationTypeOptions;
  groupType: Partial<GroupTypeOptions>;
  groupTypeId: string;
  subType: string;
  timezone: string;
  type: string;
  groups: Group[];
  owners: Group[];

  constructor(opts: Partial<GroupOptions> = {}) {
    const options = { ...getDefaults(), ...opts };
    this.id = options.id;
    this.archived = options.archived;
    this.name = options.name;
    this.clientName = options.clientName;
    this.type = options.type;
    this.subType = options.subType;
    this.address = options.address;
    this.locationType = options.locationType;
    this.groupType = options.groupType ? new GroupType(options.groupType) : options.groupType;
    this.groupTypeId = options.groupTypeId;
    this.timezone = options.timezone;
    this.client = new Client(options.client);
    this.clientId = options.clientId;
    this.groups = options.groups.map((group) => new Group(group));
    this.owners = options.owners.map((owner) => new Group(owner));
  }

  get isHospital() {
    return this.locationType.kind === AcuteLocationType.HOSPITAL;
  }

  get isPayer() {
    return this.locationType.kind === AcuteLocationType.PAYER;
  }

  get isPhysicianGroup() {
    return this.locationType.kind === AcuteLocationType.PHYSICIAN_GROUP;
  }

  get isAffiliate() {
    return this.locationType.kind === AcuteLocationType.AFFILIATE;
  }

  serialize() {
    const { ...propsToSerialize } = this;
    return {
      ...propsToSerialize,
      groupIds: this.groups.map((group) => group.id),
      ownerIds: this.owners.map((group) => group.id),
    };
  }

  toFormValues() {
    return {
      ...this,
      address: {
        ...this.address,
        state: this.address?.state ? { label: this.address.state, value: this.address.state } : '',
      },
      client: this.client.id ? this.client : '',
      groups: this.groups,
      owners: this.owners,
      groupType: this.groupType?.id ? this.groupType : undefined,
    };
  }
}
