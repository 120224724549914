export default function PlusIcon({ width = 24, height = 24, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='plus-icon-black50' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          fill='currentColor'
          fillRule='nonzero'
          points='24 13.7142857 13.7142857 13.7142857 13.7142857 24 10.2857143 24 10.2857143 13.7142857 0 13.7142857 0 10.2857143 10.2857143 10.2857143 10.2857143 0 13.7142857 0 13.7142857 10.2857143 24 10.2857143'
        />
      </g>
    </svg>
  );
}
