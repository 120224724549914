import styled from 'styled-components';

import Menu from 'components/shared/menu';
import Tag from 'components/shared/Tag';
import Tooltip from 'components/shared/Tooltip';
import { DocumentTypes } from 'models/Attachment';
import { colors } from 'styles/theme/colors';
import TagIcon from 'svg/TagIcon';

type Props = {
  activeDocType: string | null | undefined;
  setActiveDocType?: (b: string | null) => void;
  disabled: boolean;
  reportAbsentDoctype?: boolean;
};

export default function DocumentTypeMenu(props: Props) {
  const { activeDocType, setActiveDocType, disabled, reportAbsentDoctype = false } = props;

  return (
    <>
      {activeDocType && <Tag disabled={disabled} label={activeDocType} onClear={() => setActiveDocType?.(null)} />}
      {!activeDocType && reportAbsentDoctype && <>&mdash;</>}
      <Menu $size='small'>
        <Tooltip disabled={disabled} text={!activeDocType ? 'Add Document Type' : 'Edit Document Type'}>
          <Menu.Trigger disabled={disabled}>
            <StyledTagIcon width={12} height={12} color={colors.black} $disabled={disabled} />
          </Menu.Trigger>
        </Tooltip>
        <Menu.Content position='right'>
          {Object.values(DocumentTypes).map((docType) => (
            <Menu.Item key={docType} onClick={() => setActiveDocType?.(docType)} $active={activeDocType === docType}>
              {docType}
            </Menu.Item>
          ))}
        </Menu.Content>
      </Menu>
    </>
  );
}

const StyledTagIcon = styled(TagIcon)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => $disabled && 0.25};
`;
