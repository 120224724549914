import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  Changes,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface AuthorizationUpdatesOptions extends ActivityOptions {
  type: ActivityType.AUTHORIZATION_UPDATES;
  data: Partial<BaseActivityDataOptions & { changes: Changes }>;
}

export function getDefaults(): AuthorizationUpdatesOptions {
  const defaults = getActivityDefaults();
  return {
    ...defaults,
    type: ActivityType.AUTHORIZATION_UPDATES,
    data: {
      ...defaults.data,
      changes: [],
    },
  };
}

export default class AuthorizationUpdates extends Activity {
  declare type: ActivityType.AUTHORIZATION_UPDATES;
  declare data: BaseActivityData & { changes: Changes };

  constructor(options: Partial<AuthorizationUpdatesOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Authorization Details';
  }
}
