import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';

import { useInfiniteQuery } from '@tanstack/react-query';

import DischargeReason, { DischargeReasonOptions } from 'models/DischargeReason';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const dischargeReasonsQueryKeys = {
  index: ['dischargeReasons'] as const,
};

type IndexParams = BaseIndexQueryParams & {
  groupType?: string;
};

export async function indexDischargeReasons(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<DischargeReasonOptions>>(`discharge_reasons`, { params, signal }).then((res) => ({
    ...res.data,
    data: res.data.data.map((x) => new DischargeReason(x)),
  }));
}

export const useInfiniteDischargeReasons = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: [dischargeReasonsQueryKeys.index, { ...params, search }],
    queryFn: ({ signal, pageParam }) =>
      indexDischargeReasons({ pageSize: 10, ...params, search, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
