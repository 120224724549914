import styled from 'styled-components';

import { colors } from 'styles/theme/colors';
import { BodySmallBold } from 'styles/typography';

type YesNoQuestionType = {
  value?: string;
  setValue: (v: string) => void;
  text: string;
  index?: number;
};

export default function YesNoQuestion(props: YesNoQuestionType) {
  const { text, value, setValue, index = -1 } = props;

  return (
    <QuestionContainer $isFirst={index == 1}>
      <StyledQuestion>
        {index >= 0 && <span>{index}.</span>}
        <span>{text}</span>
      </StyledQuestion>
      <AnswerContainer>
        <Answer
          value='No'
          $selected={value === 'No'}
          onClick={(event) => setValue((event.target as unknown as { value: string }).value)}>
          No
        </Answer>
        <Answer
          value='Yes'
          $selected={value === 'Yes'}
          onClick={(event) => setValue((event.target as unknown as { value: string }).value)}>
          Yes
        </Answer>
      </AnswerContainer>
    </QuestionContainer>
  );
}

const QuestionContainer = styled.div<{ $isFirst: boolean }>`
  padding: 0px 16px 16px 0;
  ${({ $isFirst }) => !$isFirst && 'border-top: 1px solid var(--black-25); padding-top: 16px;'}
  display: flex;

  & > *:first-child {
    flex: 1;
  }
`;

const AnswerContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    &:first-child {
      margin: 0 8px 0 14px;
    }
  }
`;

const Answer = styled.button.attrs<{ $selected: boolean }>({
  type: 'button',
})`
  background-color: ${({ $selected }) => ($selected ? colors.primaryBlue10 : colors.black05)};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  font-size: 12px;
  color: ${({ $selected }) => ($selected ? colors.primaryBlue : colors.black)};
  height: 24px;
  width: 80px;
  border: 1px solid ${({ $selected }) => ($selected ? colors.primaryBlue : 'transparent')};
  cursor: pointer;
`;

const StyledQuestion = styled(BodySmallBold)`
  display: flex;
  gap: 8px;
`;
