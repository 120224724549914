import { LocalStorageCache } from "@auth0/auth0-react";

export const IFRAME_CLASS = "iframe-olio-external";

export class CrossDomainLocalStorageCache extends LocalStorageCache {
  public remove(key: string): void {
    super.remove(key);
    const iframes: HTMLCollectionOf<HTMLIFrameElement> = document.getElementsByClassName(
      IFRAME_CLASS
    ) as HTMLCollectionOf<HTMLIFrameElement>;
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      if (!iframe.contentWindow) continue;
      const origin = new URL(iframe.src).origin;
      iframe.contentWindow?.postMessage({ key }, origin);
      console.log("Sent message to", origin);
      console.log({ key });
    }
  }
}
