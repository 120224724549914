import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Intake from 'components/intake/Intake';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import ConfirmPatientRemovalModal from 'components/modals/ConfirmPatientRemovalModal';
import Button from 'components/shared/Button';
import { ColorThemeName } from 'components/shared/StandardModal';
import { FormHelpers } from 'context/form/types';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import Patient from 'models/Patient';
import { deleteEpisode, episodeQueryKeys, showEpisode } from 'services/api/episode';
import { updatePatient } from 'services/api/patient';
import { useToastActions } from 'stores/toastStore';
import colors from 'styles/theme/colors';
import CancelIcon from 'svg/CancelIcon';

export default function EditPatientPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useToastActions();
  const { id } = useParams<{ id?: string }>();
  const { profile } = useProfile();
  const canRemovePatient = profile.permissions.patientDelete ?? false;

  const isMobile = useIsMobile();
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);
  const { mutate: removePatient } = useMutation({
    mutationFn: deleteEpisode,
  });

  const queryParams = {
    id: id ?? '',
    include: 'owner.client.enabled_provider_types,latest_location_episode.assigned_users.credential',
  };

  const { data: episode } = useQuery({
    queryKey: episodeQueryKeys.show(queryParams),
    queryFn: () => showEpisode(queryParams),
    enabled: !!id,
  });

  const { mutate } = useMutation({
    mutationFn: updatePatient,
  });

  if (!episode) return <></>;

  const confirmRemovePatient = async () => {
    removePatient(
      { id: episode.id },
      {
        onSuccess: () => {
          addToast({ text: `${episode?.patient?.name} has been successfully removed.` });
          navigate('/patients');
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
      }
    );
  };

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    helpers.setMeta('isSubmitting', true);

    const patientValues = Patient.fromFormValues(values).serialize();

    mutate(
      { ...patientValues, id: episode?.patient.id },
      {
        onSuccess: (patient) => {
          addToast({ text: 'Patient successfully updated.' });
          queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show(queryParams) });
          navigate(`/patients/${patient.episodeId}`);
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  const initialValues = episode ? Patient.fromEpisodeData(episode).intakeFormValues() : {};

  return (
    <Intake
      initialMeta={{
        isSubmitting: false,
        mode: PatientFormMode.Edit,
        patientHasActiveServiceRefusals: !!episode.locationEpisodes?.some((le) => le.hasActiveServiceRefusals),
        currentRehabState: episode.currentRehabState.state,
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      patient={episode.patient}
      removePatientButton={
        canRemovePatient && (
          <ButtonContainer>
            <RemovePatientButton
              topValue={episode.patient.externalId ? 90 : 50}
              fullWidth={isMobile}
              variant='outline-danger'
              type='button'
              onClick={() => setShowRemovePatientModal(true)}>
              {!isMobile && <CancelIcon color={colors.accentRed} width={10} height={10} />}
              Remove Patient
            </RemovePatientButton>
          </ButtonContainer>
        )
      }
      removePatientModal={
        canRemovePatient &&
        showRemovePatientModal && (
          <ConfirmPatientRemovalModal
            patientName={episode.patient.name}
            onCancel={() => setShowRemovePatientModal(false)}
            onConfirm={() => confirmRemovePatient()}
            colorTheme={ColorThemeName.Danger}
          />
        )
      }
    />
  );
}

const ButtonContainer = styled.div`
  @media ${({ theme }) => theme.devices.desktop} {
    position: unset;
  }
  position: relative;
`;

const RemovePatientButton = styled(Button)<{ topValue: number }>`
  position: absolute;
  @media ${({ theme }) => theme.devices.desktop} {
    top: 24px;
    right: 24px;
  }
  top: ${({ topValue = 0 }) => topValue}px;
`;
