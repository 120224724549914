import React from 'react';
import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { Label } from 'styles/typography';

type Props = {
  name?: string;
  children: React.ReactNode;
};

function FieldLabel({ name = '', children }: Props) {
  return (
    <LabelContainer>
      <Label htmlFor={name} color={colors.black75}>
        {children}
      </Label>
    </LabelContainer>
  );
}

export default FieldLabel;

const LabelContainer = styled.div`
  margin-bottom: 4px;
`;
