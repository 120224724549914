import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import AssignToMe from 'components/shared/form/AssignToMe';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { useProfile } from 'context/profile';
import LocationEpisode from 'models/LocationEpisode';
import { AssignedUserRole, LocationEpisodeUser } from 'models/LocationEpisodeUser';
import { activityQueryKeys } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { locationEpisodeQueryKeys, updateLocationEpisode } from 'services/api/locationEpisodes';
import { useInfiniteUsers } from 'services/api/user';
import { useToastActions } from 'stores/toastStore';

export type AssignedUserModalProps = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  assignedUser: LocationEpisodeUser | null;
  assignedUserRole: AssignedUserRole;
};

export default function AssignedUserModal(props: AssignedUserModalProps) {
  const { setShow, patientName, locationEpisode, assignedUser, assignedUserRole } = props;
  const { profile } = useProfile();
  const queryClient = useQueryClient();

  const assignedUserText = assignedUserRole === AssignedUserRole.CaseManager ? 'Case Manager' : 'Utilization Manager';
  const [assignedManager, setAssignedManager] = useState<LocationEpisodeUser | null>(assignedUser);
  const { addToast } = useToastActions();

  const { mutate, isPending } = useMutation({
    mutationFn: updateLocationEpisode,
  });

  const asyncUserOptions = useInfiniteUsers({
    include: 'credential',
    sortBy: 'name',
    active: true,
    group: locationEpisode.owner.id,
  });

  const cancel = () => {
    setShow(false);
    setAssignedManager(null);
  };

  const payload = () => {
    if (assignedUserRole === AssignedUserRole.CaseManager) {
      return {
        id: locationEpisode.id,
        caseManagerId: assignedManager?.id || null,
      };
    } else {
      return {
        id: locationEpisode.id,
        utilizationManagerId: assignedManager?.id || null,
      };
    }
  };

  const submit = async () => {
    mutate(payload(), {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
        queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
        queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count() });
        cancel();
      },
      onError: () => {
        addToast({ text: 'Something went wrong. Please try again.' });
      },
    });
  };

  return (
    <StandardModal title={`Update ${assignedUserText}`} onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title={`Select a ${assignedUserText.toLowerCase()} to assign:`} data-cy='assignedUser'>
          <Select<LocationEpisodeUser>
            {...asyncUserOptions}
            name='assignedUser'
            placeholder={assignedUserText}
            getOptionLabel={(option) => option.fullName}
            getOptionValue={(option) => option.id}
            onChange={(change) => setAssignedManager(change)}
            value={assignedManager}
            isClearable
          />
          <AssignToMe handleAssign={() => setAssignedManager(profile)} />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;
