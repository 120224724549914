export default function PatientListsIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 15 16' fill='none' {...props}>
      <path
        fill='currentColor'
        d='M14 0H.667A.667.667 0 0 0 0 .667v14.666c0 .368.298.667.667.667h8.666v-4.667c0-.368.299-.666.667-.666h4.667v-10A.667.667 0 0 0 14 0ZM3.333 4h2v1.333h-2V4Zm0 3.333h2v1.334h-2V7.333ZM6.667 12H3.333v-1.333h3.334V12Zm4.666-3.333H6.667V7.333h4.666v1.334ZM14.276 12l-3.61 3.61V12h3.61Zm-2.943-8v1.333H6.667V4h4.666Z'
      />
    </svg>
  );
}
