import styled from 'styled-components';

interface Props {
  handleAssign: () => void;
}

export default function AssignToMe({ handleAssign }: Props) {
  return (
    <Container>
      <Link data-cy='assignToMe' onClick={handleAssign}>
        Assign to me
      </Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Link = styled.span`
  cursor: pointer;
  color: var(--primary-blue);
  font-size: 12px;
`;
