import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import JobProcess, { ExportContext } from 'models/JobProcess';
import { showExport } from 'services/api/exports';
import { getJobProcess } from 'services/api/jobProcess';
import { useToastActions } from 'stores/toastStore';

export default function useAsyncExport(exportFn: any) {
  const { addToast } = useToastActions();
  const [processToMonitor, setProcessToMonitor] = useState<string>();
  const [exportId, setExportId] = useState<string>();
  const [exporting, setExporting] = useState(false);

  const { mutate } = useMutation({
    mutationFn: exportFn,
    onMutate: () => {
      setExporting(true);
    },
    onSuccess: (res: JobProcess) => {
      setProcessToMonitor(res.id);
    },
  });

  const { data: jobProcess, isError: jobProcessError } = useQuery({
    queryKey: ['jobProcess', processToMonitor],
    queryFn: () => getJobProcess(processToMonitor ?? ''),
    enabled: !!processToMonitor,
    refetchInterval: 1500,
  });

  const { data: exportInfo, isError: exportError } = useQuery({
    queryKey: ['export', exportId],
    queryFn: () => showExport(exportId ?? ''),
    enabled: !!exportId,
  });

  useEffect(() => {
    if (jobProcess?.isFinished) {
      setProcessToMonitor(undefined);
      setExportId((jobProcess.context as ExportContext).exportId);
    }
  }, [jobProcess]);

  useEffect(() => {
    if (exportInfo?.url) {
      setExporting(false);
      window.open(exportInfo.url, '_blank');
    }
  }, [exportInfo]);

  useEffect(() => {
    if (exportError || jobProcessError || jobProcess?.error) {
      addToast({ text: 'Something went wrong with the export. Please try again.' });
      setExporting(false);
      setProcessToMonitor(undefined);
    }
  }, [addToast, exportError, jobProcess, jobProcessError]);

  return {
    exporting,
    exportData: exportInfo,
    initiateExport: mutate as any,
  };
}
