import styled from 'styled-components';

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /* for ms scrolling issue */
  .highcharts-scrolling {
    -ms-overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 0.5em;
      width: 0.5em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.scrollbarGray};
      border-radius: 5pt;
    }
  }
`;

export default ChartContainer;
