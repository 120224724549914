import { ACUTE_LOCATION_TYPES, HOSPITAL, PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import Episode from 'models/Episode';
import EpisodeGroup from 'models/EpisodeGroup';
import { AcuteLocationType } from 'models/LocationType';

const hospitalField = { label: 'Hospital', name: HOSPITAL };
const physicianGroupField = { label: 'Physician Group', name: PHYSICIAN_GROUP };
const payerField = { label: 'Payer', name: PAYER };

export const getComanagementTeamFields = (owningGroupLocationType: AcuteLocationType) => {
  switch (owningGroupLocationType) {
    case AcuteLocationType.HOSPITAL:
      return [hospitalField];
    case AcuteLocationType.PHYSICIAN_GROUP:
      return [hospitalField, physicianGroupField];
    case AcuteLocationType.PAYER:
      return [hospitalField, payerField];
    default:
      return [hospitalField, physicianGroupField, payerField];
  }
};

export const renderableComanagementTeamFields = (episode: Episode) => {
  const nonOwningAcuteGroups =
    episode.episodeGroups?.filter(({ group }: EpisodeGroup) =>
      ACUTE_LOCATION_TYPES.includes(group.locationType.kind)
    ) ?? [];

  const fields = getComanagementTeamFields(episode.owner.locationType.kind as AcuteLocationType);

  return fields
    .map((field) => {
      const episodeGroupsForField = nonOwningAcuteGroups.filter(
        ({ group }: EpisodeGroup) => group.locationType.kind === field.name
      );

      if (!episodeGroupsForField.length) {
        return {
          id: field.name,
          locationTypeKind: field.name,
          locationTypeLabel: field.label,
          name: <>&mdash;</>,
          isOwner: false,
        };
      }

      return episodeGroupsForField.map(({ group }: EpisodeGroup) => {
        return {
          id: group.id,
          locationTypeKind: group.locationType.kind,
          locationTypeLabel: group.locationType.name,
          name: group.name,
          isOwner: group.id === episode.owner.id,
        };
      });
    })
    .flat();
};
