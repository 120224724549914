import styled from 'styled-components';

import Group from 'models/Group';
import colors from 'styles/theme/colors';
import { LabelSmall } from 'styles/typography';
import GroupIcon from 'svg/GroupIcon';

type Props = {
  group: Group;
};

export default function GroupBadge({ group }: Props) {
  return (
    <Container>
      <GroupIcon width={18} height={18} color={colors.black} />
      <Text title={`${group?.groupType.displayName} - ${group?.name}`}>
        {group?.groupType.displayName} - {group?.name}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  padding: 2px;
  padding-right: 5px;
  background: ${() => colors.black05};
  min-width: 0px;
  align-self: flex-start;
  & > svg {
    min-width: 18px;
    margin-right: 5px;
  }
`;

const Text = styled(LabelSmall)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
