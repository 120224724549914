import React, { Component, ErrorInfo, ReactNode } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ErrorState, ErrorStateKind } from 'components/ErrorState';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const renderingError = new Error(error.message);
    renderingError.name = `RenderingError`;
    if (errorInfo.componentStack) {
      renderingError.stack = errorInfo.componentStack;
    }

    datadogRum.addError(renderingError);
    datadogLogs.logger.error('Unhandled Error', errorInfo, error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorState kind={ErrorStateKind.SomethingWentWrong} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
