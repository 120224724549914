import Review, { ReviewOptions, ReviewType } from 'models/Review';
import AltcsApplication, { AltcsApplicationOptions } from 'models/reviews/AltcsApplication';
import Authorization, { AuthorizationOptions } from 'models/reviews/Authorization';
import ProjectedDischarge, { ProjectedDischargeOptions } from 'models/reviews/ProjectedDischarge';
import ServiceRefusal, { ServiceRefusalOptions } from 'models/reviews/ServiceRefusal';

export default class ReviewModelFactory {
  private static defaultClass = Review;

  private static mapping = {
    [ReviewType.ALTCS_APPLICATION]: AltcsApplication,
    [ReviewType.AUTHORIZATION]: Authorization,
    [ReviewType.PROJECTED_DISCHARGE]: ProjectedDischarge,
    [ReviewType.SERVICE_REFUSAL]: ServiceRefusal,
  };

  /**
   * Returns an instance of the appropriate class based on the options provided.
   * @see {@link https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads Function Overloads}
   */
  static get(options: Partial<AltcsApplicationOptions>): AltcsApplication;
  static get(options: Partial<AuthorizationOptions>): Authorization;
  static get(options: Partial<ProjectedDischargeOptions>): ProjectedDischarge;
  static get(options: Partial<ServiceRefusalOptions>): ServiceRefusal;
  static get(options: Partial<ReviewOptions>): Review;
  static get(options): Review {
    const mappedClass = options.type ? this.mapping[options.type] : this.defaultClass;
    const klass = mappedClass ?? this.defaultClass;
    return new klass(options);
  }
}
