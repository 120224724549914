import { DefaultError, InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import { useProfile } from 'context/profile';
import { Analytics, retrieveAnalytics } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, SourceConstants } from '../../constants';

type QueryResult = Awaited<ReturnType<typeof retrieveAnalytics>>;

export type AnalyticsQueryParams = {
  source: SourceConstants;
  dimensions: DimensionConstants[];
  metrics: MetricConstants[];
  sortBy?: string;
  pageSize?: number;
};

export default function useAnalyticsInfiniteQuery(
  queryParams: AnalyticsQueryParams,
  options: Omit<
    UseInfiniteQueryOptions<QueryResult, DefaultError, InfiniteData<Analytics, Analytics>>,
    'queryKey' | 'queryFn'
  >
) {
  const { profile } = useProfile();
  const analyticsFilters = useInsightsStore((state) => getAnalyticsFilters(state, profile));

  return useInfiniteQuery({
    queryKey: ['analytics', queryParams, analyticsFilters],
    queryFn: async ({ pageParam }) => retrieveAnalytics(pageParam, analyticsFilters),
    ...options,
  });
}
