import { useEffect } from 'react';
import styled from 'styled-components';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { colors } from 'styles/theme/colors';

type MoreContentRequesterType = {
  resource: UseInfiniteQueryResult<InfiniteData<any, unknown>, Error>;
};

export default function MoreContentRequester({ resource }: MoreContentRequesterType) {
  const { isIntersecting, customRef } = useIntersectionObserver();

  useEffect(() => {
    if (!resource.isLoading && isIntersecting && resource.hasNextPage) {
      resource.fetchNextPage({ cancelRefetch: false });
    }
  }, [isIntersecting, resource]);
  if (resource.hasNextPage) {
    return (
      <MoreContentWidget ref={customRef}>
        {resource.isFetchingNextPage && <CircularProgress color={colors.primaryBlue} />}
      </MoreContentWidget>
    );
  }
}

const MoreContentWidget = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
