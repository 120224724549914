import ProgressChart, { ProgressChartOptions } from 'models/ProgressChart';
import http from 'services/http';

export const progressChartQueryKeys = {
  show: (id: string) => ['progressChart', id] as const,
};

export async function showProgressChart(locationEpisodeId: string) {
  return http
    .get<ProgressChartOptions>(`location_episodes/${locationEpisodeId}/progress_chart`)
    .then((res) => new ProgressChart(res.data));
}
