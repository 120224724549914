import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import { colors } from 'styles/theme/colors';

export type CustomImageStyles = {
  maxWidth?: string;
  maxHeight?: string;
};

type LazyImageInput = {
  className?: string;
  customStyles: CustomImageStyles;
  onLoaded?: (t: boolean) => void;
  onClick?: () => void;
  src: string | null;
  alt: string;
  useSpinner?: boolean;
};

function LazyImage(props: LazyImageInput) {
  const { alt, className, onClick, onLoaded, src, useSpinner } = props;

  const placeholderSource = '/image-placeholder.svg';
  const [loading, setLoading] = useState(true);
  const [source, setSource] = useState(placeholderSource || src);

  const onImageError = () => {
    setSource(placeholderSource);
    setLoading(false);
  };

  const onImageLoad = () => {
    setSource(src ?? '');
    setLoading(false);
    onLoaded?.(true);
  };

  useEffect(() => {
    if (src && src !== placeholderSource) {
      const img = new Image();

      img.src = src;

      img.onerror = onImageError;
      img.onload = onImageLoad;
    }
    // disabling to reduce boilerplate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return loading && useSpinner ? (
    <CircularProgress color={colors.primaryBlue} />
  ) : (
    <StyledImage {...props} alt={alt} className={className} loading={loading} onClick={onClick} src={source} />
  );
}

export default LazyImage;

const StyledImage = styled(
  ({
    alt,
    className,
    onClick,
    src,
  }: {
    customStyles: CustomImageStyles;
    loading: boolean;
    alt: string;
    className?: string;
    onClick?: () => void;
    src: string;
  }) => <img alt={alt} src={src} className={className} onClick={onClick} />
)`
  max-height: ${({ customStyles }) => customStyles?.maxHeight || 'initial'};
  max-width: ${({ customStyles }) => customStyles?.maxWidth || 'initial'};

  ${({ loading }) =>
    loading &&
    css`
      filter: blur(10px);
    `};

  ${({ loading }) =>
    !loading &&
    css`
      filter: blur(0px);
      transition: filter 0.5s linear;
    `};
`;
