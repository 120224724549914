export default function AdmissionEmptyState(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='80px'
      height='80px'
      viewBox='0 0 80 80'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M19.9401796,3.33333333 C12.6128863,3.34169306 6.67502639,9.27955298 6.66666667,16.6068462 C6.66666667,23.4597713 17.3594294,34.6834746 18.5786964,35.9482507 C18.9358485,36.3166694 19.4270617,36.5246187 19.9401796,36.5246187 C20.4532974,36.5246187 20.9445106,36.3166694 21.3016627,35.9482507 C22.5209297,34.6834746 33.2136924,23.4597713 33.2136924,16.6068462 C33.2053327,9.27955298 27.2674728,3.34169306 19.9401796,3.33333333 Z M19.9401796,22.2954946 C16.7984258,22.2954946 14.2515312,19.7486 14.2515312,16.6068462 C14.2515312,13.4650925 16.7984258,10.9181978 19.9401796,10.9181978 C23.0819333,10.9181978 25.6288279,13.4650925 25.6288279,16.6068462 C25.6288279,18.1155696 25.0294899,19.5624996 23.9626614,20.6293281 C22.8958329,21.6961566 21.4489029,22.2954946 19.9401796,22.2954946 Z'
          id='admission-empty-path-1'
        />
      </defs>
      <g id='Feedback' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='All-Lanes-Empty---Disabled' transform='translate(-547.000000, -590.000000)'>
          <g id='Group-3-Copy' transform='translate(520.000000, 590.000000)'>
            <g id='Group-2' transform='translate(27.000000, 0.000000)'>
              <circle id='Oval' fillOpacity='0.1' fill='#0F1226' cx='40' cy='40' r='40' />
              <g id='Icon/Group' opacity='0.25' transform='translate(20.000000, 20.000000)'>
                <mask id='admission-empty-mask-2' fill='white'>
                  <use xlinkHref='#admission-empty-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#admission-empty-mask-2)' fill='#0F1226' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='40' height='40' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
