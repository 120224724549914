export default function EscalationCountIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.1001 9.77031L6.03164 0.992483C6.09885 0.873926 6.19796 0.774991 6.31855 0.706066C6.43914 0.637141 6.57678 0.600769 6.71699 0.600769C6.85721 0.600769 6.99484 0.637141 7.11544 0.706066C7.23603 0.774991 7.33514 0.873926 7.40235 0.992483L12.3339 9.77031C12.3975 9.88428 12.4292 10.0122 12.4261 10.1416C12.4229 10.2709 12.3849 10.3972 12.3158 10.5082C12.2467 10.6191 12.1489 10.7109 12.0319 10.7745C11.9149 10.838 11.7828 10.8712 11.6485 10.8708H1.78622C1.65185 10.8713 1.51965 10.8382 1.40256 10.7747C1.28548 10.7112 1.18755 10.6195 1.11836 10.5085C1.04917 10.3975 1.01111 10.2712 1.00791 10.1417C1.00471 10.0123 1.03648 9.88433 1.1001 9.77031Z'
        stroke='#0F1226'
        strokeWidth='0.95155'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M6.71692 3.5343V7.20258'
        stroke='#0F1226'
        strokeWidth='0.95155'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M6.65355 9.28119C6.93393 9.28119 7.16122 9.06221 7.16122 8.79208C7.16122 8.52196 6.93393 8.30298 6.65355 8.30298C6.37317 8.30298 6.14587 8.52196 6.14587 8.79208C6.14587 9.06221 6.37317 9.28119 6.65355 9.28119Z'
        fill='#0F1226'
      />
    </svg>
  );
}
