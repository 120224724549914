import { create } from 'zustand';

export type Toast = { text: string; id: number };

type Actions = { addToast: (toast: { text: string }) => void; removeToast: (id: number) => void };

type State = {
  count: number;
  toasts: Toast[];
  actions: Actions;
};

export const useToastStore = create<State>((set) => ({
  count: 0,
  toasts: [],
  actions: {
    addToast: (toast) =>
      set((state) => {
        state.count++;
        return { toasts: [...state.toasts, { ...toast, id: state.count }] };
      }),
    removeToast: (id) => set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== id) })),
  },
}));

export const useToastActions = () => useToastStore((state) => state.actions);
