import { useState } from 'react';

type PaginationOptions = {
  page?: number;
  pageSize?: number;
};

const usePagination = (defaultOptions: PaginationOptions = {}) => {
  const defaults = {
    page: 1,
    pageSize: 10,
    ...defaultOptions,
  };

  const [pageIndex, setPageIndex] = useState(defaults.page - 1);
  const [pageSize, setPageSize] = useState(defaults.pageSize);

  return {
    paging: { page: pageIndex + 1, pageSize },
    setPageIndex,
    setPageSize,
  };
};

export default usePagination;
