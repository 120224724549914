export default function AuthValidThroughIcon({ width = 12, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_2972_11189)'>
        <path
          d='M2.27384 3.92376C2.62134 5.92939 3.84258 7.30314 6.00008 8.00002C8.15758 7.30314 9.37883 5.92939 9.72633 3.92376C9.74695 3.78973 9.73824 3.65282 9.70081 3.52248C9.66338 3.39214 9.59812 3.27147 9.50953 3.1688C9.42094 3.06613 9.31113 2.9839 9.18768 2.92778C9.06422 2.87167 8.93007 2.843 8.79446 2.84376H3.20571C3.0701 2.843 2.93595 2.87167 2.81249 2.92778C2.68904 2.9839 2.57923 3.06613 2.49064 3.1688C2.40205 3.27147 2.33679 3.39214 2.29936 3.52248C2.26193 3.65282 2.25322 3.78973 2.27384 3.92376Z'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M0.84375 0.96875H11.1563' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M3.20561 13.1563C3.07007 13.157 2.93597 13.1283 2.81257 13.0723C2.68917 13.0162 2.5794 12.934 2.49082 12.8314C2.40224 12.7288 2.33696 12.6082 2.29948 12.478C2.26201 12.3477 2.25322 12.2109 2.27374 12.0769C2.62124 10.0712 3.84249 8.6975 5.99999 8C8.15749 8.6975 9.37874 10.0712 9.72624 12.0769C9.74676 12.2109 9.73797 12.3477 9.7005 12.478C9.66302 12.6082 9.59774 12.7288 9.50916 12.8314C9.42058 12.934 9.31081 13.0162 9.18741 13.0723C9.06401 13.1283 8.92991 13.157 8.79436 13.1563H3.20561Z'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M0.84375 15.0312H11.1563' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2972_11189'>
          <rect width='12' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
