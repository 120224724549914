import { useCallback, useLayoutEffect, useState } from 'react';

const defaultRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

export default (ref: any) => {
  const rect = useCallback(() => {
    if (ref && ref.current) return ref.current.getBoundingClientRect();

    return defaultRect;
  }, [ref]);

  const [dimensions, setRect] = useState(rect);

  const handleResize = useCallback(() => {
    if (ref !== null) setRect(rect);
  }, [rect, ref]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, ref]);

  return dimensions;
};
