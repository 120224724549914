import GroupType, { GroupTypeOptions } from './GroupType';

export type ClientGroupTypeConfig = {
  projectedDischarge: {
    enabled: boolean;
  };
  utilizationManagement: {
    enabled: boolean;
    planTypeClassification: string[];
  };
};
export interface ClientGroupTypeOptions {
  id: string;
  clientId: string;
  groupTypeId: string;
  config: ClientGroupTypeConfig;
  groupType: Partial<GroupTypeOptions> | null;
}

function getDefaults(): ClientGroupTypeOptions {
  return {
    id: '',
    clientId: '',
    groupTypeId: '',
    config: {
      projectedDischarge: {
        enabled: false,
      },
      utilizationManagement: {
        enabled: false,
        planTypeClassification: [],
      },
    },
    groupType: {},
  };
}
/**
 * @class ClientGroupType
 * @classdesc Represents a client group type in the system
 * @property {string} id - The client group type's id
 * @property {string} clientId - The assocated client id
 * @property {string} groupTypeId - The associated group type id
 * @property {GroupType | null} groupType - The client group type's group type
 * @property {ClientGroupTypeConfig} config - The client group type's configuration
 * @param {Partial<ClientGroupTypeOptions>} [options={}]
 */
export default class ClientGroupType {
  id: string;
  clientId: string;
  groupTypeId: string;
  config: ClientGroupTypeConfig;
  groupType: GroupType | null;

  constructor(options = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.config = opts.config;
    this.groupType = opts.groupType ? new GroupType(opts.groupType) : null;
  }
}
