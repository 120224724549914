import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Intake from 'components/intake/Intake';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import { FormHelpers } from 'context/form/types';
import Patient from 'models/Patient';
import { createPatient } from 'services/api/patient';
import { portfolioQueryKeys } from 'services/api/portfolio';
import { useToastActions } from 'stores/toastStore';

export default function AddPatientPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToastActions();

  const { mutate: createNewPatient } = useMutation({
    mutationFn: createPatient,
  });

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    helpers.setMeta('isSubmitting', true);

    const patientValues = Patient.fromFormValues(values).serialize();

    createNewPatient(patientValues, {
      onSuccess: (patient) => {
        addToast({ text: 'Patient successfully added.' });
        queryClient.invalidateQueries({ queryKey: portfolioQueryKeys.lane('queue') });
        navigate(`/patients/${patient.episodeId}`);
      },
      onError: () => {
        addToast({ text: 'Something went wrong. Please try again.' });
      },
      onSettled: () => {
        helpers.setMeta('isSubmitting', false);
      },
    });
  };

  const patient = new Patient();
  const initialValues = patient.intakeFormValues();

  return (
    <Intake
      initialMeta={{ isSubmitting: false, mode: PatientFormMode.New }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      patient={patient}
    />
  );
}
