export default function InfoIconOutline({
  width = 16,
  height = 16,
  fill = '#0F1226',
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...rest}>
      <g id='match-on-add-/-connect' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='confirm-match-in-discharge-state'
          transform='translate(-535.000000, -300.000000)'
          fill={fill}
          fillRule='nonzero'>
          <g id='Group-10' transform='translate(535.000000, 299.000000)'>
            <g id='c-info-e' transform='translate(0.000000, 1.000000)'>
              <path
                d='M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M8,14 C4.7,14 2,11.3 2,8 C2,4.7 4.7,2 8,2 C11.3,2 14,4.7 14,8 C14,11.3 11.3,14 8,14 Z'
                id='Shape'
              />
              <rect id='Rectangle' x='7' y='7' width='2' height='5' />
              <circle id='Oval' cx='8' cy='5' r='1' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
