import styled from 'styled-components';

import { BodySmall, LabelBold } from 'styles/typography';
import DeactivatedUserIcon from 'svg/DeactivatedUserIcon';
import PatientNotFoundIcon from 'svg/PatientNotFoundIcon';

export enum ErrorStateKind {
  RequestAccess,
  PatientNotFound,
  SomethingWentWrong,
}

type ErrorStateType = {
  kind: ErrorStateKind;
};

export function ErrorState(props: ErrorStateType) {
  const { kind } = props;
  const titleText =
    kind == ErrorStateKind.PatientNotFound
      ? 'Patient Not Found'
      : kind == ErrorStateKind.RequestAccess
        ? 'Access Required'
        : 'Something went wrong';

  const subtitleText =
    kind == ErrorStateKind.PatientNotFound ? (
      'No record exists in Olio for this patient'
    ) : kind == ErrorStateKind.RequestAccess ? (
      <>
        You do not currently have access to Olio. Please contact <EmailLink>support@olio.health</EmailLink> to request
        access.
      </>
    ) : (
      'An error has occurred. Please contact your system administrator.'
    );

  const Icon = kind == ErrorStateKind.PatientNotFound ? PatientNotFoundIcon : DeactivatedUserIcon;

  return (
    <>
      <Icon />
      <StyledBody>
        <LabelBold fontSize='14px'>{titleText}</LabelBold>
        <br />
        {subtitleText}
      </StyledBody>
    </>
  );
}

const StyledBody = styled(BodySmall)`
  margin-top: 24px;
  width: 100%;
  text-align: center;
  padding: 0 24px;
`;

const EmailLink = styled.span`
  font-weight: var(--font-weight-bold);
  color: var(--primary-blue);
`;
