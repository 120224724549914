import User, { UserOptions } from 'models/User';

export type LocationEpisodeUserOptions = Partial<UserOptions> & { userRole: AssignedUserRole };

export enum AssignedUserRole {
  CaseManager = 'case_manager',
  UtilizationManager = 'utilization_manager',
}

export class LocationEpisodeUser extends User {
  userRole?: AssignedUserRole;
  constructor(options: Partial<LocationEpisodeUserOptions>) {
    super(options);
    this.userRole = options.userRole;
  }
}
