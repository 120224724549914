import { useState } from 'react';

import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { DataRow, getRollup, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import MetricTile from './MetricTile';

export default function TotalDischargesTile() {
  const filters = useInsightsStore((state) => state.filters);

  const [totalDischarges, setTotalDischarges] = useState(-1);
  const [categories, setCategories] = useState([] as string[]);
  const [values, setValues] = useState([] as number[]);

  const request = {
    params: {
      source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
      dimensions: [DimensionConstants.DATE],
      metrics: [MetricConstants.ID_COUNT],
      rollups: true,
    },
    processData: (data: DataRow[]) => {
      const [totalDischarges] = getRollup(data);
      setTotalDischarges(totalDischarges);

      const dateRange = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
        (acc, formattedDate) => {
          acc[formattedDate] = 0;
          return acc;
        },
        {}
      );

      data.forEach((row) => {
        const dimensionValues = parseDimensionValues(row, false) as string[];
        const metricValues = parseMetricValues(row);

        const date = dimensionValues[0];
        const dischargesCount = metricValues[0];

        if (date) {
          dateRange[date] = dischargesCount;
        }
      });

      setCategories(Object.keys(dateRange).map(formatDateLabel));
      setValues(Object.values(dateRange));
    },
  };

  const { loading, customRef } = useDataFetch([request], { onIntersecting: true });

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsTotalDischarges'
      data-cy='insightsTotalDischarges'
      label='Total Discharges'
      showValue
      loading={loading}
      hasData={totalDischarges !== -1 && values.some((value) => value)}
      value={`${totalDischarges}`}
      navigateTo={RouteConstants.TOTAL_DISCHARGES}>
      <AreaChart
        formatTooltipSupertext={(x) => `${x}`}
        formatTooltipSubtext={(y) => `Total Discharges: ${y}`}
        categories={categories}
        values={values}
      />
    </MetricTile>
  );
}
