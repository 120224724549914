export default function ChartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' {...props}>
      <path
        fill='currentColor'
        fillOpacity='.25'
        d='m12.9 23.578.887.494 4.25-8.498 13.17 9.707a1.124 1.124 0 0 0 1.584-.252L46.57 5.739 44.738 4.43 31.623 22.793l-13.33-9.824a1.125 1.125 0 0 0-1.674.403l-4.943 9.885c.428.006.848.116 1.224.321ZM10.945 28.5H9.02L1.486 43.24l2.004 1.024 7.924-15.504-.47-.26Z'
      />
      <path
        fill='currentColor'
        fillOpacity='.25'
        d='M31.875 38.25a1.13 1.13 0 0 1-.547-.141L11.333 27H1.5v-2.25h10.125c.191 0 .38.049.547.141L31.8 35.797l13.463-9.617 1.308 1.832L32.529 38.04c-.19.136-.42.21-.654.209Z'
      />
    </svg>
  );
}
