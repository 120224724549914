import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css, DefaultTheme } from 'styled-components';

import Slot from 'components/shared/Slot';

type Position = 'top' | 'right' | 'bottom' | 'left';

type TooltipProps = {
  children: React.ReactNode;
  text: string;
  disabled?: boolean;
  position?: Position;
};

const arrowWidth = 8;
const marginFromContent = 4;
const addedWidth = arrowWidth + marginFromContent;

function calculateCoordinates(position: Position, trigger: DOMRect, tooltip: DOMRect): { x: number; y: number } {
  const { top, height, right, left } = trigger;
  const { height: tooltipHeight, width: tooltipWidth } = tooltip;

  switch (position) {
    case 'right':
      return { x: right + addedWidth, y: top + height / 2 - tooltipHeight / 2 };
    case 'left':
      return { x: left - tooltipWidth - addedWidth, y: top + height / 2 - tooltipHeight / 2 };
    case 'top':
      return { x: left + (right - left) / 2 - tooltipWidth / 2, y: top - tooltipHeight - addedWidth };
    case 'bottom':
      return { x: left + (right - left) / 2 - tooltipWidth / 2, y: top + height + addedWidth };
    default:
      return { x: 0, y: 0 };
  }
}

export default function Tooltip({ children, text, disabled = false, position = 'top' }: TooltipProps) {
  const [visible, setVisible] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (!tooltipRef.current) return;

    setCoordinates(
      calculateCoordinates(
        position,
        event.currentTarget?.getBoundingClientRect(),
        tooltipRef.current?.getBoundingClientRect()
      )
    );

    setVisible(true);
  };

  const handleScroll = useCallback(() => {
    if (!visible) return;

    setVisible(false);
  }, [visible]);

  useLayoutEffect(() => {
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <Slot children={children} onMouseEnter={handleShowTooltip} onMouseLeave={() => setVisible(false)} />

      {createPortal(
        <Content
          ref={tooltipRef}
          style={{ top: coordinates.y, left: coordinates.x }}
          $position={position}
          $visible={visible}>
          {text}
        </Content>,
        document.body
      )}
    </>
  );
}

const Content = styled.div<{ $visible: boolean; $position: Position }>`
  position: absolute;
  background-color: #222;
  color: ${({ theme }) => theme.colors.white};
  max-width: 200px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  z-index: 9999;
  font-size: 12px;
  padding: 8px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.3s;
  pointer-events: none;

  // the tooltip arrow
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;

    ${({ theme, $position }) => arrowStyles(theme, $position)}
  }
`;

const arrowStyles = (theme: DefaultTheme, position: Position) => {
  if (position === 'right') {
    return css`
      top: 50%;
      transform: translateY(-50%);
      left: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #222;
    `;
  }

  if (position === 'left') {
    return css`
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #222;
    `;
  }

  if (position === 'top') {
    return css`
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #222;
    `;
  }

  return css`
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #222;
  `;
};
