export default function DischargedEmptyState(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='80px'
      height='80px'
      viewBox='0 0 80 80'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M4.6969697,5 C5.22606061,5 6.31287879,5.85090909 6.49151515,5.97909091 L15.0360606,12.0936364 C15.3933333,12.3513636 15.6060606,12.7631818 15.6060606,13.2036364 L15.6060606,33.6363636 C15.6060606,34.1477273 15.3210606,34.6154545 14.8669697,34.85 C14.6692424,34.9495455 14.4551515,35 14.2424242,35 C13.9628788,35 13.6860606,34.9154545 13.4501515,34.745 L3.90469697,27.9268182 C3.54606061,27.6718182 3.33333333,27.2586364 3.33333333,26.8181818 L3.33333333,6.36363636 C3.33333333,5.60954545 3.94424242,5 4.6969697,5 Z M21.0606061,5 C21.8133333,5 22.4242424,5.60954545 22.4242424,6.36363636 L22.4242424,6.36363636 L22.4242424,15.9090909 L27.8787879,15.9090909 L27.8787879,10.4545455 L36.0606061,17.2727273 L27.8787879,24.0909091 L27.8787879,18.6363636 L22.4242424,18.6363636 L22.4242424,26.8181818 C22.4242424,27.5722727 21.8133333,28.1818182 21.0606061,28.1818182 L21.0606061,28.1818182 L18.3333333,28.1818182 L18.3333333,13.2036364 C18.3333333,11.8904545 17.6951515,10.6468182 16.6233333,9.87636364 L16.6233333,9.87636364 L9.8119697,5 Z'
          id='discharged-empty-path-1'
        />
      </defs>
      <g id='Feedback' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='All-Lanes-Empty---Disabled' transform='translate(-1109.000000, -590.000000)'>
          <g id='Group-3-Copy-3' transform='translate(1080.000000, 590.000000)'>
            <g id='Group-2' transform='translate(29.000000, 0.000000)'>
              <circle id='Oval' fillOpacity='0.1' fill='#0F1226' cx='40' cy='40' r='40' />
              <g id='Icon/Discharged' opacity='0.25' transform='translate(20.000000, 20.000000)'>
                <mask id='discharged-empty-mask-2' fill='white'>
                  <use xlinkHref='#discharged-empty-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#discharged-empty-mask-2)' fill='#0F1226' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='40' height='40' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
