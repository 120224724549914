import React from 'react';
import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import { colors } from 'styles/theme/colors';

function LoadingOverlay() {
  return (
    <StyledLoadingOverlay>
      <StyledSpinnerContainer>
        <CircularProgress color={colors.primaryBlue} />
      </StyledSpinnerContainer>
    </StyledLoadingOverlay>
  );
}

export default LoadingOverlay;

const StyledLoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
`;

const StyledSpinnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black25};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
`;
