import { useState } from 'react';
import styled from 'styled-components';

import { useQueryClient } from '@tanstack/react-query';

import CreateAltcsApplicationModal from 'components/modals/altcs/CreateAltcsApplicationModal';
import Button from 'components/shared/Button';
import LocationEpisode from 'models/LocationEpisode';
import { episodeQueryKeys } from 'services/api/episode';
import { BodySmall } from 'styles/typography';

type AltcsPendingProps = {
  locationEpisode: LocationEpisode;
  patientName: string;
};

export default function AltcsPending({ locationEpisode, patientName }: AltcsPendingProps) {
  const queryClient = useQueryClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const invalidateData = () => {
    queryClient.invalidateQueries({
      queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }),
    });
  };

  return (
    <>
      <Header>
        <BodySmall>Click below to start ALTCS Pending custodial stay after MDR Approval (UMRN Only)</BodySmall>
      </Header>
      <Actions>
        <StyledButton
          size='xsmall'
          variant='outline-primary'
          fullWidth
          onClick={() => setShowConfirmModal(true)}
          data-cy='altcsPendingButton'>
          Update to ALTCS Pending
        </StyledButton>
      </Actions>

      {showConfirmModal && (
        <CreateAltcsApplicationModal
          patientName={patientName}
          locationEpisodeId={locationEpisode.id}
          setShow={setShowConfirmModal}
          onSuccess={invalidateData}
        />
      )}
    </>
  );
}

const Header = styled.div`
  display: flex;
  padding: 24px 24px 0;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  border-width: 1px;
`;

const Actions = styled.div`
  padding: 0 24px 24px;
`;
