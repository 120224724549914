export default function ActivityNoteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M9.77777778,1.33333333 L2.55555556,1.33333333 C2.24888889,1.33333333 2,1.58222222 2,1.88888889 L2,14.1111111 C2,14.4177778 2.24888889,14.6666667 2.55555556,14.6666667 L13.6666667,14.6666667 C13.9733333,14.6666667 14.2222222,14.4177778 14.2222222,14.1111111 L14.2222222,5.77777778 L10.3333333,5.77777778 C10.0266667,5.77777778 9.77777778,5.52888889 9.77777778,5.22222222 L9.77777778,1.33333333 Z M4.22222222,10.7777778 L12,10.7777778 L12,11.8888889 L4.22222222,11.8888889 L4.22222222,10.7777778 Z M4.22222222,8 L12,8 L12,9.11111111 L4.22222222,9.11111111 L4.22222222,8 Z M7.55555556,5.22222222 L7.55555556,6.33333333 L4.22222222,6.33333333 L4.22222222,5.22222222 L7.55555556,5.22222222 Z M10.8888889,1.65888889 L13.8966667,4.66666667 L10.8888889,4.66666667 L10.8888889,1.65888889 Z'
          id='activity-note-path-1'
        />
      </defs>
      <g id='Escalations/Priority-Notes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Unacknowledged-Escalation' transform='translate(-574.000000, -840.000000)'>
          <g id='Group-10' transform='translate(560.000000, 822.000000)'>
            <g id='Group-8' transform='translate(14.000000, 18.000000)'>
              <rect id='Rectangle-Copy' fillOpacity='0.25' fill='#3253EF' x='0' y='0' width='24' height='24' rx='3' />
              <g id='Icon/Note' transform='translate(4.000000, 4.000000)'>
                <mask id='activity-note-mask-2' fill='white'>
                  <use xlinkHref='#activity-note-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#activity-note-mask-2)' fill='#3253EF' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='16' height='16' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
