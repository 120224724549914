import styled from 'styled-components';

import Button from 'components/shared/Button';
import CircularProgress from 'components/shared/CircularProgress';
import useIsMobile from 'hooks/useIsMobile';
import { colors } from 'styles/theme/colors';
import ChartIconLarge from 'svg/ChartIconLarge';
import ChevronLeft from 'svg/ChevronLeft';

type Props = {
  children: React.ReactNode;
  hasData?: boolean;
  loading?: boolean;
  onBackClick: () => void;
  header: JSX.Element;
};

export default function MetricDetailContainer(props: Props) {
  const { children, hasData = true, loading = false, onBackClick, header, ...rest } = props;

  const isMobile = useIsMobile();

  const chevronProps = isMobile && { width: '16', height: '16' };

  return (
    <Container {...rest}>
      <FlexContainer>
        {onBackClick && (
          <BackButtonContainer onClick={onBackClick} variant='icon'>
            <Chevron {...chevronProps} />
          </BackButtonContainer>
        )}
        {header}
      </FlexContainer>
      {!isMobile && (
        <>
          {loading ? (
            <SpinnerContainer>
              <CircularProgress color={colors.primaryBlue} />
            </SpinnerContainer>
          ) : (
            <MetricContent>
              {hasData ? (
                children
              ) : (
                <EmptyState>
                  <ChartIconLarge color='#3253EF' />
                  <EmptyText>No data to show for the selected date range or filters</EmptyText>
                </EmptyState>
              )}
            </MetricContent>
          )}
        </>
      )}
    </Container>
  );
}

const BackButtonContainer = styled(Button)`
  height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
`;

const Container = styled.div`
  height: '100%';
  max-width: 1280px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 24px 24px 24px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.desktop} {
    height: 360px;
    padding-bottom: 8px;
  }
`;

const Chevron = styled(ChevronLeft)`
  margin-right: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 16px;
  font-weight: normal;
  margin-top: 16px;
  text-align: center;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const MetricContent = styled.div`
  min-height: 200px;
  margin-top: 24px;
  width: 100%;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
