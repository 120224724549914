import styled, { css } from 'styled-components';

import { colors } from 'styles/theme/colors';

export const InputStyles = css<{ $hasError?: boolean; disabled?: boolean; $width?: string }>`
  box-sizing: border-box;
  width: ${({ $width }) => $width ?? '100%'};
  border: 1px solid
    ${({ $hasError, disabled }) => {
      let borderColor = colors.black25;

      if ($hasError) {
        borderColor = colors.accentRed;
      }

      if (disabled) {
        borderColor = 'transparent';
      }

      return borderColor;
    }};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  background-color: ${({ disabled }) => (disabled ? colors.black10 : colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  outline: none;

  &::placeholder {
    color: ${colors.black25};
  }

  &:focus {
    border-color: ${({ $hasError }) => ($hasError ? colors.accentRed : colors.primaryBlue)};
  }
`;

const Input = styled.input<{
  $hasError?: boolean;
  disabled?: boolean;
  $width?: string;
}>`
  ${InputStyles}
`;

export default Input;
