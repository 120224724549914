import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useProfile } from 'context/profile';
import { ClientOptions } from 'models/Client';
import Episode from 'models/Episode';
import { Paginated } from 'models/Paginated';
import { forResourceClients } from 'services/api/client';
import { updateProfile } from 'services/api/profile';
import { useToastActions } from 'stores/toastStore';

export function useActingClientAuthorizedCheck(episodeId?: string) {
  const { profile } = useProfile();
  const { addToast } = useToastActions();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: updateProfile,
  });
  const autoAuthorize = !profile.canChangeClientScope;
  const [authorizationChecked, setAuthorizationChecked] = useState(autoAuthorize);

  const checkActingClientAuthorized = useCallback(
    async (queryResponse: Paginated<ClientOptions>) => {
      try {
        const clientIds = queryResponse?.data.map((client) => client.id) ?? [];

        if (!clientIds.includes(profile.actingClient.id)) {
          const newActingClientId = profile.isAdmin
            ? clientIds[0]
            : clientIds.find((id) => profile.client?.leafDescendantIds.includes(id));

          if (!newActingClientId) {
            addToast({ text: 'Patient not found' });
            navigate('/patients');
            return;
          }

          // Use mutateAsync to ensure the profile query data is updated synchronously
          // before any dependent code acts on "authorizationChecked" state change
          const response = await mutateAsync({ actingClientId: newActingClientId });

          queryClient.setQueryData(['profile'], () => response);
          setAuthorizationChecked(true);
        }

        setAuthorizationChecked(true);
      } catch (e) {
        addToast({ text: e.message });
      }
    },
    [addToast, mutateAsync, navigate, queryClient, profile]
  );

  useQuery({
    queryKey: ['clientsForResource', { episodeId }],
    queryFn: ({ signal }) => forResourceClients(new Episode({ id: episodeId }), signal),
    enabled: !authorizationChecked && !!episodeId,
    select: checkActingClientAuthorized,
  });

  return authorizationChecked;
}
