import styled from 'styled-components';

import { PreferencesFormMeta, PreferencesFormValues } from 'components/notificationPreferences/NotificationPreferences';
import { MultiSelectChips } from 'components/shared/form/InfiniteScrollDropdown.tsx';
import InputGroup from 'components/shared/form/InputGroup';
import SectionToggle from 'components/shared/SectionToggle.tsx';
import { TabType } from 'components/Tabs';
import { useForm } from 'context/form';
import Classification, { ClassificationApiName } from 'models/Classification.ts';
import Group from 'models/Group.ts';
import { Notification } from 'models/Notifications';
import User from 'models/User';
import { useInfiniteClassifications } from 'services/api/classification';
import { useInfiniteGroups } from 'services/api/group';
import { useInfiniteUsers } from 'services/api/user';
import { BodySmall, H3 } from 'styles/typography';

import NotificationCategoryPreferences from './NotificationCategoryPreferences';

function NotificationPreferencesContainer({
  actingClientId,
  hasCaseManagerAssignmentFlag,
  hasUtilizationManagerAssignmentFlag,
  selectedGroupType,
}: {
  actingClientId: string;
  hasCaseManagerAssignmentFlag: boolean;
  hasUtilizationManagerAssignmentFlag: boolean;
  selectedGroupType: TabType;
}) {
  const { values, setValue } = useForm<PreferencesFormValues, PreferencesFormMeta>();

  const groupTypeValues = values[selectedGroupType.value] ?? ({} as Notification);

  const name = selectedGroupType.label;

  const asyncGroupOptions = useInfiniteGroups({
    type: groupTypeValues.groupTypeApiName,
    include: 'groupType',
    sortBy: 'name asc',
  });

  const asyncPlanTypeOptions = useInfiniteClassifications({
    type: ClassificationApiName.PLAN_TYPE,
    sortBy: 'name',
  });

  const asyncEpisodeTypeOptions = useInfiniteClassifications({
    type: ClassificationApiName.EPISODE,
    sortBy: 'name',
  });

  const asyncUserOptions = useInfiniteUsers({
    include: 'credential',
    sortBy: 'name',
    active: true,
    client: actingClientId,
  });

  const getSelectedCountLabel = (selectedItems: any[] | undefined) => {
    return ((selectedItems ?? []).length == 0 ? 'All' : `${selectedItems?.length}`) + ' selected';
  };

  return (
    <ToggleAndFormContainer>
      <SectionToggle
        active={groupTypeValues.active}
        disabled={false}
        onChange={(active) => setValue(`${selectedGroupType.value}.active`, active)}
        label={`Receive notifications for ${name}`}
      />
      <StyledFormContainer>
        <SectionContainer>
          <StyledH3>Notification Settings</StyledH3>
          <SectionDescription>
            Indicate below if you wish to only receive notifications for {name} patients that meet a specific set of
            criteria
          </SectionDescription>
          <InputGroup title={`Specific ${name}s - ${getSelectedCountLabel(groupTypeValues.scopes?.locations)}`}>
            <MultiSelectChips<Group>
              {...asyncGroupOptions}
              disabled={!groupTypeValues.active}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(locations) => {
                setValue(`${selectedGroupType.value}.scopes.locations`, locations);
              }}
              values={groupTypeValues.scopes?.locations ?? []}
            />
          </InputGroup>

          <InputGroup title={`Specific Plan Types - ${getSelectedCountLabel(groupTypeValues.scopes?.planTypes)}`}>
            <MultiSelectChips<Classification>
              {...asyncPlanTypeOptions}
              disabled={!groupTypeValues.active}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(planTypes) => {
                setValue(`${selectedGroupType.value}.scopes.planTypes`, planTypes);
              }}
              values={groupTypeValues.scopes?.planTypes ?? []}
            />
          </InputGroup>

          <InputGroup title={`Specific Episode Types - ${getSelectedCountLabel(groupTypeValues.scopes?.episodes)}`}>
            <MultiSelectChips<Classification>
              {...asyncEpisodeTypeOptions}
              disabled={!groupTypeValues.active}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(episodes) => {
                setValue(`${selectedGroupType.value}.scopes.episodes`, episodes);
              }}
              values={groupTypeValues.scopes?.episodes ?? []}
            />
          </InputGroup>

          {hasUtilizationManagerAssignmentFlag && (
            <InputGroup
              title={`Specific Utilization Managers - ${getSelectedCountLabel(groupTypeValues.scopes?.utilizationManagers)}`}>
              <MultiSelectChips<User>
                {...asyncUserOptions}
                disabled={!groupTypeValues.active}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(users) => {
                  setValue(`${selectedGroupType.value}.scopes.utilizationManagers`, users);
                }}
                values={groupTypeValues.scopes?.utilizationManagers ?? []}
              />
            </InputGroup>
          )}

          {hasCaseManagerAssignmentFlag && (
            <InputGroup
              title={`Specific Case Managers - ${getSelectedCountLabel(groupTypeValues.scopes?.caseManagers)}`}>
              <MultiSelectChips<User>
                {...asyncUserOptions}
                disabled={!groupTypeValues.active}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(users) => {
                  setValue(`${selectedGroupType.value}.scopes.caseManagers`, users);
                }}
                values={groupTypeValues.scopes?.caseManagers ?? []}
              />
            </InputGroup>
          )}
        </SectionContainer>
        <SectionContainer>
          <StyledH3>Select Notifications</StyledH3>
          {['criticalAlerts', 'activity', 'patientStatusChanges'].map((category) => (
            <NotificationCategoryPreferences
              key={category}
              category={category}
              selectedTab={selectedGroupType}
              disabled={!groupTypeValues.active}
            />
          ))}
        </SectionContainer>
      </StyledFormContainer>
    </ToggleAndFormContainer>
  );
}

const SectionContainer = styled.div`
  height: fit-content;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.black10};
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
  width: 550px;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 12px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`;

const ToggleAndFormContainer = styled.div`
  /* Each card is 550px wide, margin between cards is 24px */
  max-width: calc(1100px + 24px);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionDescription = styled(BodySmall)`
  font-size: 12px;
  margin-bottom: 24px;
`;

export default NotificationPreferencesContainer;
