import styled from 'styled-components';

import colors from 'styles/theme/colors';

type Props = {
  icon: React.ElementType;
  label: string;
  value: number | string;
};

export default function MetricValue({ icon: Icon, label, value }: Props) {
  return (
    <Box>
      <Icon />
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Box>
  );
}

const Box = styled.div`
  background-color: ${colors.black03};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1;
  flex-direction: column;
`;

const Label = styled.p`
  font-size: 12px;
  color: ${colors.black};
`;

const Value = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.black};
`;
