import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  Changes,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface ProjectedDischargeUpdatesOptions extends ActivityOptions {
  type: ActivityType.PROJECTED_DISCHARGE_UPDATES;
  data: Partial<BaseActivityDataOptions & { changes: Changes }>;
}

export function getDefaults(): ProjectedDischargeUpdatesOptions {
  const defaults = getActivityDefaults();
  return {
    ...defaults,
    type: ActivityType.PROJECTED_DISCHARGE_UPDATES,
    data: {
      ...defaults.data,
      changes: [],
    },
  };
}

export default class ProjectedDischargeUpdates extends Activity {
  declare type: ActivityType.PROJECTED_DISCHARGE_UPDATES;
  declare data: BaseActivityData & { changes: Changes };

  constructor(options: Partial<ProjectedDischargeUpdatesOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Projected Discharge';
  }
}
