import UserPreference, {
  getDefaults as getUserPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from 'models/UserPreference';
import { ProviderType } from 'models/userPreferences/types';

export type InsightsFilterValue = ProviderType;

export interface InsightsFilterOptions extends UserPreferenceOptions {
  type: UserPreferenceType.INSIGHTS_FILTER;
  value: InsightsFilterValue;
}

export function getDefaults(): InsightsFilterOptions {
  return {
    ...getUserPreferenceDefaults(),
    type: UserPreferenceType.INSIGHTS_FILTER,
    value: {},
  };
}

export default class InsightsFilter extends UserPreference {
  declare value: InsightsFilterValue;

  constructor(options: Partial<InsightsFilterOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.value = opts.value;
  }
}
