export default function PatientNotFoundIcon({ width = 56, height = 56 }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 68 68' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M49.343 49.3429L66.0001 66' stroke='#3253EF' strokeWidth='4' strokeMiterlimit='10' />
      <path
        d='M29.7333 57.4667C45.05 57.4667 57.4667 45.05 57.4667 29.7333C57.4667 14.4166 45.05 2 29.7333 2C14.4166 2 2 14.4166 2 29.7333C2 45.05 14.4166 57.4667 29.7333 57.4667Z'
        stroke='#3253EF'
        strokeWidth='4'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M42.5334 42.5333V40.4373C42.5334 38.9227 41.7387 37.5296 40.4352 36.7584C38.3606 35.5307 34.7638 34 29.7334 34C24.6379 34 21.0645 35.5211 19.0112 36.7467C17.7184 37.5189 16.9333 38.9077 16.9333 40.4139V42.5333H42.5334Z'
        stroke='#3253EF'
        strokeWidth='4'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
      <path
        d='M29.7333 27.6C33.2679 27.6 36.1333 24.7346 36.1333 21.2C36.1333 17.6654 33.2679 14.8 29.7333 14.8C26.1986 14.8 23.3333 17.6654 23.3333 21.2C23.3333 24.7346 26.1986 27.6 29.7333 27.6Z'
        stroke='#3253EF'
        strokeWidth='4'
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
}
