import Review, {
  BaseReviewData,
  BaseReviewDataOptions,
  getDefaults as getReviewDefaults,
  ReviewOptions,
  ReviewType,
} from 'models/Review';

type RefusedBy = {
  id: string;
  name: string;
  type: 'patient' | 'rehab_facility';
};

type ServiceRefusalData = Partial<{ refusedBy: RefusedBy | null }>;

export interface ServiceRefusalOptions extends ReviewOptions {
  type: ReviewType.SERVICE_REFUSAL;
  data: Partial<ServiceRefusalData & BaseReviewDataOptions>;
}

export function getDefaults(): ServiceRefusalOptions {
  const defaults = getReviewDefaults();
  return {
    ...defaults,
    type: ReviewType.SERVICE_REFUSAL,
    data: {
      ...defaults.data,
      refusedBy: null,
    },
  };
}

export default class ServiceRefusal extends Review {
  declare type: ReviewType.SERVICE_REFUSAL;
  declare data: ServiceRefusalData & BaseReviewData;

  constructor(options: Partial<ServiceRefusalOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  static get type() {
    return ReviewType.SERVICE_REFUSAL;
  }
}
