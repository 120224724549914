import React from 'react';
import styled from 'styled-components';

import FileDropZone from 'components/shared/FileDropZone';
import { Label } from 'styles/typography';
import AttachFileIcon from 'svg/AttachFileIcon';

type FileInputComponentProps = {
  onFilesSelected?: (files: File[]) => void;
  filesCount?: number;
};

export default function FileInputComponent(props: FileInputComponentProps) {
  const { onFilesSelected = () => {}, filesCount = 0 } = props;

  return (
    <FileDropZone onFilesSelected={onFilesSelected} noClick noDrag={false} currentFileCount={filesCount}>
      {(dropzone) => (
        <Container {...dropzone.getRootProps()} $active={dropzone.isDragActive}>
          <input {...dropzone.getInputProps()} />
          <AttachFileIcon height={16} />
          <InputLabel>
            Drag and drop your file(s) here, or&nbsp;
            <FileBrowserLink onClick={dropzone.open} aria-label='launch file browser'>
              browse your files
            </FileBrowserLink>
          </InputLabel>
        </Container>
      )}
    </FileDropZone>
  );
}

const Container = styled.div<{ $active: boolean }>`
  background-color: ${({ $active }) => ($active ? 'var(--primary-blue-05)' : 'white')};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ $active }) => ($active ? 'var(--primary-blue)' : 'var(--black-25)')};
  position: relative;
  display: flex;
  min-height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:focus-within {
    border-color: var(--primary-blue);
    background-color: var(--primary-blue-05);
  }

  &:focus-visible {
    outline: none;
  }
`;

const FileBrowserLink = styled.a`
  color: var(--primary-blue);
  cursor: pointer;
  text-decoration: underline;
`;

const InputLabel = styled(Label)`
  margin-top: 8px;
`;
