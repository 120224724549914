import { parseDate } from 'lib/date';
import Attr, { AttrOptions } from 'models/Attr';

export enum ReviewType {
  SERVICE_REFUSAL = 'service_refusal',
  AUTHORIZATION = 'authorization',
  PROJECTED_DISCHARGE = 'projected_discharge',
  ALTCS_APPLICATION = 'altcs_application',
}

export enum ReviewStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export type BaseReviewDataOptions = {
  statusUpdatedAt: string | null;
};

export type BaseReviewData = {
  statusUpdatedAt: Date | null;
};

export interface ReviewOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  // Models inherting from the `Review` class should define the shape of `data`
  data: Partial<BaseReviewDataOptions>;
  locationEpisodeId: string;
  reviewedBy: string | null;
  status: string;
  type: ReviewType;
  attrs: Partial<AttrOptions>[];
}

export function getDefaults(): ReviewOptions {
  return {
    id: '',
    createdAt: null,
    createdBy: null,
    data: {
      statusUpdatedAt: null,
    },
    locationEpisodeId: '',
    reviewedBy: null,
    status: ReviewStatus.PENDING,
    type: ReviewType.ALTCS_APPLICATION,
    attrs: [],
  };
}
/**
 * @class Review
 * @classdesc Represents a review in the system
 * @property {string} id - The review's id
 * @property {string | Date} createdAt - The review's creation date
 * @property {string} createdBy - The review's creator
 * @property {Record<string, any>} data - The review's data
 * @property {string} locationEpisodeId - The review's location episode id
 * @property {string} reviewedBy - The review's reviewer
 * @property {string} status - The review's status
 * @property {string} type - The review's type
 * @param {Partial<ReviewOptions>} [options={}]
 * @example const review = new Review({ id: '123' });
 */
export default class Review {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  // Models inherting from this class should define the shape of `data`
  data: Record<string, any>;
  locationEpisodeId: string;
  reviewedBy: string | null;
  status: string;
  type: ReviewType;
  attrs: Attr[];

  constructor(options: Partial<ReviewOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.data = {
      ...opts.data,
      statusUpdatedAt: parseDate(opts.data.statusUpdatedAt) ?? null,
    };
    this.locationEpisodeId = opts.locationEpisodeId;
    this.reviewedBy = opts.reviewedBy;
    this.status = opts.status;
    this.type = opts.type;
    this.createdAt = opts.createdAt;
    this.attrs = opts.attrs?.map((attr) => new Attr(attr)) ?? [];
  }

  get pending() {
    return this.status === ReviewStatus.PENDING;
  }

  get accepted() {
    return this.status === ReviewStatus.ACCEPTED;
  }

  get attrValues() {
    return this.attrs.flatMap((attr) => attr?.attrValues || []) ?? [];
  }
}

export const parseProjectedDischarge = (projectedDischarge?: string | Date) => {
  if (!projectedDischarge) return null;
  if (projectedDischarge instanceof Date) return projectedDischarge;

  // Set time to end of day
  return new Date(`${projectedDischarge}T23:59:00.000`);
};
