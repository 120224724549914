import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { ReviewType } from 'models/Review';
import { createReview } from 'services/api/reviews';
import { useToastActions } from 'stores/toastStore';
import { BodySmall } from 'styles/typography';

export type CreateAltcsApplicationModalProps = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisodeId: string;
  onSuccess: () => void;
};

export default function CreateAltcsApplicationModal(props: CreateAltcsApplicationModalProps) {
  const { setShow, patientName, locationEpisodeId, onSuccess } = props;

  const { addToast } = useToastActions();

  const cancel = () => {
    setShow(false);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: createReview,
    onSuccess: () => {
      onSuccess();
      cancel();
    },
    onError: () => {
      addToast({ text: 'Something went wrong. Please try again.' });
    },
  });

  const submit = async () => {
    mutate({
      locationEpisodeId,
      type: ReviewType.ALTCS_APPLICATION,
    });
  };

  return (
    <StandardModal title='Confirm ALTCS Pending' onCancel={cancel}>
      <Content>
        <PatientName>Patient: {patientName}</PatientName>
        <BodySmall style={{ marginBottom: 24 }}>
          Are you sure you wish to update this patient to ALTCS Pending?
        </BodySmall>
        <BodySmall>
          This will discharge the current stay and transition the patient to a new ALTCS Pending custodial stay record
          in Olio.
        </BodySmall>
      </Content>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const Content = styled.div`
  margin-bottom: 60px;
`;
