import { useEnvironment } from 'context/environment';
import { IFRAME_CLASS } from 'lib/cache';

function IFrameContainer() {
  const { environment } = useEnvironment();
  const iframes = environment?.AUTH0_OLIO_DOMAINS;

  return (
    <>
      {iframes?.map((iframe, index) => {
        return (
          <iframe
            key={index}
            src={`${iframe}/external`}
            className={IFRAME_CLASS}
            style={{ width: 0, height: 0, border: 'none', position: 'absolute' }}></iframe>
        );
      })}
    </>
  );
}

export default IFrameContainer;
