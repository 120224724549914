import { useEffect } from 'react';

import { useEnvironment } from 'context/environment';

function External() {
  const { environment } = useEnvironment();

  useEffect(() => {
    const messageHandler = (message: MessageEvent) => {
      const allowed = environment?.AUTH0_OLIO_DOMAINS ?? [];

      if (!allowed?.includes(message.origin)) return;

      const key = message.data.key;

      if (!key) return;
      console.log('Got message from', message.origin);
      console.log({ key });
      localStorage.removeItem(key);
    };
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [environment]);

  return <></>;
}

export default External;
