import { create } from 'zustand';

type Actions = {
  setNavigationOpen: (open: boolean) => void;
};

type State = {
  navigationOpen: boolean;
  actions: Actions;
};

export const useMobileNavigationStore = create<State>((set) => ({
  navigationOpen: false,
  actions: {
    setNavigationOpen: (open: boolean) => set({ navigationOpen: open }),
  },
}));

export const useMobileNavigationActions = () => useMobileNavigationStore((state) => state.actions);
