export default function CircleXIcon({ width = 24, height = 24, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='circle-x-icon-black50' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C23.9807773,5.38056166 18.6194383,0.0192227178 12,0 Z M16.95,15.536 L15.536,16.95 L12,13.414 L8.464,16.95 L7.05,15.536 L10.586,12 L7.05,8.464 L8.464,7.05 L12,10.586 L15.536,7.05 L16.95,8.464 L13.414,12 L16.95,15.536 Z'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
}
