import AttrValue, { AttrValueOptions } from 'models/AttrValue';
import EpisodeGroup, { EpisodeGroupOptions } from 'models/EpisodeGroup';
import LocationEpisodeSummary, { LocationEpisodeSummaryOptions } from 'models/LocationEpisodeSummary';
import User, { UserOptions } from 'models/User';

import Group, { GroupOptions } from './Group';
import LocationEpisode, { LocationEpisodeOptions } from './LocationEpisode';
import Patient, { PatientOptions } from './Patient';
import RehabState, { RehabStateName, RehabStateOptions } from './RehabState';

// 'Special' Episode type for use within the portfolio requests
export type SupplementalEpisodeInformation = {
  latest: boolean;
  onTrack: null | string;
  locationEpisodeId: string;
  hasNewChanges: boolean;
  statusOutOfDate: boolean;
  lengthOfStay: number;
  planTypeClassification: {
    id: string;
    name: string;
  };
  hasActiveServiceRefusals: boolean;
  unacknowledgedEscalations: number;
  unacknowledgedPriorityNotes: number;
};

export interface EpisodeOptions {
  id: string;
  payor: string | null;
  medicare: boolean | null;
  admittedOn: string | Date;
  currentRehabState: Partial<RehabStateOptions>;
  hospital: Partial<GroupOptions>;
  patient: Partial<PatientOptions>;
  physicianTeam: Partial<AttrValueOptions>;
  physicianGroup: Partial<GroupOptions> | null;
  affiliates: Partial<GroupOptions>[];
  rehabInformation: {
    latestRehabFacility: Partial<GroupOptions>;
    latestRehabFacilityType: string;
  };
  rehabStates?: Partial<RehabStateOptions>[];
  updatedAt?: string;
  archived?: boolean;
  archivedBy?: Partial<UserOptions> | null;
  archivedByGroup?: Partial<GroupOptions> | null;
  latestLocationEpisodeId?: string;
  latestLocationEpisode?: Partial<LocationEpisodeOptions>;
  locationEpisodes: Partial<LocationEpisodeSummaryOptions>[];
  episodeId?: string;
  externalId?: string | null;
  latest?: boolean;
  trackLos?: boolean;
  hasNewChanges?: boolean;
  owner?: Partial<GroupOptions>;
  unacknowledgedEscalations?: number;
  unacknowledgedPriorityNotes?: number;
  statusOutOfDate?: boolean;
  episodeGroups?: Partial<EpisodeGroupOptions>[];
}

function getDefaults(): EpisodeOptions {
  return {
    id: '',
    payor: null,
    medicare: null,
    admittedOn: '',
    currentRehabState: {} as RehabStateOptions,
    hospital: {
      name: '',
    },
    latestLocationEpisodeId: '',
    latestLocationEpisode: {} as LocationEpisodeOptions,
    locationEpisodes: [],
    owner: {},
    patient: {},
    physicianTeam: {},
    physicianGroup: null,
    affiliates: [],
    rehabInformation: {
      latestRehabFacility: {} as GroupOptions,
      latestRehabFacilityType: '',
    },
    rehabStates: [
      {
        current: true,
        position: 0,
        state: RehabStateName.Queue,
      } as RehabStateOptions,
    ],
    updatedAt: '',
    archived: false,
    archivedBy: null,
    archivedByGroup: null,
    episodeGroups: [],
  };
}
/**
 * Episode model
 * @param options EpisodeOptions
 * @property id string
 * @property payor string | null
 * @property medicare boolean | null
 * @property admittedOn string | Date
 * @property currentRehabState RehabState
 * @property hospital Group
 * @property patient Patient
 * @property {AttrValue} physicianTeam
 * @property {Group} physicianGroup
 * @property {Group[]} affiliates
 * @property rehabInformation object
 * @property rehabStates RehabState[]
 * @property updatedAt string
 * @property archived boolean
 * @property {User | null} archivedBy
 * @property {GroupOptions | null} archivedByGroup
 * @property latestLocationEpisodeId string
 * @property locationEpisodes LocationEpisode[]
 * @property episodeId string
 * @property externalId string | null
 * @property latest boolean
 * @property trackLos boolean
 * @property hasNewChanges boolean
 * @property owner Group
 * @property unacknowledgedEscalations number
 * @property unacknowledgedPriorityNotes number
 * @property statusOutOfDate boolean
 * @returns Episode
 * @example const episode = new Episode({ id: '123' });
 */
export default class Episode {
  id: string;
  payor: string | null;
  medicare: boolean | null;
  admittedOn: string | Date;
  currentRehabState: RehabState;
  hospital: Group;
  patient: Patient;
  physicianTeam: AttrValue;
  physicianGroup: Group | null;
  affiliates: Group[];
  rehabInformation: {
    latestRehabFacility: Group;
    latestRehabFacilityType: string;
  };
  rehabStates?: RehabState[];
  updatedAt?: string;
  archived?: boolean;
  archivedBy: User | null;
  archivedByGroup: Group | null;
  latestLocationEpisodeId?: string;
  latestLocationEpisode?: LocationEpisode;
  locationEpisodes: LocationEpisodeSummary[];
  episodeId?: string;
  externalId?: string | null;
  latest?: boolean;
  trackLos?: boolean;
  hasNewChanges?: boolean;
  owner: Group;
  unacknowledgedEscalations?: number;
  unacknowledgedPriorityNotes?: number;
  statusOutOfDate?: boolean;
  episodeGroups?: EpisodeGroup[];

  constructor(options: Partial<EpisodeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.payor = opts.payor;
    this.admittedOn = opts.admittedOn;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.hospital = new Group(opts.hospital);
    this.patient = new Patient(opts.patient);
    this.physicianTeam = new AttrValue(opts.physicianTeam);

    this.physicianGroup = opts.physicianGroup ? new Group(opts.physicianGroup) : null;
    this.affiliates = opts.affiliates?.map((x) => new Group(x));
    this.rehabInformation = {
      latestRehabFacility: new Group(opts.rehabInformation.latestRehabFacility),
      latestRehabFacilityType: opts.rehabInformation.latestRehabFacilityType,
    };
    this.rehabStates = opts.rehabStates?.map((x) => new RehabState(x));
    this.updatedAt = opts.updatedAt;
    this.archived = opts.archived;
    this.archivedBy = opts.archivedBy ? new User(opts.archivedBy) : null;
    this.archivedByGroup = opts.archivedByGroup ? new Group(opts.archivedByGroup) : null;
    this.latestLocationEpisodeId = opts.latestLocationEpisodeId;
    this.latestLocationEpisode = new LocationEpisode(opts.latestLocationEpisode);
    this.locationEpisodes = opts.locationEpisodes.map((x) => new LocationEpisodeSummary(x));
    this.episodeId = opts.episodeId;
    this.externalId = opts.externalId;
    this.latest = opts.latest;
    this.trackLos = opts.trackLos;
    this.hasNewChanges = opts.hasNewChanges;
    this.owner = new Group(opts.owner);
    this.unacknowledgedEscalations = opts.unacknowledgedEscalations;
    this.unacknowledgedPriorityNotes = opts.unacknowledgedPriorityNotes;
    this.statusOutOfDate = opts.statusOutOfDate;
    this.episodeGroups = opts.episodeGroups?.map((x) => new EpisodeGroup(x));
  }

  get type() {
    return 'Episode';
  }

  get latestRehabFacility() {
    return this.rehabInformation.latestRehabFacility;
  }
}
