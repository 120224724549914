import Profile from 'models/Profile';
import { ProfileOptions } from 'models/Profile';
import http from 'services/http';

export async function updateProfile(params: Partial<Profile>) {
  return http
    .put<ProfileOptions>(`/me`, {
      ...params,
      include:
        'client.leaf_descendants,scopes,preferences,acting_client.group_types,acting_client.client_group_types.group_type,enabled_provider_types,selected_group_ids,allowed_group_ids',
    })
    .then((response) => new Profile(response.data));
}

export async function showProfile() {
  return http
    .get<ProfileOptions>(
      `/me/profile?include=client.leaf_descendants,scopes,preferences,acting_client.group_types,acting_client.client_group_types.group_type,enabled_provider_types,selected_group_ids,allowed_group_ids`
    )
    .then((response) => new Profile(response.data));
}
