export default function ReassignPencilIcon({ width = 12, height = 12, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <defs>
        <path
          d='M6.075,2.625 L9.375,5.925 L3.525,11.775 C3.375,11.925 3.225,12 3,12 L3,12 L0.75,12 C0.3,12 0,11.7 0,11.25 L0,11.25 L0,9 C0,8.775 0.075,8.625 0.225,8.475 L0.225,8.475 L6.075,2.625 Z M8.475,0.225 C8.775,-0.075 9.225,-0.075 9.525,0.225 L9.525,0.225 L11.775,2.475 C12.075,2.775 12.075,3.225 11.775,3.525 L11.775,3.525 L10.425,4.875 L7.125,1.575 Z'
          id='reassign-pencil-path-1'
        />
      </defs>
      <g id='demo-walkthrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Confirm-or-Reassign-Actions' transform='translate(-717.000000, -294.000000)'>
          <g id='Group-28' transform='translate(560.000000, 194.000000)'>
            <g id='Group-20' transform='translate(142.000000, 90.000000)'>
              <g id='Group-26' transform='translate(15.000000, 8.000000)'>
                <g id='Icon/Download-Copy' transform='translate(0.000000, 2.000000)'>
                  <mask id='mask-2' fill='white'>
                    <use xlinkHref='#reassign-pencil-path-1' />
                  </mask>
                  <use id='Mask' fill='#3253EF' fillRule='nonzero' xlinkHref='#reassign-pencil-path-1' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
