export default function ExportInsights({ width = 14, height = 14, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_2044_5318)'>
        <path d='M7 0.875V10.0625' stroke='#3253EF' strokeWidth='1.5' strokeMiterlimit='10' />
        <path
          d='M3.9375 7L7 10.0625L10.0625 7'
          stroke='#3253EF'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M0.875 9.625V11.8125C0.875 12.1606 1.01328 12.4944 1.25942 12.7406C1.50556 12.9867 1.8394 13.125 2.1875 13.125H11.8125C12.1606 13.125 12.4944 12.9867 12.7406 12.7406C12.9867 12.4944 13.125 12.1606 13.125 11.8125V9.625'
          stroke='#3253EF'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
      </g>
      <defs>
        <clipPath id='clip0_2044_5318'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
