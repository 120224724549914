import React from 'react';
import styled from 'styled-components';

import Card from 'components/insights/layout/Card';

type Props = {
  children: React.ReactNode;
  gridWidth: number;
  title: string;
};

function MetricsGridContainer({ children, gridWidth, title }: Props) {
  return (
    <Card>
      <Card.Title>{title}</Card.Title>
      <Grid data-cy='grid' gridWidth={gridWidth}>
        {children}
      </Grid>
    </Card>
  );
}

const getNumColumns = (gridWidth: number) => {
  const columnMinWidth = 324;

  // max number of columns that can safely fit inside the wrapper
  const maxColumns = Math.floor(gridWidth / columnMinWidth);

  // results in desired min-width of ~320px for each column (no matter how many)
  const adjustedMaxColumns = Math.floor(gridWidth / (columnMinWidth + maxColumns * 4));

  // grid should have at least 1 column and at most 3 columns
  return Math.max(1, Math.min(3, adjustedMaxColumns));
};

/**
 * Container surrounding the grid has a max width of 1280px with 24px padding on each side.
 * So the max width of the row would be 1280 - 24 - 24 = 1232px.
 * We want to have 24px gap between each column, so each column should be 394px.
 * (394px * 3) + (24px * 2) = 1232px
 */
const Grid = styled.div<{ gridWidth: number }>`
  display: grid;
  grid-template-columns: ${({ gridWidth }) => `repeat(${getNumColumns(gridWidth - 48)}, minmax(200px, 394px))`};
  grid-gap: 24px;
  justify-content: center;
`;

export default MetricsGridContainer;
