import styled from 'styled-components';

import { Body } from 'styles/typography';

import Button from './Button';
import ButtonGroup from './ButtonGroup';
import StandardModal from './StandardModal';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
};

function DiscardChangesModal({ onCancel, onConfirm }: Props) {
  return (
    <StandardModal title={'Discard Changes?'} onCancel={onCancel} closeOnBackdropClick>
      <Content>
        <StyledBody>Are you sure you wish to continue? All of your changes will be discarded.</StyledBody>
      </Content>
      <StyledButtonGroup>
        <Button variant='ghost' onClick={onCancel}>
          Go Back
        </Button>
        <Button onClick={onConfirm}>Discard Changes</Button>
      </StyledButtonGroup>
    </StandardModal>
  );
}

export default DiscardChangesModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const StyledBody = styled(Body)`
  display: block;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;
