import React, { useEffect, useState } from 'react';
import Icon from 'react-fontawesome';
import styled from 'styled-components';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useIsMobile from 'hooks/useIsMobile';
import ArrowUpDownIcon from 'svg/ArrowUpDownIcon';

import CircularProgress from './CircularProgress';

interface TableProps<T> {
  columns: ColumnDef<T, any>[];
  data: T[];
}

type DataTableProps<T> = TableProps<T> & {
  defaultSortBy?: SortingState;
  desktopOnlyColumns?: string[];
  hasMore?: boolean;
  loading?: boolean;
  onBodyEndReached?: () => void;
};

export default function DataTable<T>(props: DataTableProps<T>) {
  const { customRef, isIntersecting } = useIntersectionObserver();
  const {
    defaultSortBy = [],
    desktopOnlyColumns = [],
    hasMore = false,
    loading,
    onBodyEndReached,
    ...reactTableProps
  } = props;

  const [sorting, setSorting] = useState<SortingState>(defaultSortBy);

  const isMobile = useIsMobile();

  const table = useReactTable({
    ...reactTableProps,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      columnVisibility: desktopOnlyColumns.reduce((acc, key) => ({ ...acc, [key]: !isMobile }), {}),
      sorting,
    },
  });

  useEffect(() => {
    if (isIntersecting && hasMore && !loading) {
      onBodyEndReached?.();
    }
  }, [hasMore, isIntersecting, loading, onBodyEndReached]);

  const hasTableRows = !!table.getRowModel().rows.length;

  return loading && !hasTableRows ? (
    <StyledCircularProgress />
  ) : (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeaderFooterRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                isSorted={!!header.column.getIsSorted()}
                isSortable={header.column.getCanSort()}
                onClick={header.column.getToggleSortingHandler()}
                width={header.getSize()}>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && (
                  <ArrowUpDownIcon
                    direction={header.column.getIsSorted() || 'none'}
                    height={10}
                    width={10}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </TableHeaderCell>
            ))}
          </TableHeaderFooterRow>
        ))}
      </TableHeader>

      <TableBody>
        {!hasTableRows && (
          <TableRow>
            <TableCell colSpan={table.getVisibleFlatColumns().length} style={{ justifyContent: 'center' }}>
              No rows found
            </TableCell>
          </TableRow>
        )}
        {hasTableRows &&
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} {...cell.column.columnDef.meta} width={cell.column.getSize()}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        {/* Empty row used to trigger when the end is reached */}
        <HiddenTableRow ref={customRef}></HiddenTableRow>
        {hasTableRows && loading && (
          <TableRow>
            <TableCell colSpan={table.getVisibleFlatColumns().length}>
              <StyledCircularProgress />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {hasTableRows && (
        <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableHeaderFooterRow key={footerGroup.id}>
              {footerGroup.headers.map((footer) => (
                <TableFooterCell key={footer.id} {...footer.column.columnDef.meta} width={footer.column.getSize()}>
                  {flexRender(footer.column.columnDef.footer, footer.getContext())}
                </TableFooterCell>
              ))}
            </TableHeaderFooterRow>
          ))}
        </TableFooter>
      )}
    </Table>
  );
}

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin: auto;
`;

export const Table = styled.table`
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 4px;
  max-height: 440px;

  > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.black10};
  }
`;

export const TableHeader = styled.thead`
  display: flex;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};

  > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.black10};
  }
`;

export const TableRow = styled.tr`
  display: flex;
  flex-shrink: 0;
`;

const HiddenTableRow = styled(TableRow)`
  border: none;
  height: 1px; /* Needs height to trigger the intersection */
`;

export const TableHeaderFooterRow = styled(TableRow)`
  flex-basis: 100%;
  background-color: ${({ theme }) => theme.colors.black03};
`;

export const TableFooter = styled.tfoot`
  display: flex;
`;

export const TableSortIcon = styled(Icon)`
  padding-left: 8px;
  padding-top: 1px;
`;

export const TableCell = styled.td<{ width?: number; bold?: boolean }>`
  align-items: center;
  display: flex;
  flex: ${({ width }) => (width ? width : 150)} 0 auto;
  padding: 16px 24px;
  text-overflow: ellipsis;
  white-space: initial;
  width: ${({ width }) => (width ? width : 150)}px;
`;

export const TableFooterCell = styled(TableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const TableHeaderCell = styled(TableCell)<{ isSorted: boolean; isSortable: boolean }>`
  align-items: normal;
  color: ${(props) => (props.isSorted ? props.theme.colors.black : props.theme.colors.black50)};
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};
`;
