import { useProfile } from 'context/profile';
import useAsyncExport from 'hooks/useAsyncExport';
import { createAnalyticsExport } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

import { AnalyticsQueryParams } from '../helpers/useAnalyticsInfiniteQuery';

import Export from './Export';

type Props = {
  disabled?: boolean;
  filename?: string;
  show?: boolean;
  params: AnalyticsQueryParams;
};

function ApiExport({ disabled = false, filename, show = true, params }: Props) {
  const { profile } = useProfile();
  const { initiateExport, exporting } = useAsyncExport(createAnalyticsExport);
  const filters = useInsightsStore((state) => getAnalyticsFilters(state, profile));

  const handleClick = () => {
    initiateExport({ params: { ...params, filename }, filters });
  };

  return <Export disabled={disabled} show={show} loading={exporting} onClick={handleClick} />;
}

export default ApiExport;
