import { Notification, NotificationOptions } from 'models/Notifications';
import { BaseMutationParams, BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const notificationPreferencesQueryKeys = {
  index: (params: Record<string, any> = {}) => ['notificationPreferences', 'index', params] as const,
};

type IndexParams = {
  id: string;
} & BaseQueryParams;
export async function fetchNotificationPreferences({ id, ...params }: IndexParams) {
  return http
    .get<{ data: NotificationOptions[] }>(`/me/notification_preferences?${id}`, { params })
    .then((response) => response.data.data.map((notification) => new Notification(notification)));
}

export async function createNotificationPreferences(params: NotificationOptions & BaseMutationParams) {
  return http
    .post<NotificationOptions>(`/me/notification_preferences`, params)
    .then((response) => new Notification(response.data));
}

type UpdateParams = {
  urlExtension: string;
} & NotificationOptions &
  BaseMutationParams;
export async function updateNotificationPreferences({ urlExtension, ...params }: UpdateParams) {
  return http
    .put<NotificationOptions>(`/me/notification_preferences${urlExtension}`, params)
    .then((response) => new Notification(response.data));
}
