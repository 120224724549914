import React, { useRef } from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';

export type TabType = { label: string; value: string };

type Props = {
  tabs: TabType[];
  onTabClick: (tab: TabType) => void;
  selectedTab?: TabType;
};

export default function Tabs(props: Props) {
  const { tabs, onTabClick, selectedTab } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <TabsContainer ref={containerRef}>
      {tabs.map((tab: TabType) => {
        const active = selectedTab?.value === tab.value;

        return (
          <React.Fragment key={tab.label}>
            <Tab
              data-cy={`tab-filter-${tab.value}`}
              onClick={(event) => {
                containerRef.current?.scrollTo({
                  // Center the tab in the container (if possible)
                  left:
                    event.currentTarget.offsetLeft -
                    (containerRef.current.clientWidth - event.currentTarget.offsetWidth) / 2,
                  behavior: 'smooth',
                });
                onTabClick(tab);
              }}
              $active={active}>
              <TabText color={active ? colors.primaryBlue : colors.black} fontWeight={active ? 'bold' : 'inherit'}>
                {tab.label}
              </TabText>
            </Tab>
          </React.Fragment>
        );
      })}
    </TabsContainer>
  );
}

const Tab = styled.div<{ $active: boolean }>`
  cursor: pointer;
  line-height: 20px;
  padding: 10px 10px ${({ $active }: any) => ($active ? '7px' : '10px')} 10px;
  border-bottom: ${({ $active }: any) => ($active ? `3px solid ${colors.primaryBlue}` : 'none')};
  white-space: nowrap;
`;

const TabsContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  gap: 16px;
  overflow-x: auto;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 20px;
  }
`;

const TabText = styled(BodySmall)`
  font-size: 12px;

  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 14px;
  }
`;
