import React from 'react';
import styled from 'styled-components';

import { H2 } from 'styles/typography';

function FormTitle({ children }) {
  return (
    <TitleContainer>
      <StyledH2>{children}</StyledH2>
    </TitleContainer>
  );
}

export default FormTitle;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
`;

const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
