import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

import DocumentLink from 'components/shared/DocumentLink';
import DocumentTypeMenu from 'components/shared/DocumentTypeMenu';
import { dateAtTime } from 'lib/date';
import Attachment from 'models/Attachment';
import { updateAttachment } from 'services/api/attachments';
import colors from 'styles/theme/colors';
import { BodySmallBold } from 'styles/typography';

const columnHelper = createColumnHelper<Attachment>();

export default function columns() {
  return [
    columnHelper.accessor((row) => row.filename, {
      header: 'File Name',
      id: 'filename',
      cell: (info: any) => (
        <DocumentLink entry={info.row.original}>
          <BodySmallBold className='first-column' color={colors.primaryBlue}>
            {info.getValue() || `Untitled ${info.row.original.contentType} ${info.row.index}`}
          </BodySmallBold>
        </DocumentLink>
      ),
    }),
    columnHelper.accessor((row) => row.createdByUser?.fullName, {
      id: 'createdByUser',
      header: 'Uploaded By',
    }),
    columnHelper.accessor((row) => row.createdAt, {
      header: 'Uploaded On',
      id: 'createdAt',
      cell: (info) => (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{dateAtTime(info.getValue())}</div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.docType, {
      id: 'docType',
      header: 'Document Type',
      cell: function Cell({ row }: { row: any }) {
        const { mutate } = useMutation({
          mutationFn: updateAttachment,
        });

        const [activeDocType, setDocType] = useState(row.original?.docType);

        useEffect(() => {
          setDocType(row.original?.docType);
        }, [row.original?.docType]);

        const changeDocType = (docType: string | null) => {
          mutate(
            { id: row.original.id, docType },
            {
              onSuccess: () => {
                row.original.docType = docType;
                setDocType(docType);
              },
            }
          );
        };

        return (
          <DocumentTypeContainer className='last-column'>
            <DocumentTypeMenu
              activeDocType={activeDocType}
              setActiveDocType={changeDocType}
              disabled={false}
              reportAbsentDoctype={true}
            />
          </DocumentTypeContainer>
        );
      },
    }),
  ];
}

const DocumentTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
`;
