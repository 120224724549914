import styled from 'styled-components';

import Button from 'components/shared/Button';

type FilterSlideoutButtonsProps = {
  handleApply: () => void;
  handleCancel: () => void;
  handleExit: () => void;
};

const FilterSlideoutButtons = ({ handleApply, handleCancel, handleExit }: FilterSlideoutButtonsProps) => {
  const handleApplyAndExit = () => {
    handleApply();
    handleExit();
  };

  const handleCancelAndExit = () => {
    handleCancel();
    handleExit();
  };

  return (
    <ButtonGroup>
      <CancelButton data-cy='filtersCancel' variant='ghost' onClick={handleCancelAndExit}>
        Cancel
      </CancelButton>
      <Button data-cy='filtersApply' color='primary' onClick={handleApplyAndExit}>
        Apply
      </Button>
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  display: flex;
`;

const CancelButton = styled(Button)`
  &:focus {
    border-color: transparent;
  }
`;

export default FilterSlideoutButtons;
