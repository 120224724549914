import { StyledTableCell, StyledTableRow } from 'components/shared/table/Table';

function EmptyRow() {
  return (
    <StyledTableRow>
      <StyledTableCell className='span-all-columns'>No rows found</StyledTableCell>
    </StyledTableRow>
  );
}

export default EmptyRow;
