import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import { simpleDate } from 'lib/date';
import { ClientType } from 'models/Client';
import ImportedPatient from 'models/ImportedPatient';
import { devices } from 'styles/theme/devices';
import { BodySmallBold } from 'styles/typography';

const columnHelper = createColumnHelper<ImportedPatient>();

type Props = {
  locationTitle: string;
  userClientType?: string;
  showConnect: boolean;
  onConnectClick: (id: string) => void;
};

export default function connectPatientsTableColumns(props: Props) {
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'patientName',
      header: 'Name',
      cell: (info) => <BodySmallBold data-dd-action-name='Patient name'>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.dateOfBirth, {
      id: 'dateOfBirth',
      header: 'Date of Birth',
      cell: (info) => <div data-dd-action-name='Date of birth'>{simpleDate(info.getValue())}</div>,
    }),
    columnHelper.accessor((row) => row.owner?.name, {
      id: 'ownerName',
      header: props.locationTitle,
    }),
  ];

  if (props.userClientType === ClientType.HEALTH_SYSTEM) {
    columns.push(
      columnHelper.accessor((row) => row.admittedOn, {
        id: 'hospitalAdmissionDate',
        header: 'Hospital Admission',
        cell: (info) => <div>{simpleDate(info.getValue()) || '—'}</div>,
      })
    );
  }

  if (props.showConnect) {
    columns.push(
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: '',
        cell: (info) => (
          <LinkContainer>
            <Link onClick={() => props.onConnectClick(info.getValue())}>Connect</Link>
          </LinkContainer>
        ),
        enableSorting: false,
      })
    );
  }

  return columns;
}

const Link = styled(BodySmallBold)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${devices.desktop} {
    justify-content: center;
  }
`;
