import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface NoteOptions extends ActivityOptions {
  type: ActivityType.NOTE;
  data: Partial<BaseActivityDataOptions>;
}

export function getDefaults(): NoteOptions {
  return {
    ...getActivityDefaults(),
    type: ActivityType.NOTE,
  };
}

export default class Note extends Activity {
  declare type: ActivityType.NOTE;
  declare data: BaseActivityData;

  constructor(options: Partial<NoteOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }
}
