import Acknowledgement, { AcknowledgementOptions } from 'models/activities/Acknowledgement';
import AltcsApplicationStatusUpdate, {
  AltcsApplicationStatusUpdateOptions,
} from 'models/activities/AltcsApplicationStatusUpdate';
import AuthorizationUpdates, { AuthorizationUpdatesOptions } from 'models/activities/AuthorizationUpdates';
import DischargeQuestions, { DischargeQuestionsOptions } from 'models/activities/DischargeQuestions';
import Note, { NoteOptions } from 'models/activities/Note';
import PatientFieldChanges, { PatientFieldChangesOptions } from 'models/activities/PatientFieldChanges';
import PatientMatchCollaboration, {
  PatientMatchCollaborationOptions,
} from 'models/activities/PatientMatchCollaboration';
import ProgressUpdate, { ProgressUpdateOptions } from 'models/activities/ProgressUpdate';
import ProjectedDischargeUpdates, {
  ProjectedDischargeUpdatesOptions,
} from 'models/activities/ProjectedDischargeUpdates';
import RehabStateChange, { RehabStateChangeOptions } from 'models/activities/RehabStateChange';
import ServiceRefusal, { ServiceRefusalOptions } from 'models/activities/ServiceRefusal';
import Activity, { ActivityOptions, ActivityType } from 'models/Activity';

export default class ActivityModelFactory {
  private static defaultClass = Activity;

  private static mapping = {
    [ActivityType.ACKNOWLEDGEMENT]: Acknowledgement,
    [ActivityType.ALTCS_APPLICATION_STATUS_UPDATE]: AltcsApplicationStatusUpdate,
    [ActivityType.AUTHORIZATION_UPDATES]: AuthorizationUpdates,
    [ActivityType.DISCHARGE_QUESTIONS]: DischargeQuestions,
    [ActivityType.NOTE]: Note,
    [ActivityType.PATIENT_FIELD_CHANGES]: PatientFieldChanges,
    [ActivityType.PATIENT_MATCH_COLLABORATION]: PatientMatchCollaboration,
    [ActivityType.PROGRESS_UPDATE]: ProgressUpdate,
    [ActivityType.PROJECTED_DISCHARGE_UPDATES]: ProjectedDischargeUpdates,
    [ActivityType.REHAB_STATE_CHANGE]: RehabStateChange,
    [ActivityType.SERVICE_REFUSAL]: ServiceRefusal,
  };

  /**
   * Returns an instance of the appropriate class based on the options provided.
   * @see {@link https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads Function Overloads}
   */
  static get(options: Partial<AcknowledgementOptions>): Acknowledgement;
  static get(options: Partial<AltcsApplicationStatusUpdateOptions>): AltcsApplicationStatusUpdate;
  static get(options: Partial<AuthorizationUpdatesOptions>): AuthorizationUpdates;
  static get(options: Partial<DischargeQuestionsOptions>): DischargeQuestions;
  static get(options: Partial<NoteOptions>): Note;
  static get(options: Partial<PatientFieldChangesOptions>): PatientFieldChanges;
  static get(options: Partial<PatientMatchCollaborationOptions>): PatientMatchCollaboration;
  static get(options: Partial<ProgressUpdateOptions>): ProgressUpdate;
  static get(options: Partial<ProjectedDischargeUpdatesOptions>): ProjectedDischargeUpdates;
  static get(options: Partial<RehabStateChangeOptions>): RehabStateChange;
  static get(options: Partial<ServiceRefusalOptions>): ServiceRefusal;
  static get(options: Partial<ActivityOptions>): Activity;
  static get(options): Activity {
    const mappedClass = options.type ? this.mapping[options.type] : this.defaultClass;
    const klass = mappedClass ?? this.defaultClass;
    return new klass(options);
  }
}
