import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';
import _ from 'lodash';

import { useInfiniteQuery } from '@tanstack/react-query';

import { ClientOptions, default as Client } from 'models/Client';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const clientsQueryKeys = {
  index: ['clients'] as const,
};

type IndexParams = BaseIndexQueryParams & {
  leaf?: boolean;
};

export async function indexClients(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<ClientOptions>>(`clients`, { params, signal }).then((res) => {
    return {
      ...res.data,
      data: res.data.data.map((x) => new Client(x)),
    };
  });
}

type ForResourceParams = {
  id: string;
  type: string;
};
export async function forResourceClients(requestParams: ForResourceParams, signal: GenericAbortSignal) {
  const params = { resourceId: requestParams.id, resourceType: requestParams.type };

  return http.get<Paginated<ClientOptions>>(`clients/for_resource`, { params, signal }).then((res) => {
    return {
      ...res.data,
      data: res.data.data.map((x) => new Client(x)),
    };
  });
}

export const useInfiniteClientOptions = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: [...clientsQueryKeys.index, { ...params, search }],
    queryFn: ({ signal, pageParam }) => indexClients({ ...params, page: pageParam, search }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
