import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import YesNoQuestion from 'components/modals/QuestionList/YesNoQuestion';
import { useForm } from 'context/form';
import { QuestionType } from 'models/QuestionTemplate';

type Props = {
  questions: QuestionType[];
};

export default function DischargeQuestions({ questions }: Props) {
  const { values, setValue } = useForm<DischargeFormValues, DischargeFormMeta>();

  const orderedQuestions = questions.sort((a, b) => a.order - b.order);

  const handleChangeAnswer = (value: string, id: string) => {
    const newValues = { ...values.questions, [id]: value };

    setValue('questions', newValues);
  };

  return (
    <div>
      {orderedQuestions.map((question, index) => {
        return (
          <YesNoQuestion
            index={index + 1}
            key={index}
            text={question.text}
            value={values.questions?.[question.id]}
            setValue={(change) => handleChangeAnswer(change, question.id)}
          />
        );
      })}
    </div>
  );
}
