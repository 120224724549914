export const CASE_MANAGER = 'caseManager';
export const DATES = 'dates';
export const END_DATE = 'endDate';
export const EPISODE_CLASSIFICATION = 'episodeClassification';
export const HOSPITAL = 'hospital';
export const LATEST_REHAB_STATE = 'latestRehabState';
export const NONE = 'None';
export const NONE_OPTION = { id: 'null', name: NONE };
export const PAYER = 'payer';
export const PLAN_TYPE_CLASSIFICATION = 'planTypeClassification';
export const PHYSICIAN_GROUP = 'physicianGroup';
export const PHYSICIAN_TEAM = 'physicianTeam';
export const START_DATE = 'startDate';
export const UTILIZATION_MANAGER = 'utilizationManager';

export enum PatientState {
  CURRENT = 'current',
  CONTINUED = 'continued',
  LATEST = 'latest',
}

export const filterKeysToWords: Record<string, string> = {
  [PLAN_TYPE_CLASSIFICATION]: 'Plan Type',
  [EPISODE_CLASSIFICATION]: 'Episode Type',
  [CASE_MANAGER]: 'Case Manager',
  [UTILIZATION_MANAGER]: 'Utilization Manager',
};
