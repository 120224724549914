import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import DischargeQuestions from 'components/dischargeForm/DischargeQuestions';
import { buildAnsweredQuestionsFromFormValues } from 'components/dischargeForm/helpers';
import Button from 'components/shared/Button';
import StandardModal, { Actions } from 'components/shared/StandardModal';
import { Form, FormProvider } from 'context/form';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types';
import Activity from 'models/Activity';
import { activityQueryKeys, updateActivity } from 'services/api/activity';
import { useToastActions } from 'stores/toastStore';

import { AnswerType } from './QuestionList/QuestionList';

export type EditDischargeQuestionsModalType = {
  setShow: (b: boolean) => void;
  activity: Activity;
};

type Values = {
  questions: Record<string, string>;
};

type Meta = {
  isSubmitting: boolean;
};

export default function EditDischargeQuestionsModal({ setShow, activity }: EditDischargeQuestionsModalType) {
  const queryClient = useQueryClient();

  const { addToast } = useToastActions();

  const { mutate: mutateActivity } = useMutation({
    mutationFn: updateActivity,
  });

  const questions: AnswerType[] = activity.data.questions ?? [];

  const cancel = () => {
    setShow(false);
  };

  const handleSubmit = async (values: FormValues<Values>, meta: FormMeta<Meta>, helpers: FormHelpers<Values, Meta>) => {
    helpers.setMeta('isSubmitting', true);

    mutateActivity(
      {
        id: activity.id,
        questions: buildAnsweredQuestionsFromFormValues(questions, values.questions ?? {}),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  const initialValues = activity.data?.questions?.reduce(
    (acc, { id, answer }) => ({ ...acc, [id]: answer.text }),
    {} as Record<string, string>
  );

  return (
    <StandardModal title='Edit Discharge Details' onCancel={cancel}>
      <FormProvider onSubmit={handleSubmit} initialValues={{ questions: initialValues }}>
        {({ valid, dirty, meta, values }) => {
          const allQuestionsAnswered = questions?.every((x) => !!values.questions?.[x.id]);

          return (
            <Form>
              <QuestionContainer>
                <DischargeQuestions questions={activity.data.questions} />
              </QuestionContainer>
              <Actions>
                <Button variant='ghost' onClick={cancel}>
                  Cancel
                </Button>
                <Button type='submit' disabled={!valid || !dirty || !allQuestionsAnswered} loading={meta.isSubmitting}>
                  Confirm
                </Button>
              </Actions>
            </Form>
          );
        }}
      </FormProvider>
    </StandardModal>
  );
}

const QuestionContainer = styled.div`
  margin-bottom: 12px;
`;
