import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';
import CircleXIcon from 'svg/CircleXIcon';
import SearchIcon from 'svg/SearchIcon';

type SearchType = {
  onChange: (e: string) => void;
  placeholder: string;
  value: string;
};
function Search(props: SearchType) {
  const { onChange, placeholder, value } = props;

  return (
    <SearchContainer>
      <SearchWrapper>
        <SearchIcon width={13} height={13} color={colors.black25} />
      </SearchWrapper>
      <Input placeholder={placeholder} value={value} onChange={(e) => onChange(e.currentTarget.value)} />
      {value && (
        <ClearSearchContainer onClick={() => onChange('')}>
          <CircleXIcon width={16} height={16} color={colors.black50} />
        </ClearSearchContainer>
      )}
    </SearchContainer>
  );
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 9999px;
  padding: 8px 10px;
  background-color: ${colors.gray};
  width: 100%;
  height: 38px;
`;

const Input = styled.input`
  min-width: 0;
  background-color: transparent;
  flex: 1;
  outline: none !important;
  border: none;
  color: var(--black);
  font-family: Lato;
  font-size: 16px;
  &::placeholder {
    color: var(--black-25);
  }
`;

const ClearSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  margin: 0;
  margin-left: 4px;
  height: 21px;
`;

export default Search;
