import React, { useState } from 'react';
import styled from 'styled-components';

import FormSection from 'components/shared/form/FormSection';
import SectionHeader from 'components/shared/form/SectionHeader';
import Patient from 'models/Patient';
import { Label, LabelBold } from 'styles/typography';

import { CollapsibleContent } from '../Collapsible';
import { ExpandCollapse } from '../Containers';

type EpisodeInfoSectionProps = {
  patient: Patient;
};

export default function EpisodeInfoSection({ patient }: EpisodeInfoSectionProps) {
  const [expanded, setExpanded] = useState(false);

  if (!patient?.attrValues?.length) return null;

  return (
    <FormSection data-cy='additionalInfoSection'>
      <SectionHeader>
        Additional Information
        <StyledExpandCollapse onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Collapse' : 'Expand'}
        </StyledExpandCollapse>
      </SectionHeader>
      <CollapsibleContent collapsed={!expanded}>
        {patient.attrValues.map((attrValue) => (
          <DataRow key={attrValue.id}>
            <LabelBold className='info-key'>{attrValue.attr?.displayName}:</LabelBold>
            <Label>{attrValue.name}</Label>
          </DataRow>
        ))}
      </CollapsibleContent>
    </FormSection>
  );
}

const DataRow = styled.div`
  display: flex;
  height: 40px;
  border-bottom: 1px solid var(--black-15);
  align-items: center;

  .info-key {
    color: var(--black-50);
    margin-right: 4px;
  }

  &:last-child {
    margin-bottom: 24px;
  }
`;

const StyledExpandCollapse = styled(ExpandCollapse)`
  margin-left: 16px;
  font-weight: normal;
`;
