import { createRoot } from 'react-dom/client';

class AsyncModal {
  modalContainer: HTMLDivElement;
  root: any;
  renderFunc: any;
  options: any;

  constructor(renderFunc, options = {}) {
    this.modalContainer = this.createModalContainer();
    this.renderFunc = renderFunc;
    this.options = options;
    this.root = createRoot(this.modalContainer);
  }

  createModalContainer() {
    const modalContainer = document.createElement('div');

    document.body.appendChild(modalContainer);
    return modalContainer;
  }

  displayModal({ onConfirm, onCancel }) {
    this.root.render(
      this.renderFunc({
        onConfirm,
        onCancel,
        show: true,
        ...this.options,
      })
    );
  }

  destroyModal() {
    this.root.unmount(this.modalContainer);
    document.body.removeChild(this.modalContainer);
  }

  render() {
    const modalPromise = new Promise((resolve) => {
      const onConfirm = () => resolve(true);
      const onCancel = () => resolve(false);

      this.displayModal({ onConfirm, onCancel });
    });

    modalPromise.finally(() => {
      this.destroyModal();
    });

    return modalPromise;
  }
}

export default AsyncModal;
