import { useCallback, useRef, useState } from 'react';
import { MentionItem } from 'react-mentions';

import Upload from 'hooks/useDirectUpload/Upload';
import { EscalationTypes } from 'models/Escalation';

export type ActivityInputValues = {
  text: string;
  plaintext: string;
  mentions: MentionItem[];
  attachments: Upload[];
  escalationType?: EscalationTypes;
  escalated?: boolean;
};

export const defaultActivityInputValues: ActivityInputValues = {
  text: '',
  plaintext: '',
  mentions: [],
  attachments: [],
  escalationType: EscalationTypes.Escalation,
  escalated: false,
};

export default function useActivityInput(initialValues: Partial<ActivityInputValues> = {}) {
  const defaultValues = useRef({ ...defaultActivityInputValues, ...initialValues });
  const [note, setNote] = useState(defaultValues.current);

  const getSerializedNoteValues = useCallback(() => {
    return {
      ...note,
      attachments: note.attachments?.map((upload) => upload.serialize()) ?? [],
    };
  }, [note]);

  const resetNote = useCallback(() => {
    setNote({ ...defaultValues.current });
  }, []);

  return { note, setNote, resetNote, getSerializedNoteValues };
}
