import React, { useEffect } from 'react';

import Upload from 'hooks/useDirectUpload/Upload';
import useUpload from 'hooks/useDirectUpload/useDirectUpload';

import FileInputAndAttachments from './FileInputAndAttachments';

type FileInputValues = { attachments: Upload[]; [key: string]: any };

type FileInputProps = {
  values: FileInputValues;
  setValues: React.Dispatch<React.SetStateAction<FileInputValues>>;
  onUploading?: (uploading: boolean) => void;
};

export default function FileInput(props: FileInputProps) {
  const { values, setValues, onUploading } = props;
  const { uploadFiles, uploads, uploading, removeUpload, setDocType } = useUpload(values.attachments);

  useEffect(() => {
    setValues({ ...values, attachments: uploads });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);

  useEffect(() => {
    onUploading?.(uploading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  return (
    <FileInputAndAttachments
      attachments={values.attachments}
      onDeleteAttachment={removeUpload}
      onFilesSelected={uploadFiles}
      onSetDocType={setDocType}
    />
  );
}
