import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import usePortfolioTabs from 'components/portfolio/usePortfolioTabs';
import Button from 'components/shared/Button';
import Foldout from 'components/shared/Foldout';
import { useAuth } from 'context/auth';
import { useProfile } from 'context/profile';
import { capitalize } from 'lib/util';
import { useMobileNavigationActions, useMobileNavigationStore } from 'stores/mobileNavigationStore';
import { useSelectViewActions } from 'stores/selectViewStore';
import { colors } from 'styles/theme/colors';
import ChevronRight from 'svg/ChevronRight';
import GearIcon from 'svg/GearIcon';
import InsightsIcon from 'svg/InsightsIcon';
import LogoutIcon from 'svg/LogoutIcon';
import OlioOLogo from 'svg/OlioOLogo';
import PatientListsIcon from 'svg/PatientListsIcon';
import PatientPortfolioIcon from 'svg/PatientPortfolioIcon';
import PlusIcon from 'svg/PlusIcon';

export default function MobileNavigation() {
  const { logout } = useAuth();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const navigationOpen = useMobileNavigationStore((state) => state.navigationOpen);
  const { setNavigationOpen } = useMobileNavigationActions();
  const { setSelectViewOpen } = useSelectViewActions();
  const tabs = usePortfolioTabs();

  const showConnectPatients = useMemo(() => {
    return profile.isAcute && (profile.isAdmin || (!profile.isPhysicianTeamUser && !profile.isAffiliateUser));
  }, [profile]);

  const showAddPatientButton = profile.permissions.patientCreate;

  return (
    <Foldout
      data-cy='leftFoldout'
      open={navigationOpen}
      setOpen={setNavigationOpen}
      position='left'
      closeOnBackdropClick>
      <FoldoutHeaderContainer>
        <div>
          <OlioOLogo color={colors.primaryBlue} width={36} height={36} />
        </div>
        <FoldoutHeaderText>
          <UsernameText>
            {profile.fullName}
            {profile.actingClient && <ActingClientName>({profile.actingClient?.name})</ActingClientName>}
          </UsernameText>
          <SubtitleText>{profile.email}</SubtitleText>
        </FoldoutHeaderText>
      </FoldoutHeaderContainer>
      <NavigationWrapper>
        <FoldoutMenuHeader>
          <FoldoutIconSlot>
            <PatientPortfolioIcon width={16} height={16} color={colors.black} />
          </FoldoutIconSlot>
          Patient Portfolio
        </FoldoutMenuHeader>
        {tabs.map((tab) => {
          return (
            <FoldoutMenuLink
              key={tab.value}
              onClick={() => {
                navigate(`/patients?tab=${tab.value}`);
                setNavigationOpen(false);
              }}>
              {capitalize(tab.displayName)} Patients ({tab.count})
              <Spacer />
              <FoldoutChevronContainer>
                <ChevronRight width={12} height={12} color={colors.black50} />
              </FoldoutChevronContainer>
            </FoldoutMenuLink>
          );
        })}

        <FoldoutMenuHeader>
          <FoldoutIconSlot>
            <PatientListsIcon width={12} height={12} color={colors.black} />
          </FoldoutIconSlot>
          Patient Lists
        </FoldoutMenuHeader>
        <FoldoutMenuLink
          onClick={() => {
            navigate('/all-patients');
            setNavigationOpen(false);
          }}>
          All Patients
          <Spacer />
          <FoldoutChevronContainer>
            <ChevronRight width={12} height={12} color={colors.black50} />
          </FoldoutChevronContainer>
        </FoldoutMenuLink>
        {showConnectPatients && (
          <FoldoutMenuLink
            onClick={() => {
              navigate('/connect-patients');
              setNavigationOpen(false);
            }}>
            Connect Patients
            <Spacer />
            <FoldoutChevronContainer>
              <ChevronRight width={12} height={12} color={colors.black50} />
            </FoldoutChevronContainer>
          </FoldoutMenuLink>
        )}
        <FoldoutMenuHeader>
          <FoldoutIconSlot>
            <InsightsIcon width={16} height={16} color={colors.black} />
          </FoldoutIconSlot>
          Dashboards
        </FoldoutMenuHeader>
        <FoldoutMenuLink
          onClick={() => {
            navigate('/insights');
            setNavigationOpen(false);
          }}>
          Olio Insights
          <Spacer />
          <FoldoutChevronContainer>
            <ChevronRight width={12} height={12} color={colors.black50} />
          </FoldoutChevronContainer>
        </FoldoutMenuLink>

        <FoldoutMenuHeader>
          <FoldoutIconSlot>
            <GearIcon width={16} height={16} color={colors.black} />
          </FoldoutIconSlot>
          Settings
        </FoldoutMenuHeader>
        <FoldoutMenuLink
          onClick={() => {
            navigate('/preferences');
            setNavigationOpen(false);
          }}>
          Notification Preferences
          <Spacer />
          <FoldoutChevronContainer>
            <ChevronRight width={12} height={12} color={colors.black50} />
          </FoldoutChevronContainer>
        </FoldoutMenuLink>
        {profile.canChangeClientScope && (
          <FoldoutMenuLink
            onClick={() => {
              setNavigationOpen(false);
              setSelectViewOpen(true);
            }}>
            Select View
            <Spacer />
            <FoldoutChevronContainer>
              <ChevronRight width={12} height={12} color={colors.black50} />
            </FoldoutChevronContainer>
          </FoldoutMenuLink>
        )}
      </NavigationWrapper>
      {showAddPatientButton && (
        <AddButtonWrapper>
          <AddButton
            variant='outline-primary'
            onClick={() => {
              navigate('/patients/new');
              setNavigationOpen(false);
            }}>
            <PlusIcon color={colors.primaryBlue} width={14} height={14} />
            Add Patient
          </AddButton>
        </AddButtonWrapper>
      )}
      <LogoutButton variant='ghost' onClick={() => logout()}>
        <LogoutIcon width={20} height={20} color={colors.primaryBlue} />
        Logout
      </LogoutButton>
    </Foldout>
  );
}

const AddButton = styled(Button)`
  font-size: 14px;
  border-width: 1px;
  height: 40px;
  width: 100%;
`;

const AddButtonWrapper = styled.div`
  margin: 40px 0 8px;
  padding: 0 24px;
`;

const FoldoutMenuRow = styled.div`
  display: flex;
  cursor: pointer;
  padding: 16px 0;
  border-bottom: 1px solid var(--black-10);
  align-items: center;
  background-color: transparent;
  transition: background-color 0.25s;
  font-size: 14px;

  &:hover {
    background-color: var(--black-10);
  }
  &:active {
    background-color: var(--black-25);
  }
`;

const FoldoutMenuLink = styled(FoldoutMenuRow)`
  padding-left: 50px;
`;

const FoldoutMenuHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  padding: 16px 0 16px 24px;
  border-bottom: 1px solid var(--black-10);
`;

const FoldoutIconSlot = styled.div`
  display: flex;
  padding-right: 10px;
`;

const FoldoutChevronContainer = styled.div`
  display: flex;
  padding-right: 24px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const FoldoutHeaderContainer = styled.div`
  display: flex;
  padding: 40px 24px;
  gap: 16px;
  align-items: center;
`;

const FoldoutHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LogoutButton = styled(Button)`
  color: ${() => colors.primaryBlue};
  margin: 32px auto;
`;

const UsernameText = styled.div`
  color: var(--primary-blue);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleText = styled.span`
  color: var(--black-50);
`;

const ActingClientName = styled.span`
  font-style: italic;
  margin-left: 8px;
`;

const NavigationWrapper = styled.div`
  ${FoldoutMenuHeader}:not(:first-child) {
    margin-top: 40px;
  }
`;
