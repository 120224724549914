import React from 'react';
import styled from 'styled-components';

import { BodySmall } from 'styles/typography';

const UserTag = styled.span`
  background-color: var(--primary-blue-10);
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  cursor: default;
  font-weight: var(--font-weight-bold);
  padding: 2px;
  color: var(--primary-blue);
`;
const StyledBodySmall = styled(BodySmall)<{ $removed: boolean }>`
  text-decoration: ${({ $removed }) => ($removed ? 'line-through' : 'none')};
`;

const TAG_REGEX = new RegExp(/@\{\{.+?\}\}/);
const NEWLINE_REGEX = new RegExp(/\n/);
const COMBINED_REGEX = new RegExp(`(${TAG_REGEX.source}|${NEWLINE_REGEX.source})`);
const TAG_COMPONENTS_REGEX = /\{\{(.+?) - (.+)\|\|(.+)\}\}/;

export function TaggedText(props: { text: string; $removed: boolean }) {
  const textFragments = props.text.split(COMBINED_REGEX).filter((t) => t);

  return (
    <StyledBodySmall $removed={props.$removed}>
      {textFragments.map((fragment, i) => {
        if (fragment.match(TAG_REGEX)) {
          const name = fragment.match(TAG_COMPONENTS_REGEX)![1];
          return <UserTag key={i}>@{name}</UserTag>;
        } else if (fragment.match(NEWLINE_REGEX)) {
          return <br key={i} />;
        }
        return fragment;
      })}
    </StyledBodySmall>
  );
}
