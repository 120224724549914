import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AdditionalInfoSection from 'components/intake/AdditionalInfoSection';
import EpisodeInfoSection from 'components/intake/EpisodeInfoSection';
import PatientInfoSection from 'components/intake/PatientInfoSection';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import FormHeader from 'components/shared/form/FormHeader';
import { useForm } from 'context/form';
import { useProfile } from 'context/profile';
import Patient from 'models/Patient';

export type PatientFormProps = {
  patient: Patient;
  onNextStep?: () => void;
};

export default function PatientForm({ patient, onNextStep }: PatientFormProps) {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const { profile } = useProfile();

  const { meta, valid } = useForm<FormValues, FormMeta>();
  const connecting = meta.mode === PatientFormMode.Connecting;

  const title = useMemo(() => {
    if (connecting) return 'Connect';
    return meta.mode == PatientFormMode.Edit ? 'Edit' : 'Add';
  }, [connecting, meta.mode]);

  const subtitle = profile?.isAcute && patient.externalId ? `ID: ${patient.externalId}` : null;

  const onCancel = () => {
    switch (meta.mode) {
      case PatientFormMode.Edit:
        navigate(`/patients/${id}`);
        break;
      case PatientFormMode.Connecting:
        navigate('/connect-patients');
        break;
      default:
        navigate('/patients');
        break;
    }
  };

  return (
    <>
      <FormHeader title={`${title} Patient`} subtitle={subtitle} />
      <PatientInfoSection disabled={profile?.isPostAcute} />
      <EpisodeInfoSection profile={profile} />
      <AdditionalInfoSection patient={patient} />
      <ButtonGroup>
        <Button variant='ghost' data-cy='clickable' type='button' disabled={false} onClick={onCancel}>
          Cancel
        </Button>
        <Button data-cy='clickable' disabled={!valid} type='button' onClick={onNextStep}>
          {connecting ? 'Next' : 'Review Patient Details'}
        </Button>
      </ButtonGroup>
    </>
  );
}
