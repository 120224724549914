export default function HamburgerMenuIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
      <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' clipPath='url(#a)'>
        <path d='M.667 14h14.666M.667 2h14.666M.667 8h14.666' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
