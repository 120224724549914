import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';
import { Label } from 'styles/typography';
import CheckboxOffIcon from 'svg/CheckboxOffIcon';
import CheckboxOnIcon from 'svg/CheckboxOnIcon';

export type CheckboxProps = {
  checked?: boolean;
  checkedColor?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
  label?: string;
  labelIcon?: React.ReactNode;
  labelMargin?: string;
  labelSize?: string;
  name?: string;
  onChange?: (val: boolean) => void;
  overrideSetValue?: boolean;
  size?: number;
  style?: Record<string, string>;
  dataCyLabel?: string;
};

export default function Checkbox(props: CheckboxProps) {
  const {
    checked = false,
    checkedColor = '',
    className = '',
    color = colors.black,
    disabled = false,
    label = '',
    labelIcon,
    labelMargin = '10px',
    labelSize = '16px',
    onChange,
    overrideSetValue,
    size = 24,
    style = {},
    dataCyLabel,
  } = props;

  const [isChecked, setIsChecked] = useState(checked || false);

  const handleClick = useCallback(() => {
    const val = !isChecked;

    if (!disabled) {
      if (!overrideSetValue) setIsChecked(val);
      onChange?.(val);
    }
  }, [disabled, overrideSetValue, isChecked, onChange]);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const iconStyles = {
    fill: color,
    width: size,
    height: size,
  };

  return (
    <StyledCheckbox
      className={className}
      onClick={handleClick}
      disabled={disabled}
      color={color}
      labelMargin={labelMargin}
      data-cy='checkbox'>
      <CheckboxContainer disabled={disabled} style={style.checkboxContainer} size={size}>
        {isChecked ? (
          <CheckboxOnIcon
            {...iconStyles}
            color={checkedColor || color}
            // data-cy="checkboxOn"
            data-cy={`checkboxOn ${dataCyLabel}`}
          />
        ) : (
          <CheckboxOffIcon {...iconStyles} data-cy={`checkboxOff ${dataCyLabel}`} />
        )}
      </CheckboxContainer>
      <Label fontSize={labelSize}>{label}</Label>
      {!!labelIcon && <LabelIcon>{labelIcon}</LabelIcon>}
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled.div<{
  disabled: boolean;
  color: string;
  labelMargin: string;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  & label {
    margin-left: ${({ labelMargin }) => labelMargin};
    color: ${({ color }) => color};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Don't remove min-width and min-height!
// I know it seems pointless but IE is dumb.
const CheckboxContainer = styled.div<{ size: number; disabled: boolean; style?: string }>`
  display: flex;
  flex: 0;
  align-items: center;
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
`;

const LabelIcon = styled.div`
  margin-left: 5px;
`;
