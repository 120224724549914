import { DataRow } from 'components/insights/layout/helpers/dataUtils';
import { DATES, NONE, PHYSICIAN_TEAM } from 'constants/filterKeysConstants';
import JobProcess from 'models/JobProcess';
import http from 'services/http';

export const serializeFilters = (filters: any) =>
  Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
    if (!filterValue) return acc;

    if (Array.isArray(filterValue)) {
      if (!filterValue.length) {
        return acc;
      }
      if (filterKey === PHYSICIAN_TEAM) {
        return { ...acc, [filterKey]: filterValue.map((value) => (value.name === NONE ? value.id : value.name)) };
      }
      if (filterKey !== DATES) {
        return { ...acc, [filterKey]: filterValue.map((value) => value.id) };
      }
    }

    return { ...acc, [filterKey]: filterValue };
  }, {});

const formatParams = (params: any, filters: any) => {
  const requestParams = {
    ...params,
    ...serializeFilters(filters),
  };

  return {
    ...requestParams,
    [DATES]: requestParams[DATES].map(({ start, end }: { start: Date; end: Date }) => ({
      start: start.toISOString().slice(0, 10),
      end: end.toISOString().slice(0, 10),
    })),
  };
};

export type Analytics = {
  data: DataRow[];
  dimensionHeaders: { name: string }[];
  links: {
    next: {
      payload: unknown;
      url: string;
    };
    prev: {
      payload: unknown;
      url: string;
    };
  };
  meta: { totalPages: number; totalRecords: number };
  metricHeaders: { name: string }[];
};

export const retrieveAnalytics = async (params: any, filters: any) => {
  return http.post<Analytics>(`/insights/analytics`, formatParams(params, filters)).then((response) => response.data);
};

export const createAnalyticsExport = async ({ params, filters }) => {
  return http
    .post<JobProcess>(`/insights/analytics/exports`, formatParams(params, filters))
    .then((response) => new JobProcess(response.data));
};
