import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Actions = {
  addViewedEpisode: (episodeId: string) => void;
  setCurrentPatientName: (patientName: string) => void;
  setViewedOnLogin: () => void;
  reset: () => void;
};

type State = {
  currentPatientName: string;
  viewedEpisodes: string[];
  viewedOnLogin: boolean;
  actions: Actions;
};

const initialState = {
  currentPatientName: '',
  viewedEpisodes: [],
  viewedOnLogin: false,
};

export const useTaskModalStore = create<State>()(
  persist(
    (set) => ({
      ...initialState,
      actions: {
        addViewedEpisode: (episodeId: string) => {
          set((state) => ({
            viewedEpisodes: [...state.viewedEpisodes, episodeId],
          }));
        },
        setCurrentPatientName: (patientName: string) => {
          set({ currentPatientName: patientName });
        },
        setViewedOnLogin: () => {
          set({ viewedOnLogin: true });
        },
        reset: () => set(initialState),
      },
    }),
    {
      name: 'taskModalStore',
      partialize: ({ viewedEpisodes, viewedOnLogin }) => ({ viewedEpisodes, viewedOnLogin }),
      version: 1,
    }
  )
);

export const useTaskModalActions = () => useTaskModalStore((state) => state.actions);
