import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import StandardModal, { Actions, ColorThemeName, PatientName } from 'components/shared/StandardModal';
import LocationEpisode from 'models/LocationEpisode';
import Review, { ReviewStatus } from 'models/Review';
import { activityQueryKeys } from 'services/api/activity';
import { episodeQueryKeys } from 'services/api/episode';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { portfolioQueryKeys } from 'services/api/portfolio';
import { updateReview } from 'services/api/reviews';
import { useToastActions } from 'stores/toastStore';
import { BodySmall } from 'styles/typography';

export type ConfirmServiceRefusalModalType = {
  setShow: (b: boolean) => void;
  locationEpisode: LocationEpisode;
  patientName: string;
  serviceRefusal: Review;
};
export default function ConfirmServiceRefusalModal(props: ConfirmServiceRefusalModalType) {
  const { setShow, patientName, serviceRefusal, locationEpisode } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToastActions();

  const rehabFacilityName = locationEpisode.rehabInformation.latestRehabFacility.name;
  const isContinuedCare = !!locationEpisode.lastValidPredecessor;
  const bodyMessage = isContinuedCare
    ? `disable the ability to add notes for the patient's stay at ${rehabFacilityName}.`
    : 'remove the patient from the Patient Portfolio.';

  const toastMessage = isContinuedCare
    ? 'The current stay will be removed from the Patient Portfolio.'
    : 'The patient will no longer display on the Patient Portfolio.';

  const { mutate, isPending } = useMutation({
    mutationFn: updateReview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }),
      });
      queryClient.invalidateQueries({
        queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }),
      });
      queryClient.invalidateQueries({
        queryKey: activityQueryKeys.base,
      });
      queryClient.invalidateQueries({
        queryKey: portfolioQueryKeys.index,
      });
      addToast({ text: toastMessage });
      setShow(false);

      if (!isContinuedCare) navigate('/patients');
    },
  });

  const submit = () => {
    mutate({
      id: serviceRefusal.id,
      status: ReviewStatus.ACCEPTED,
    });
  };

  return (
    <StandardModal
      title='Confirm Refusal'
      colorTheme={ColorThemeName.PrimaryRed}
      onCancel={() => {
        setShow(false);
      }}>
      <PatientName>Patient Name: {patientName}</PatientName>
      <FormContainer>
        <BodySmall>Confirming the refusal of services will {bodyMessage}</BodySmall>
      </FormContainer>
      <Actions>
        <Button
          onClick={() => {
            setShow(false);
          }}
          variant='ghost'>
          Cancel
        </Button>
        <Button onClick={submit} variant='danger' loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  margin: 0 58px 50px 64px;
  text-align: center;
`;
