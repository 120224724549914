import React, { useMemo } from 'react';
import styled from 'styled-components';

import { PreferencesFormMeta, PreferencesFormValues } from 'components/notificationPreferences/NotificationPreferences';
import Checkbox from 'components/shared/form/Checkbox';
import { TabType } from 'components/Tabs';
import { useForm } from 'context/form';
import { capitalize, humanize } from 'lib/util';
import { NotificationChannel, NotificationChannels } from 'models/Notifications';
import colors from 'styles/theme/colors';
import { BodySmallBold, Label } from 'styles/typography';

type Props = {
  category: string;
  disabled?: boolean;
  selectedTab: TabType;
};

export default function NotificationCategoryPreferences({ selectedTab, category, disabled = false }: Props) {
  const { values, setValue } = useForm<PreferencesFormValues, PreferencesFormMeta>();

  const categoryPreferences = useMemo(
    () => (values[selectedTab.value]?.notificationSelections[category] ?? {}) as Record<string, NotificationChannels>,
    [values, selectedTab.value, category]
  );

  const _setPref = (key: string, channel: NotificationChannel, val: boolean) => {
    setValue(`${selectedTab.value}.notificationSelections.${category}.${key}.${channel}`, val);
  };
  const handleSelectAll = (val: boolean, channel: NotificationChannel) => {
    const notificationTypes = Object.keys(categoryPreferences);

    notificationTypes.forEach((type) => {
      const channelValue = categoryPreferences[type]?.[channel];

      if (channelValue === undefined) return;
      _setPref(type, channel, val);
    });
  };

  const allValuesSelected = useMemo(() => {
    return Object.values(categoryPreferences).every((v) => v.email);
  }, [categoryPreferences]);

  return (
    <List>
      <Header>{capitalize(humanize(category))}</Header>
      <ListItem>
        <Cell></Cell>
        <CheckboxContainer>
          <HeaderLabel>Email</HeaderLabel>
          <Checkbox
            key={`${selectedTab.value}-all-email`}
            dataCyLabel='select-all-checkbox-email'
            disabled={disabled}
            size={14}
            color={colors.black}
            checkedColor={colors.primaryBlue}
            labelMargin='0px'
            checked={allValuesSelected}
            onChange={(val) => handleSelectAll(val, NotificationChannel.EMAIL)}
          />
        </CheckboxContainer>
      </ListItem>

      {Object.entries(categoryPreferences)
        .sort()
        .map(([key, _val]: [string, any]) => {
          return (
            <ListItem key={key}>
              <Cell>
                <NotificationLabel>{capitalize(humanize(key))}</NotificationLabel>
              </Cell>
              <CheckboxContainer>
                <Checkbox
                  disabled={disabled}
                  size={14}
                  color={colors.black}
                  checkedColor={colors.primaryBlue}
                  labelMargin='0px'
                  checked={categoryPreferences[key]?.email}
                  onChange={(val) => _setPref(key, NotificationChannel.EMAIL, val)}
                />
              </CheckboxContainer>
            </ListItem>
          );
        })}
    </List>
  );
}

const Header = styled(BodySmallBold)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const HeaderLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black75};
  margin-bottom: 10px;
`;

const NotificationLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black75};
  font-weight: 700;
`;

const List = styled.div`
  margin-top: 40px;
`;

const ListItem = styled.div`
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};
  align-items: center;
  line-height: 16px;
`;

const Cell = styled.div<{ style?: any }>`
  min-width: 150px;
  flex: 1;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  align-items: center;
  text-align: center;
`;
