import { eachDayOfInterval, format } from 'date-fns';
import { isArray, mergeWith } from 'lodash';

const mergeWithCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const mergeChartConfigs = (baseConfig: Highcharts.Options, config: Highcharts.Options) =>
  mergeWith(baseConfig, config, mergeWithCustomizer);

export const getFormattedDatesInRange = (startDate: Date, endDate: Date) => {
  const formattedDates = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => format(date, 'yyyy-MM-dd'));

  return formattedDates;
};

export const formatDateLabel = (date: string) => {
  const [_, month, day] = date.split('-');
  return `${month}/${day}`;
};

export const convertMillisToHoursMinutes = (
  milliseconds: number
): { hours: number; minutes: number; seconds: number } => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);

  return { hours: hours, minutes: minutes, seconds: seconds };
};

export const formatDuration = (milliseconds, isDisplay = false) => {
  const data = convertMillisToHoursMinutes(milliseconds);

  if (isDisplay && !data.hours && !data.minutes && data.seconds) {
    return '<0h 1m';
  }

  return `${data.hours}h ${data.minutes}m`;
};
