import styled from 'styled-components';

import Button from 'components/shared/Button';
import useDeepEffect from 'hooks/useDeepEffect';

export type ToggleOption = { label: string; value: string };

export type ToggleGroupProps = {
  autoSelectFirstOption?: boolean;
  visible?: boolean;
  options: ToggleOption[];
  disabled: boolean;
  selectedOption: ToggleOption;
  getOptionLabel: (option: ToggleOption) => string;
  getOptionValue: (option: ToggleOption) => string;
  onChange: (option: ToggleOption) => void;
};

function ToggleGroup({
  autoSelectFirstOption = false,
  visible = true,
  options,
  disabled,
  selectedOption,
  onChange,
  getOptionLabel,
  getOptionValue,
}: ToggleGroupProps) {
  const handleChange = (option: ToggleOption) => {
    if (!disabled) onChange(option);
  };

  useDeepEffect(() => {
    if (autoSelectFirstOption && !selectedOption.value && !!options.length && !disabled) {
      handleChange(options[0]);
    }
  }, [disabled, options, autoSelectFirstOption]);

  const renderButton = (option: ToggleOption) => {
    const optionValue = getOptionValue(option);
    const handleOnClick = () => handleChange(option);

    const selected = option.value === selectedOption.value;

    return (
      <ToggleButton
        key={optionValue}
        className={!disabled && selected ? 'active' : ''}
        disabled={disabled}
        variant={selected ? 'primary' : 'ghost'}
        onClick={handleOnClick}>
        {getOptionLabel(option)}
      </ToggleButton>
    );
  };
  return <Container>{visible && options.map((option) => renderButton(option))}</Container>;
}

export default ToggleGroup;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;

const ToggleButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  min-width: 40px;
  padding: 8px 16px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.black25 : theme.colors.black)};
  background-color: ${({ theme }) => theme.colors.black05};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.white};
  outline: none !important;
  &.active {
    background-color: ${({ theme }) => theme.colors.primaryBlue10};
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.primaryBlue};
    color: $primary-blue;
    opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  }
`;
