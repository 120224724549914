import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { InsightsFilterState } from 'stores/insightsStore';
import { PatientFilterState } from 'stores/patientFiltersStore';
import { PortfolioFilterState } from 'stores/portfolioStore';

export const getFilterCount = (filters: InsightsFilterState | PatientFilterState | PortfolioFilterState): number => {
  return Object.entries(filters).reduce((acc, [, value]) => {
    if (value instanceof FilterValueArray) {
      return acc + value.length;
    } else if (value instanceof FilterValueBoolean && value.value) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
};
