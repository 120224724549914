import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface DischargeQuestionsOptions extends ActivityOptions {
  type: ActivityType.DISCHARGE_QUESTIONS;
  data: Partial<BaseActivityDataOptions & { questions: AnswerType[] }>;
}

export function getDefaults(): DischargeQuestionsOptions {
  const defaults = getActivityDefaults();
  return {
    ...defaults,
    type: ActivityType.DISCHARGE_QUESTIONS,
    data: {
      ...defaults.data,
      questions: [],
    },
  };
}

export default class DischargeQuestions extends Activity {
  declare type: ActivityType.DISCHARGE_QUESTIONS;
  declare data: BaseActivityData & { questions: AnswerType[] };

  constructor(options: Partial<DischargeQuestionsOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Discharge Details';
  }
}
