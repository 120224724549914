const DISPLAY_NAME_MAP = {
  statusOutOfDate: 'Needs Progress Update',
};

export default class FilterValueBoolean {
  name: string;
  value: boolean;

  constructor(value = { name: '', value: false }) {
    this.name = value.name;
    this.value = value.value;
  }

  get label() {
    return DISPLAY_NAME_MAP[this.name] || this.name;
  }

  removeFilter() {
    return new FilterValueBoolean({ ...this, value: false });
  }

  serialize() {
    return this.value;
  }
}
