import styled from 'styled-components';

export const LabelSmall = styled.label`
  font-size: 10px;
  line-height: 14px;
`;
export const LabelSmallBold = styled(LabelSmall)`
  font-weight: var(--font-weight-bold);
`;

export const Label = styled.label<{
  fontSize?: string;
  htmlFor?: string;
}>`
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  line-height: 16px;
`;
export const LabelBold = styled(Label)`
  font-weight: var(--font-weight-bold);
`;

export const Body = styled.p<{
  fontWeight?: string;
  color?: string;
}>`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  color: ${({ color }) => color};
`;
export const BodyBold = styled(Body)`
  font-weight: var(--font-weight-bold);
`;

export const BodySmall = styled(Body)`
  font-size: 14px;
  line-height: 20px;
`;

export const BodySmallBold = styled(BodySmall)`
  font-weight: var(--font-weight-bold);
`;

export const H3 = styled.h3`
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`;
export const H2 = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
`;
export const H1 = styled.h1`
  font-weight: var(--font-weight-bold);
  font-size: 36px;
  line-height: 40px;
  margin: 0px;
`;
