import Group from './Group';

export interface EpisodeGroupOptions {
  id: string;
  episodeId: string;
  groupId: string;
  group: Group;
}

function getDefaults(): EpisodeGroupOptions {
  return {
    id: '',
    episodeId: '',
    groupId: '',
    group: new Group(),
  };
}
/**
 * @class EpisodeGroup
 * @classdesc Represents an episode group in the system
 * @property {string} id - The episode group's id
 * @property {string} episodeId - The episode group's episode id
 * @property {string} groupId - The episode group's group id
 * @property {Group} group - The episode group's group
 * @returns EpisodeGroup
 */

export default class EpisodeGroup {
  id: string;
  episodeId: string;
  groupId: string;
  group: Group;

  constructor(options: Partial<EpisodeGroupOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.episodeId = opts.episodeId;
    this.groupId = opts.groupId;
    this.group = opts.group;
  }
}
