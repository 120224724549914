import { GenericAbortSignal } from 'axios';

import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export interface TaggableUser {
  id: string;
  groupName: string;
  name: string;
}

type IndexParams = {
  locationEpisodeId: string;
} & BaseIndexQueryParams;
export async function indexTaggableUsers({ locationEpisodeId, ...params }: IndexParams, signal: GenericAbortSignal) {
  return http
    .get<Paginated<TaggableUser>>(`location_episodes/${locationEpisodeId}/taggable_users`, {
      params,
      signal,
    })
    .then((res) => ({
      ...res.data,
      data: res.data.data.map((user) => ({
        ...user,
        display: `${user.name} - ${user.groupName}`,
      })),
    }));
}
