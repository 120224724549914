import Classification from 'models/Classification';
import Patient, { PatientOptions } from 'models/Patient';

interface ImportedPatientOptions extends PatientOptions {
  episodeClassification?: Classification;
}

export default class ImportedPatient extends Patient {
  constructor(opts: Partial<ImportedPatientOptions> = {}) {
    // Currently don't support multiple episode classifications for imported patients, so
    // convert the incoming single value to an array for the Patient model used on the Connect form,
    // at which point multiple can be selected.
    super({ ...opts, episodeClassifications: opts.episodeClassification ? [opts.episodeClassification] : [] });
  }
}
