export default function TrashIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      {...props}
      overflow='visible'>
      <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M.667 2.333h10.267M4.4 2.333V.467h2.8v1.866M9.534 4.2l-.375 5.233a1.4 1.4 0 0 1-1.396 1.3H3.835a1.4 1.4 0 0 1-1.397-1.3L2.067 4.2' />
      </g>
    </svg>
  );
}
