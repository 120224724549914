import styled from 'styled-components';

import { SectionContainer } from 'components/Containers';
import Activity from 'models/Activity';
import { BodySmall } from 'styles/typography';

const fieldLabels: { [key: string]: string } = {
  ['admitted_on']: 'Hospital Admission Date',
  ['affiliate_id']: 'Affiliate',
  ['episode_classification_ids']: 'Episode Type',
  ['home_health_agency_id']: 'Home Health Agency',
  ['hospital_id']: 'Hospital',
  ['inpatient_rehab_facility_id']: 'Inpatient Rehab Facility',
  ['group_id']: 'Post Acute',
  ['long_term_acute_care_hospital_id']: 'Long Term Acute Care Hospital',
  ['outpatient_therapy_provider_id']: 'Outpatient Therapy Provider',
  ['payer_classification_id']: 'Plan Type',
  ['plan_type_classification_id']: 'Plan Type',
  ['payer_id']: 'Payer',
  ['physician_group_id']: 'Physician Group',
  ['physician_team_id']: 'Physician Team',
  ['rehab_facility_id']: 'Post Acute',
  ['skilled_nursing_facility_id']: 'Skilled Nursing Facility',
  ['date']: 'Projected Discharge Date',
  ['case_manager_id']: 'Case Manager',
  ['utilization_manager_id']: 'Utilization Manager',
};

function formatFieldChangesKey(text: string): string {
  return text
    .replace(/_/g, ' ')
    .split(' ')
    .map((x) => x[0].toUpperCase() + x.slice(1))
    .join(' ');
}

export function FieldChangesContent(props: { activity: Activity }) {
  const { activity } = props;

  const changesText: string[] = activity.data.changes.map(
    (change: { prop: string; previous: string; current: string }) => {
      return (
        `${fieldLabels[change.prop] || formatFieldChangesKey(change.prop)} updated from ` +
        `${change.previous || '—'} to ${change.current || '—'}`
      );
    }
  );

  return (
    <SectionContainer>
      {changesText?.length === 1 ? (
        <BodySmall>{changesText[0]}</BodySmall>
      ) : (
        <List>
          {changesText.map((change, i) => (
            <ListItem key={i}>{change}</ListItem>
          ))}
        </List>
      )}
    </SectionContainer>
  );
}

const List = styled.ul`
  list-style: inside;
  margin: 0px;
  padding-left: 0px;
`;

const ListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;
