import React from 'react';
import Highcharts from 'highcharts';
import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';

ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

type Props = {
  minValue?: number;
  maxValue: number;
  value: number;
  colorStops: [number, string][];
  label: string;
  legend: { name: string; value: number; color: string }[];
};

const FullGauge = ({ colorStops, label, legend, maxValue, minValue = 0, value }: Props) => {
  const config = {
    chart: {
      type: 'solidgauge',
      height: 140,
      width: 140,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: label,
      align: 'center',
      style: {
        fontSize: 24,
      },
      verticalAlign: 'middle',
    },
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor: `${colors.black15}`,
        innerRadius: '88%',
        outerRadius: '100%',
        borderColor: 'none',
        borderWidth: 0,
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      stops: colorStops,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickPositions: [minValue, maxValue],
      labels: {
        enabled: false,
      },
      min: minValue,
      max: maxValue,
    },
    series: [
      {
        data: [value],
      },
    ],
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      solidgauge: {
        innerRadius: '88%',
        dataLabels: {
          enabled: false,
        },
      },
    },
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={config} />
      <LegendWrapper>
        {legend.map((item) => (
          <div key={item.name}>
            <LegendLabelWrapper>
              <LegendDot style={{ backgroundColor: item.color }} />
              <LegendTitle>{item.name}</LegendTitle>
            </LegendLabelWrapper>
            <LegendValue>{item.value}</LegendValue>
          </div>
        ))}
      </LegendWrapper>
    </Container>
  );
};

export default FullGauge;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  > * + * {
    margin-top: 24px;
  }

  @media (max-width: 420px) {
    display: none;
  }
`;

const LegendLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendTitle = styled.p`
  margin: 0 0 0 8px;
  font-size: 12px;
`;

const LegendValue = styled.p`
  margin: 8px 0 0 15px;
  font-size: 16px;
  font-weight: bold;
`;

const LegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
`;

const Container = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
