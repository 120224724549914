import Button from 'components/shared/Button';
import StandardModal, { Actions, ColorThemeName, ModalContent } from 'components/shared/StandardModal';
import ThemeProvider from 'components/ThemeProvider';
import { H3 } from 'styles/typography';

type ConfirmEscalationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default function ConfirmEscalationModal({ onCancel, onConfirm }: ConfirmEscalationModalProps) {
  return (
    <ThemeProvider>
      <StandardModal colorTheme={ColorThemeName.Danger} title='Confirm Escalation' onCancel={onCancel}>
        <ModalContent>
          <H3>Are you sure you want to send an escalation?</H3>
          This will notify all collaborating providers.
        </ModalContent>
        <Actions>
          <Button variant='ghost' onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </Actions>
      </StandardModal>
    </ThemeProvider>
  );
}
