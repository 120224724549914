export default function RefusalIcon({ width = 12, height = 12, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='demo-walkthrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Confirm-or-Reassign-Actions'
          transform='translate(-872.000000, -294.000000)'
          fill='#D10D44'
          fillRule='nonzero'>
          <g id='f-delete' transform='translate(872.000000, 294.000000)'>
            <path
              d='M6,0 C2.6862915,0 0,2.6862915 0,6 C0,9.3137085 2.6862915,12 6,12 C9.3137085,12 12,9.3137085 12,6 C12,2.6862915 9.3137085,0 6,0 Z M9.13043478,6.7826087 L2.86956522,6.7826087 L2.86956522,5.2173913 L9.13043478,5.2173913 L9.13043478,6.7826087 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
