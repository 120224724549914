import React from 'react';
import styled from 'styled-components';

import AttachmentComponent from 'components/shared/Attachment';
import Upload from 'hooks/useDirectUpload/Upload';

import FileInputComponent from './FileInputComponent';

type FileInputAndAttachmentProps = {
  attachments: Upload[];
  onDeleteAttachment?: (id: string) => void;
  onFilesSelected?: (files: File[]) => void;
  onSetDocType?: (id: string, docType: string | null) => void;
};

export default function FileInputAndAttachments(props: FileInputAndAttachmentProps) {
  const { attachments, onDeleteAttachment, onFilesSelected, onSetDocType } = props;

  return (
    <Container>
      <FileInputComponent onFilesSelected={onFilesSelected} filesCount={attachments.length} />
      {attachments.map((attachment) => (
        <AttachmentComponent
          key={attachment.id}
          error={attachment.hasError}
          attachment={attachment}
          uploadProgress={attachment.progress}
          onDeleteAttachment={onDeleteAttachment}
          onDocumentTypeChange={onSetDocType}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
