import { DependencyList, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';

export default function useDeepMemo<T>(memoFunc: () => T, deps: DependencyList = []): T {
  const prevDeps = useRef<DependencyList | null>(null);
  const prevValue = useRef<T | null>(null);

  return useMemo(() => {
    const isSame = !!(prevDeps.current && prevDeps.current.every((obj, index) => isEqual(obj, deps[index])));

    if (!isSame) {
      prevValue.current = memoFunc();
      prevDeps.current = deps;
    }

    return prevValue.current as T;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
