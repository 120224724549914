export default function RemovedImageIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='note-strikethrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.5'>
        <g
          id='image-has-been-removed'
          transform='translate(-577.000000, -643.000000)'
          fill='#000000'
          fillRule='nonzero'>
          <g id='image-delete' transform='translate(577.000000, 643.000000)'>
            <circle id='Oval' cx='6' cy='5' r='1' />
            <rect id='Rectangle' x='10' y='12' width='6' height='2' />
            <path
              d='M8,14 L2,14 L2,2 L14,2 L14,8 L16,8 L16,1 C16,0.44771525 15.5522847,0 15,0 L1,0 C0.44771525,0 0,0.44771525 0,1 L0,15 C0,15.5522847 0.44771525,16 1,16 L8,16 L8,14 Z'
              id='Path'
            />
            <path
              d='M11.628,9.257 L10,6 L7,10 L5,8 L3,12 L9.141,12 C9.46943323,10.7328351 10.3989684,9.707618 11.628,9.257 Z'
              id='Path'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
