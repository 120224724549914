export default function ActivityPatientMatchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='match-on-add-/-connect' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='new-expanded-pt-details' transform='translate(-573.000000, -639.000000)' fill='#0F1226'>
          <g id='Group-3-Copy' transform='translate(559.000000, 625.000000)'>
            <g id='Group-13' transform='translate(14.000000, 14.000000)'>
              <rect id='Rectangle-Copy-2' fillOpacity='0.25' opacity='0.25' x='0' y='0' width='24' height='24' rx='3' />
              <g id='s-add' transform='translate(6.000000, 6.000000)' fillOpacity='0.5' fillRule='nonzero'>
                <path
                  d='M11.25,0 L0.75,0 C0.3,0 0,0.3 0,0.75 L0,11.25 C0,11.7 0.3,12 0.75,12 L11.25,12 C11.7,12 12,11.7 12,11.25 L12,0.75 C12,0.3 11.7,0 11.25,0 Z M9,6.75 L6.75,6.75 L6.75,9 L5.25,9 L5.25,6.75 L3,6.75 L3,5.25 L5.25,5.25 L5.25,3 L6.75,3 L6.75,5.25 L9,5.25 L9,6.75 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
