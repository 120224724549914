import styled from 'styled-components';

import Button from 'components/shared/Button';

export default styled(Button)`
  padding: 0;
  color: var(--black);
  font-weight: normal;

  span {
    display: none;

    @media ${({ theme }) => theme.devices.desktop} {
      display: inline;
    }
  }
`;
