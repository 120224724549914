import styled from 'styled-components';

import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import { useForm } from 'context/form';
import { simpleDate } from 'lib/date';

type ConfirmationItem = {
  label: string;
  value: string | React.ReactNode;
  renderCondition?: boolean;
};

export default function DischargeConfirm() {
  const { values } = useForm<DischargeFormValues, DischargeFormMeta>();

  const items: ConfirmationItem[] = [
    { label: 'Discharge Date', value: simpleDate(values.actualDischargeDate) },
    { label: 'Discharge Disposition', value: values.dischargeReason?.displayName },
    { label: 'Discharge Location', value: values.dischargedGroup?.name },
    { label: 'Note', value: values.note?.plaintext, renderCondition: !!values.note?.plaintext },
  ];

  if (values.note?.attachments.length) {
    values.note.attachments.forEach((attachment) => {
      items.push({
        label: 'Attachment',
        value: <div style={{ marginBottom: '8px' }}>{attachment.filename}</div>,
      });
    });
  }

  return items.map((item, index) => {
    if (typeof item.renderCondition != 'undefined' && !item.renderCondition) return null;
    if (!item.value) return null;

    return (
      <ConfirmationContainer key={index}>
        <ConfirmationLabel>{item.label}</ConfirmationLabel>
        <ConfirmationValue>{item.value}</ConfirmationValue>
      </ConfirmationContainer>
    );
  });
}

const ConfirmationContainer = styled.div`
  padding: 16px 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
  word-break: break-word;
`;

const ConfirmationLabel = styled.div`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 14px;
  font-weight: 700;
  flex-basis: 50%;
  line-height: 16px;
`;

const ConfirmationValue = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 400;
  flex-basis: 50%;
  line-height: 16px;
`;
