import { useInfiniteQuery } from '@tanstack/react-query';

import { useDebounce } from 'hooks/useDebounce';
import { indexTaggableUsers } from 'services/api/locationEpisode/taggableUsers';

export default function useTaggableUsersInfiniteQuery(
  locationEpisodeId: string | undefined,
  search?: string | undefined,
  delay?: number | undefined
) {
  const queryKeyValues = ['taggableUsers', { locationEpisodeId: locationEpisodeId ?? '', search }];
  const debouncedQueryKeyValues = useDebounce(queryKeyValues, delay);
  const queryKey = !!delay && delay > 0 ? debouncedQueryKeyValues : queryKeyValues;
  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      indexTaggableUsers({ locationEpisodeId: locationEpisodeId ?? '', page: pageParam, pageSize: 10, search }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
  });
}
