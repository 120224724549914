export default function ChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='5' height='9' fill='none' viewBox='0 0 5 9' {...props}>
      <path
        fill='currentColor'
        d='M5.00017 0.80083C5.00013 1.01325 4.91571 1.21696 4.76548 1.36714L2.12778 4.00484L4.76548 6.64253C4.91139 6.7936 4.99213 6.99594 4.9903 7.20596C4.98847 7.41598 4.90423 7.61688 4.75572 7.76539C4.60721 7.9139 4.40631 7.99814 4.19629 7.99997C3.98627 8.00179 3.78393 7.92106 3.63286 7.77515L0.428858 4.57114C0.278694 4.42093 0.194336 4.21723 0.194336 4.00484C0.194336 3.79244 0.278694 3.58874 0.428858 3.43853L3.63286 0.234523C3.74489 0.122535 3.8876 0.0462747 4.04295 0.0153818C4.19831 -0.0155106 4.35934 0.000350952 4.50568 0.0609627C4.65202 0.121574 4.77711 0.224215 4.86513 0.355908C4.95314 0.4876 5.00014 0.642432 5.00017 0.80083Z'
      />
    </svg>
  );
}
