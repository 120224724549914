export default function WarnIcon({ width = 11, height = 11, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 16 16' width={width} height={height} version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='warn-icon' stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
        <path
          fill='currentColor'
          d='M15.216,13.529L8.882,1.654c-0.376-0.706-1.388-0.706-1.765,0L0.784,13.529C0.429,14.195,0.912,15,1.667,15 h12.667C15.088,15,15.571,14.195,15.216,13.529z M8,13c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1 C9,12.552,8.552,13,8,13z M9,10H7V6h2V10z'
        />
      </g>
    </svg>
  );
}
