import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { FiltersGeneric as Filters } from 'components/shared/filters/FilterBar';
import FilterSlideoutButtons from 'components/shared/filters/FilterSlideoutButtons';
import { FILTER_TYPES } from 'constants/filterConfigs';
import useDeepMemo from 'hooks/useDeepMemo';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { FilterOption } from 'stores/types';
import colors from 'styles/theme/colors';

import Checkbox, { CheckboxProps } from '../form/Checkbox';

import FilterMultiSelect, { FilterMultiSelectProps } from './FilterMultiSelect';
import FiltersFoldout from './FiltersFoldout';
import { getFilterCount } from './helpers';

const parseCountDefault = (data: { meta: { totalRecords: number } }) => data.meta.totalRecords;

export type FilterSection = {
  title: string;
  filters: FilterMultiSelectProps[] | CheckboxProps[];
};

type FilterSlideoutProps = {
  handleClose: () => void;
  filters: Filters;
  filterSections: FilterSection[];
  search?: string;
  showResults?: boolean;
  resultsText?: string;
  handleApply: (filters: Filters) => void;
  filterFormatter?: (filters: Filters) => object;
  parseCount?: (data: any) => number;
  queryFn: (params: any, signal: AbortSignal) => void;
};

const FilterSlideout = ({
  handleClose,
  filters,
  filterSections,
  search = '',
  showResults = false,
  resultsText,
  handleApply,
  parseCount = parseCountDefault,
  queryFn = () => {},
}: FilterSlideoutProps) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const localFilterCount = useDeepMemo(() => getFilterCount(localFilters), [localFilters]);

  const handleCancel = () => {
    setLocalFilters(filters);
    handleClose();
  };

  const handleClear = () => {
    const resetFilters = Object.entries(localFilters).reduce((acc, [key, value]) => {
      if (value instanceof FilterValueArray) {
        return { ...acc, [key]: new FilterValueArray([]) };
      } else if (value instanceof FilterValueBoolean) {
        return { ...acc, [key]: new FilterValueBoolean({ value: false, name: key }) };
      }

      return { ...acc, [key]: value };
    }, {});

    setLocalFilters(resetFilters as Filters);
  };

  const { data: resultsCount } = useQuery({
    queryKey: ['filterSlideout', { ...localFilters, search }],
    queryFn: ({ signal }) => queryFn({ filters: localFilters, search }, signal),
    select: parseCount,
    placeholderData: 0,
    enabled: showResults,
  });

  return (
    <FiltersFoldout closeModal={handleClose}>
      {{
        content: (
          <>
            {filterSections.map((section: FilterSection, i) => (
              <Section key={i}>
                <SectionTitle> {section.title} </SectionTitle>
                {section.filters.map((filter, index) => (
                  <Fragment key={index}>
                    {filter.filterType === FILTER_TYPES.DROPDOWN && (
                      <DropdownContainer>
                        <FilterMultiSelect
                          {...filter}
                          onChange={(val) =>
                            setLocalFilters({
                              ...localFilters,
                              [filter.name]: val,
                            })
                          }
                          value={localFilters[filter.name] as FilterOption[]}
                        />
                      </DropdownContainer>
                    )}
                    {filter.filterType === FILTER_TYPES.CHECKBOX && (
                      <Checkbox
                        checked={(localFilters[filter.name] as FilterValueBoolean)?.value}
                        checkedColor={colors.primaryBlue}
                        size={14}
                        labelSize='14px'
                        label={filter.label}
                        onChange={(val) =>
                          setLocalFilters({
                            ...localFilters,
                            [filter.name]: new FilterValueBoolean({ value: val, name: filter.name }),
                          })
                        }
                      />
                    )}
                  </Fragment>
                ))}
              </Section>
            ))}
          </>
        ),
        footer: (
          <>
            {showResults && (
              <Results>
                <P data-cy='filterSlideoutTotal'>{`${resultsCount} ${resultsText || 'results'}`} found</P>
              </Results>
            )}
            <Footer>
              <Link disabled={!localFilterCount} onClick={handleClear}>
                Clear Selections
              </Link>
              <FilterSlideoutButtons
                handleApply={() => handleApply({ ...localFilters, search })}
                handleCancel={handleCancel}
                handleExit={handleClose}
              />
            </Footer>
          </>
        ),
      }}
    </FiltersFoldout>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
`;

const DropdownContainer = styled.div`
  gap: none;
`;

const Results = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.black10};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
`;

const Link = styled.a<{ disabled: boolean }>`
  font-size: 14px;
  color: ${({ disabled }: { disabled: boolean }) => (disabled ? colors.accentRed25 : colors.accentRed)};
  text-decoration: underline;
  &:hover {
    cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const P = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
`;

export default FilterSlideout;
