import styled from 'styled-components';

import { BodySmall } from 'styles/typography';

export default function OlioFooter() {
  const year = new Date().getFullYear();

  return (
    <Container>
      &copy; {year} Olio Health | Confidential |{' '}
      <span
        onClick={() => {
          window.open('https://policy.olio.health/privacy-policy', '_blank');
        }}>
        Privacy Policy
      </span>
    </Container>
  );
}

const Container = styled(BodySmall)`
  color: var(--black-50);
  padding: 12px 0 12px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;

  span {
    color: var(--primary-blue);
    cursor: pointer;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 16px 0 24px;
    font-size: 14px;
  }
`;
