import { useState } from 'react';
import styled from 'styled-components';

import EditDischargeQuestionsModal from 'components/modals/EditDischargeQuestionsModal';
import Menu from 'components/shared/menu';
import DischargeQuestions from 'models/activities/DischargeQuestions';
import Note from 'models/activities/Note';
import ProgressUpdate from 'models/activities/ProgressUpdate';
import Activity from 'models/Activity';
import Profile from 'models/Profile';
import { useUpdateActivity } from 'services/api/activity';
import { colors } from 'styles/theme/colors';
import CircleXIcon from 'svg/CircleXIcon';
import MenuHorizontalIcon from 'svg/MenuHorizontalIcon';
import PencilIcon from 'svg/PencilIcon';
import RestoreIcon from 'svg/RestoreIcon';

type Props = {
  activity: Activity;
  profile: Profile;
};

export function ActivityCardMenu({ activity, profile }: Props) {
  const [showEditDischargeModal, setShowEditDischargeModal] = useState(false);

  const { mutate } = useUpdateActivity();

  const toggleRemoved = () => {
    mutate({
      id: activity.id,
      textRemoved: !activity.data.textRemoved,
      include: 'attachments.blob',
    });
  };

  const canDischarge = activity instanceof DischargeQuestions && (activity.ageInHours ?? 0) <= 72;
  const canRemove =
    activity.createdBy.id === profile.id &&
    !activity.escalated &&
    !!(activity.data.text?.length > 0 || activity.attachments.length) &&
    (activity instanceof Note || activity instanceof ProgressUpdate);

  if (!canDischarge && !canRemove) return <></>;

  return (
    <Container>
      <Menu>
        <Menu.Trigger>
          <MenuHorizontalIcon />
        </Menu.Trigger>
        <Menu.Content position='right'>
          {canRemove && (
            <Menu.Item onClick={toggleRemoved}>
              {activity.data.textRemoved ? (
                <RestoreIcon />
              ) : (
                <CircleXIcon color={colors.accentRed} width={18} height={18} />
              )}
              {activity.data.textRemoved ? 'Restore Note' : 'Remove Note'}
              {!!activity.attachments.length && ' & Attachments'}
            </Menu.Item>
          )}
          {canDischarge && (
            <Menu.Item onClick={() => setShowEditDischargeModal(true)}>
              <PencilIcon width={18} height={18} />
              Edit Discharge Details
            </Menu.Item>
          )}
        </Menu.Content>
      </Menu>
      {activity instanceof DischargeQuestions && showEditDischargeModal && (
        <EditDischargeQuestionsModal activity={activity} setShow={setShowEditDischargeModal} />
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-left: auto;
  width: 20px;
  position: relative;
`;
