import { InfiniteData } from '@tanstack/react-query';

import { Paginated } from 'models/Paginated';

export default function updateInfiniteQueryRecord<Record extends { id: string }>(
  prevData: InfiniteData<Paginated<Record>> | undefined,
  updatedRecord: Record
) {
  if (!prevData) return prevData;
  return {
    ...prevData,
    pages: prevData.pages.map((page) => {
      return {
        ...page,
        data: page.data.map((a) => {
          return a.id === updatedRecord.id ? updatedRecord : a;
        }),
      };
    }),
  };
}
