import { useCallback } from 'react';
import { debounce } from 'lodash';
import { SuggestionDataItem } from 'react-mentions';

import { useQueryClient } from '@tanstack/react-query';

import { Paginated } from 'models/Paginated';
import { indexTaggableUsers, TaggableUser } from 'services/api/locationEpisode/taggableUsers';

export default function useTaggableUsersQuery(locationEpisodeId: string | undefined) {
  const queryClient = useQueryClient();

  const formatQueryResponse = useCallback((response: Paginated<TaggableUser>) => {
    return (
      response?.data.map((user: TaggableUser) => ({
        ...user,
        display: `${user.name} - ${user.groupName}`,
      })) || []
    );
  }, []);

  const fetchTaggableUsers = useCallback(
    async (search: string, callback: (data: SuggestionDataItem[]) => void) => {
      const queryKey = ['taggableUsers', { locationEpisodeId: locationEpisodeId ?? '', search }];
      const queryFn = ({ signal }) =>
        indexTaggableUsers({ locationEpisodeId: locationEpisodeId ?? '', pageSize: 25, search }, signal);

      queryClient.fetchQuery({ queryKey, queryFn }).then(formatQueryResponse).then(callback);
    },
    [formatQueryResponse, locationEpisodeId, queryClient]
  );

  // `indexTaggableUsers` has deps that are unknown to this callback hook so it throws a benign warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(fetchTaggableUsers, 250), [fetchTaggableUsers]);
}
