import { parseDate } from 'lib/date';

export enum RehabStateName {
  Queue = 'Queue',
  Admission = 'Admission',
  InTreatment = 'In Treatment',
  Discharged = 'Discharged',
}

export enum RehabStateApiName {
  Queue = 'queue',
  Admission = 'admission',
  InTreatment = 'inTreatment',
  Discharged = 'discharged',
}

export interface RehabStateOptions {
  current: boolean;
  enteredAt: string | Date | null;
  id: string;
  position: number;
  state: RehabStateName;
  apiName?: RehabStateApiName;
}

function getDefaults(): RehabStateOptions {
  return {
    current: true,
    enteredAt: new Date(),
    id: '',
    position: 0,
    state: RehabStateName.Queue,
  };
}

/**
 * @class RehabState
 * @classdesc Represents a rehab state in the system
 * @property {boolean} current - Whether the rehab state is current
 * @property {string | Date | null} enteredAt - The rehab state's entry date
 * @property {string} id - The rehab state's id
 * @property {number} position - The rehab state's position
 * @property {RehabStateName} state - The rehab state's name
 * @property {string} apiName - The rehab state's api name
 * @param {Partial<RehabStateOptions>} [options={}]
 * @example const rehabState = new RehabState({ id: '123' });
 */
export default class RehabState {
  current: boolean;
  enteredAt: Date | null;
  id: string;
  position: number;
  state: RehabStateName;
  apiName: RehabStateApiName;

  constructor(options: Partial<RehabStateOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.current = opts.current;
    this.enteredAt = parseDate(opts.enteredAt);
    this.id = opts.id;
    this.position = opts.position;
    this.state = opts.state;
    this.apiName = (opts.state.charAt(0).toLowerCase() + opts.state.slice(1).replace(/ /g, '')) as RehabStateApiName;
  }

  get queue() {
    return this.state === RehabStateName.Queue;
  }

  get admission() {
    return this.state === RehabStateName.Admission;
  }

  get inTreatment() {
    return this.state === RehabStateName.InTreatment;
  }

  get discharged() {
    return this.state === RehabStateName.Discharged;
  }

  get name() {
    return this.state;
  }
}
