export default function HospitalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        id='info-sections-with-icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-224.000000, -360.000000)' stroke='#3253EF'>
          <g id='hospital-32' transform='translate(225.000000, 361.000000)'>
            <polygon id='Path' points='12 4 8 4 8 0 4 0 4 4 0 4 0 8 4 8 4 12 8 12 8 8 12 8' />
          </g>
        </g>
      </g>
    </svg>
  );
}
