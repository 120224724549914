import SparkMD5 from 'spark-md5';

import http from 'services/http';

export type PresignedResponse = {
  id: string;
  key: string;
  filename: string;
  content_type: string;
  byte_size: number;
  checksum: string;
  signedId: string;
  attachable_sgid: string;
  direct_upload: {
    url: string;
    headers: {
      'Content-Type': string;
      'Content-MD5': string;
      'Content-Disposition': string;
    };
  };
};

const CHUNK_SIZE = 2097152;
const checksum = (file: File) =>
  new Promise<string>((resolve, reject) => {
    let currentChunk = 0;
    const chunks = Math.ceil(file.size / CHUNK_SIZE);

    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    const loadNext = () => {
      const start = currentChunk * CHUNK_SIZE;
      const end = start + CHUNK_SIZE >= file.size ? file.size : start + CHUNK_SIZE;

      // Selectively read the file and only store part of it in memory.
      // This allows client-side applications to process huge files without the need for huge memory
      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    fileReader.onload = (e) => {
      spark.append(e.target!.result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chunks) loadNext();
      else {
        const binaryDigest = spark.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    };

    fileReader.onerror = () => {
      return reject('Calculating file checksum failed');
    };

    loadNext();
  });

export const createPresigned = async (file: File) => {
  const md5 = await checksum(file);
  const data = {
    blob: {
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
      checksum: md5,
    },
  };

  return http.post<PresignedResponse>('uploads', data).then((res) => res.data);
};
