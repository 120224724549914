import { memo } from 'react';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { colors } from 'styles/theme/colors';

import { mergeChartConfigs } from './helpers';

type AreaChartProps = {
  formatTooltipSupertext?: (x: string | number | undefined) => string | undefined;
  formatTooltipSubtext?: (y: number | null | undefined, key: string) => string | undefined;
  categories: string[];
  values: number[];
  config?: Highcharts.Options;
};

const AreaChart = memo(
  ({
    formatTooltipSupertext = (x: string | number | undefined) => x as undefined,
    formatTooltipSubtext = (y: number | null | undefined) => y as undefined,
    categories,
    values,
    config = {},
  }: AreaChartProps) => {
    const baseConfig: Highcharts.Options = {
      chart: {
        type: 'area',
        spacing: [10, 0, 5, 0],
        style: {
          fontFamily: 'Lato',
        },
      },
      title: undefined,
      credits: { enabled: false },

      yAxis: {
        title: undefined,
        gridLineColor: '#ffffff',
        labels: {
          style: {
            fontSize: '8px',
            color: colors.black50,
            fontWeight: 'normal',
          },
        },
      },

      xAxis: {
        categories: categories,
        gridLineWidth: 0,
        tickLength: 0,
        lineWidth: 0,
        tickInterval: Math.ceil(values.length / 5), // Show 5 ticks
        labels: {
          style: {
            fontSize: '8px',
            color: colors.black50,
            fontWeight: 'normal',
            bottom: '5px',
          },
        },
      },

      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          states: {
            hover: {
              lineWidth: 2,
              halo: {
                attributes: {
                  fill: colors.primaryBlue,
                },
                opacity: 0.1,
              },
            },
          },
        },
        area: {
          fillColor: colors.primaryBlue05,
          lineColor: colors.primaryBlue,
          lineWidth: 2,
          marker: {
            enabled: !(values.length > 1),
            fillColor: colors.primaryBlue,
            states: {
              hover: {
                fillColor: colors.primaryBlue,
                lineWidth: 0,
              },
            },
          },
        },
      },

      tooltip: {
        backgroundColor: '#4b4d5c',
        padding: 16,
        style: {
          color: colors.white,
        },
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          return `
            <b style="font-size: 12px; font-weight: bold;">${formatTooltipSupertext(this.x)}</b>
            <br />
            ${formatTooltipSubtext(this.y, this.key as string) || ''}
            `;
        },
      },

      series: [
        {
          name: 'Values',
          data: values,
          type: 'area',
        },
      ],
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={mergeChartConfigs(baseConfig, config)}
        containerProps={{ style: { flex: '1 1 0' } }}
      />
    );
  }
);

export default AreaChart;
