import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { useWasTrue } from 'hooks/useWasTrue';
import Attachment from 'models/Attachment';
import { attachmentsQueryKeys, showAttachment } from 'services/api/attachments';

import LazyImage from './LazyImage';
import StandardModal from './StandardModal';

type ImageViewerInput = {
  attachment: Attachment;
  onClose?: () => void;
  show: boolean;
};

export default function ImageViewer(props: ImageViewerInput) {
  const { attachment, onClose, show } = props;
  const everShown = useWasTrue(show); //don't unload the image just because the modal was closed.

  const { data: fullAttachment } = useQuery({
    queryKey: attachmentsQueryKeys.show(attachment.id),
    queryFn: () => showAttachment({ id: attachment.id, include: 'url' }),
    enabled: !everShown,
  });

  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    show && (
      <StandardModal title='Attached Image' onCancel={onClose}>
        <ImageContainer $imageLoaded={imageLoaded}>
          <LazyImage
            src={fullAttachment?.url ?? ''}
            customStyles={{ maxWidth: '100%' }}
            alt='attached image'
            onLoaded={setImageLoaded}
            useSpinner
          />
        </ImageContainer>
      </StandardModal>
    )
  );
}

const ImageContainer = styled.div<{ $imageLoaded: boolean }>`
  display: flex;
  margin: auto;
  max-width: 10vw;
  max-height: 10vw;
  overflow: auto;
  padding: 16px;

  ${({ $imageLoaded }) =>
    $imageLoaded &&
    css`
      max-width: 60vw;
      max-height: 40vw;
      transition: all 0.5s linear;
    `};

  /*
        Set margin on the children to fix issue
        with centering & scrolling larger images
    */
  & > * {
    margin: auto;
  }
`;
