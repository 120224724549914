import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/shared/Button';
import { useTaskModalActions } from 'stores/taskModalStore';
import { BodySmall, LabelBold } from 'styles/typography';
import LoggedOutIcon from 'svg/LoggedOut';

import SimpleLandingPage from './SimpleLandingPage';

export default function LoggedOut() {
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/');

  useTaskModalActions().reset();

  return (
    <SimpleLandingPage>
      <LoggedOutIcon />
      <StyledBody>
        <LabelBold fontSize='14px'>You&apos;ve been logged out of Olio.</LabelBold>
        <br />
        Click the button below to log back in.
      </StyledBody>
      <StyledButton onClick={handleOnClick}>Log Back in to Olio</StyledButton>
    </SimpleLandingPage>
  );
}

const StyledBody = styled(BodySmall)`
  margin: 36px 0px;
  width: 100%;
  text-align: center;
  padding: 0 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
