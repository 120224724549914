import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  Changes,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface PatientFieldChangesOptions extends ActivityOptions {
  type: ActivityType.PATIENT_FIELD_CHANGES;
  data: Partial<BaseActivityDataOptions & { enteredAt: string; changes: Changes }>;
}

export function getDefaults(): PatientFieldChangesOptions {
  const defaults = getActivityDefaults();
  return {
    ...defaults,
    type: ActivityType.PATIENT_FIELD_CHANGES,
    data: {
      ...defaults.data,
      enteredAt: '',
      changes: [],
    },
  };
}

export default class PatientFieldChanges extends Activity {
  declare type: ActivityType.PATIENT_FIELD_CHANGES;
  declare data: BaseActivityData & { enteredAt: string; changes: Changes };

  constructor(options: Partial<PatientFieldChangesOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Patient Details';
  }
}
