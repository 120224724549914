import React from 'react';

export default function NextReviewIcon({ width = 16, height = 16 }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3084_3769)'>
        <path d='M12.6875 10.3438H15.0313V7.53125' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M14.913 10.3437C14.7019 9.52563 14.2207 8.80279 13.5474 8.29236C12.8741 7.78194 12.0482 7.51385 11.2034 7.53155C10.3587 7.54925 9.5447 7.8517 8.89337 8.38988C8.24203 8.92806 7.79153 9.67043 7.61487 10.4967C7.43821 11.3229 7.54576 12.1846 7.92007 12.9421C8.29439 13.6995 8.91353 14.3084 9.67717 14.67C10.4408 15.0316 11.3042 15.1247 12.1273 14.9342C12.9505 14.7437 13.6852 14.2809 14.2124 13.6206'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M2.84375 4.71875H9.40625' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.84375 7.53125H6.59375' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.84375 10.3438H5.1875' stroke='#0F1226' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M5.1875 13.1563H1.90625C1.65761 13.1563 1.41915 13.0575 1.24334 12.8817C1.06752 12.7058 0.96875 12.4674 0.96875 12.2188V1.90625C0.96875 1.65761 1.06752 1.41915 1.24334 1.24334C1.41915 1.06752 1.65761 0.96875 1.90625 0.96875H8.54938C8.79784 0.968803 9.03613 1.06749 9.21188 1.24313L11.0069 3.03813C11.1825 3.21387 11.2812 3.45216 11.2813 3.70063V5.1875'
          stroke='#0F1226'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3084_3769'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
