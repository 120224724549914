import { useState } from 'react';

export enum IntakeSteps {
  Information = 'information',
  Confirm = 'confirm',
}

export default function useIntakeSteps(steps: IntakeSteps[]) {
  const [step, setStep] = useState<IntakeSteps>(IntakeSteps.Information);

  const handlePreviousStep = () => {
    const currentIndex = steps.indexOf(step);
    setStep(steps[currentIndex - 1]);
  };

  const handleNextStep = () => {
    const currentIndex = steps.indexOf(step);
    setStep(steps[currentIndex + 1]);
  };

  return { step, handleNextStep, handlePreviousStep };
}
