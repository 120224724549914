import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import useAsyncExport from 'hooks/useAsyncExport';
import { createEpisodeExport } from 'services/api/episode';
import { colors } from 'styles/theme/colors';
import ExportIcon from 'svg/ExportIcon';

type Props = {
  episodeId: string;
};

export default function EpisodeExportButton({ episodeId }: Props) {
  const { exporting, initiateExport } = useAsyncExport(createEpisodeExport);

  return (
    <>
      {exporting ? (
        <ExportLoadingIndicator color={colors.black} thickness={2} />
      ) : (
        <ActionIcon onClick={() => initiateExport(episodeId)}>
          <ExportIcon color={colors.black} />
        </ActionIcon>
      )}
    </>
  );
}

const ActionIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

const ExportLoadingIndicator = styled(CircularProgress)`
  width: 20px;
  height: 20px;
`;
