import styled from 'styled-components';

import Menu from 'components/shared/menu';
import { TaggableUser } from 'services/api/locationEpisode/taggableUsers';
import AtIcon from 'svg/AtIcon';

import IconButton from './IconButton';
import renderSuggestionDataItem from './renderSuggestionDataItem';
import useTaggableUsersInfiniteQuery from './useTaggableUsersInfiniteQuery';

export type TaggableUserMenuItemProps = { display: string } & TaggableUser;

type TaggableUserMenuProps = {
  locationEpisodeId: string | undefined;
  onMenuItemClick: (user: TaggableUserMenuItemProps) => void;
};

export default function TaggableUserMenu(props: TaggableUserMenuProps) {
  const { locationEpisodeId, onMenuItemClick } = props;
  const taggableUsersResponse = useTaggableUsersInfiniteQuery(locationEpisodeId);
  const taggableUsers = taggableUsersResponse.data?.pages.flatMap((page) => page.data) || [];

  return (
    <Menu $size='small'>
      <Menu.Trigger>
        <IconButton size='xsmall' variant='ghost' type='button'>
          <AtIcon />
          <span>Tag</span>
        </IconButton>
      </Menu.Trigger>
      <Menu.Content
        position='left'
        style={{ maxHeight: '20vh', maxWidth: 'none' }}
        hasMore={taggableUsersResponse.hasNextPage}
        loading={taggableUsersResponse.isLoading || taggableUsersResponse.isFetchingNextPage}
        onEndReached={taggableUsersResponse.fetchNextPage}>
        {taggableUsers.map((user, i) => (
          <StyledMenuItem key={i} onClick={() => onMenuItemClick(user)}>
            {renderSuggestionDataItem(user)}
          </StyledMenuItem>
        ))}
      </Menu.Content>
    </Menu>
  );
}

const StyledMenuItem = styled(Menu.Item)`
  gap: 0px;
`;
