import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { RedBold, SectionContainer } from 'components/Containers';
import AttachedImage from 'components/shared/AttachedImage';
import AttachmentComponent from 'components/shared/Attachment';
import { TaggedText } from 'components/TaggedText';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import Activity from 'models/Activity';
import LocationEpisode from 'models/LocationEpisode';
import { attachmentsQueryKeys, indexAttachments } from 'services/api/attachments';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import PriorityFlag from 'svg/PriorityFlag';

type Props = {
  activity: Activity;
  locationEpisode: LocationEpisode;
};

export function NoteInnerContent(props: Props) {
  const { activity, locationEpisode } = props;
  const { isIntersecting: hasIntersected, customRef } = useIntersectionObserver({ once: true });
  const { addToast } = useToastActions();

  const queryParams = useMemo(
    () => ({
      locationEpisodeId: locationEpisode.id,
      activityId: activity.id,
      include: 'thumbnailUrl,blob',
    }),
    [activity.id, locationEpisode.id]
  );

  const { data: attachmentsdata, error: attachmentsError } = useQuery({
    queryKey: attachmentsQueryKeys.index(queryParams),
    queryFn: ({ signal }) => indexAttachments(queryParams, signal),
    enabled: !!locationEpisode?.id && !!activity?.id && hasIntersected,
  });

  useEffect(() => {
    if (attachmentsError) {
      addToast({ text: 'There was an error fetching attachments. Please try again.' });
    }
  }, [addToast, attachmentsError]);

  const imageAttachments = useMemo(
    () => (attachmentsdata?.data ?? activity.attachments ?? []).filter((attachment) => attachment.isImage),
    [activity.attachments, attachmentsdata]
  );

  const fileAttachments = useMemo(
    () => (activity.attachments ?? []).filter((attachment) => !attachment.isImage),
    [activity.attachments]
  );

  return (
    <>
      {activity.escalation?.isPriorityNote && (
        <PriorityNoteLabelContainer>
          <PriorityFlag width={14} height={14} color={colors.accentRed} />
          <RedBold>This note is marked as priority</RedBold>
        </PriorityNoteLabelContainer>
      )}
      {!!activity.data.text?.length && (
        <SectionContainer>
          <TaggedText text={activity.data.text ?? ''} $removed={activity.data.textRemoved} />
        </SectionContainer>
      )}
      {!!(imageAttachments.length || fileAttachments.length) && (
        <SectionContainer ref={customRef}>
          {!!imageAttachments.length && (
            <ImagesWrapper>
              {imageAttachments.map((item) => (
                <AttachedImage key={item.id} attachment={item} removed={activity.data.textRemoved} />
              ))}
            </ImagesWrapper>
          )}
          {!!fileAttachments.length && (
            <AttachmentWrapper>
              {fileAttachments.map((item) => (
                <AttachmentComponent disabled={activity.data.textRemoved} key={item.id} attachment={item} />
              ))}
            </AttachmentWrapper>
          )}
        </SectionContainer>
      )}
    </>
  );
}

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 0.5rem;
`;

const AttachmentWrapper = styled.div`
  width: 100%;

  & > * {
    margin-top: 0.5rem;
  }
`;

const PriorityNoteLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > svg {
    margin-right: 6px;
  }
`;
