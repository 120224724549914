import React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CollapsibleDiv = styled.div<{ height: number; $collapsed: boolean }>`
  transition: all 200ms ease-out;
  height: ${({ $collapsed, height }) => ($collapsed ? '0' : `${height}px`)};
  overflow: hidden;
  transition-property: height;
  @media not ${({ theme }) => theme.devices.desktop} {
    margin-bottom: 8px;
    margin-top: 8px;
    padding-right: 24px;
  }
`;
export function CollapsibleContent(props: {
  children: React.JSX.Element | (React.JSX.Element | null)[];
  className?: string;
  collapsed: boolean;
}) {
  const { children } = props;

  const container = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(container?.current?.scrollHeight);

  useEffect(() => {
    setHeight(container?.current?.scrollHeight);
  }, []);

  return (
    <CollapsibleDiv
      id='collapsible-content'
      height={height!}
      className={props.className}
      $collapsed={props.collapsed}
      ref={container}>
      {children}
    </CollapsibleDiv>
  );
}
