export default function ExportIcon({ width = 24, height = 24, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'-2 -3 20 20'}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M12,12 L8,8 L11,8 L11,4 L13,4 L13,8 L16,8 L12,12 Z M19,4 C19.6,4 20,4.4 20,5 L20,19 C20,19.6 19.6,20 19,20 L5,20 C4.4,20 4,19.6 4,19 L4,5 C4,4.4 4.4,4 5,4 L8,4 L8,6 L6,6 L6,14 L18,14 L18,6 L16,6 L16,4 L19,4 Z M6,18 L18,18 L18,16 L6,16 L6,18 Z'
          id='export-path'
        />
      </defs>
      <g id='Web-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Icon/Download' transform='translate(-4.000000, -4.000000)'>
          <mask id='export-mask' fill='white'>
            <use xlinkHref='#export-path' />
          </mask>
          <g id='Mask' fillRule='nonzero' />
          <g id='Mixin/Fill/Active' mask='url(#export-mask)' fill='currentColor' fillRule='evenodd'>
            <rect id='Box' x='0' y='0' width='24' height='24' />
          </g>
        </g>
      </g>
    </svg>
  );
}
