import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useProfile } from 'context/profile';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import usePrevious from 'hooks/usePrevious';
import isDeepEqual from 'lib/isDeepEqual';
import { Analytics, retrieveAnalytics } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

type Request<Result> = {
  params: any;
  processData: (data: Analytics) => Result;
};

type DataFetchConfig = {
  onIntersecting?: boolean;
};

export default function useInsightsQuery<Result = Record<string, any>>(
  request: Request<Result>,
  config: DataFetchConfig = {}
) {
  const { onIntersecting = false } = config;

  const { profile } = useProfile();
  const analyticsFilters = useInsightsStore((state) => getAnalyticsFilters(state, profile));
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const filtersChanged =
    !isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType);

  const {
    isIntersecting: hasIntersected,
    setIsIntersecting: setHasIntersected,
    customRef,
  } = useIntersectionObserver({
    initialState: !onIntersecting,
    once: true,
    threshold: 0.2,
  });

  const query = useQuery({
    queryKey: ['analytics', request, analyticsFilters],
    queryFn: () => retrieveAnalytics(request.params, analyticsFilters),
    // Don't let the query fire immediately when filters change - let the useEffect block below
    // handle resetting the intersection observer, which will then trigger the query if/when the
    // element is actually in view.
    enabled: !filtersChanged && (!onIntersecting || hasIntersected),
    select: request.processData,
  });

  useEffect(() => {
    if (filtersChanged) {
      setHasIntersected(false);
    }
  }, [filtersChanged, setHasIntersected]);

  return { customRef, loading: query.isPending || query.isRefetching, query };
}
