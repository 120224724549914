import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';

export interface ProgressUpdateOptions extends ActivityOptions {
  type: ActivityType.PROGRESS_UPDATE;
  data: Partial<
    BaseActivityDataOptions & {
      questions: AnswerType[];
    }
  >;
}

export function getDefaults(): ProgressUpdateOptions {
  const defaults = getActivityDefaults();

  return {
    ...defaults,
    type: ActivityType.PROGRESS_UPDATE,
    data: {
      ...defaults.data,
      questions: [],
    },
  };
}

export default class ProgressUpdate extends Activity {
  declare type: ActivityType.PROGRESS_UPDATE;
  declare data: BaseActivityData & { questions: AnswerType[] };

  constructor(options: Partial<ProgressUpdateOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  get typeLabel() {
    return 'Progress Update';
  }

  get isStatusUpdate() {
    return this.data.questions[0]?.kind === 'status';
  }
}
