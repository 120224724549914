import styled from 'styled-components';

import FieldLabel from 'components/shared/form/FieldLabel';
import { BodySmall } from 'styles/typography';

type Props = {
  title?: string;
  error?: string;
  disabled?: boolean;
  visible?: boolean;
  children: React.ReactNode;
  $containerWidth?: string;
};

export default function InputGroup(props: Props) {
  const { title, visible, children, error, $containerWidth, ...rest } = props;
  return (
    <InputGroupContainer $visible={visible ?? true} $width={$containerWidth} {...rest}>
      {!!title && <FieldLabel name=''>{title}</FieldLabel>}
      <div>{children}</div>
      <ErrorMessageContainer>{!!error && <RedBodySmall>{error}</RedBodySmall>}</ErrorMessageContainer>
    </InputGroupContainer>
  );
}

const InputGroupContainer = styled.div<{ $visible: boolean; $width?: string }>`
  width: ${({ $width }) => $width ?? '100%'};

  ${({ $visible }) => !$visible && `display: none;`}
`;
const ErrorMessageContainer = styled.div`
  margin-top: 6px;
  min-height: 20px;
  white-space: pre-line;
`;

const RedBodySmall = styled(BodySmall)`
  color: var(--accent-red);
  margin-bottom: 15px;
`;

export const InlineInputGroups = styled.div`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
  & > * {
    flex: 1;
    margin-right: 9px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
