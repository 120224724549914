import { useMemo } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import AsyncModal from 'components/modals/AsyncModal';
import ConfirmEscalationModal from 'components/modals/ConfirmEscalationModal';
import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import { useProfile } from 'context/profile';
import { ActivityType } from 'models/Activity';
import Escalation from 'models/Escalation';
import { EscalationTypes } from 'models/Escalation';
import LocationEpisode from 'models/LocationEpisode';
import { activityQueryKeys, createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { useToastActions } from 'stores/toastStore';

type Props = {
  locationEpisode: LocationEpisode;
};

export default function NewActivity({ locationEpisode }: Props) {
  const queryClient = useQueryClient();
  const { addToast } = useToastActions();
  const { profile } = useProfile();

  const { mutateAsync, isPending: creatingActivity } = useMutation({
    mutationFn: createActivity,
  });

  const escalationType = useMemo(() => {
    return Escalation.typeForProfile(profile);
  }, [profile]);

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput({ escalationType });

  const handleSubmit = async () => {
    if (note.escalated && escalationType === EscalationTypes.Escalation) {
      const confirmed = await new AsyncModal(ConfirmEscalationModal).render();
      if (!confirmed) return;
    }

    try {
      await mutateAsync({
        ...getSerializedNoteValues(),
        type: ActivityType.NOTE,
        locationEpisodeId: locationEpisode?.id,
      });

      queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count() });
      resetNote();
    } catch {
      addToast({ text: 'Something went wrong. Please try again.' });
    }
  };

  return (
    <ActivityInput
      escalationType={escalationType}
      taggable
      attachable
      showEscalate
      showPostButton
      submitting={creatingActivity}
      locationEpisodeId={locationEpisode?.id}
      onSubmit={handleSubmit}
      values={note}
      setValues={setNote}
      darkBorder={false}
      heightSettings={{ minHeight: '140px' }}
    />
  );
}
