import Review, {
  BaseReviewData,
  BaseReviewDataOptions,
  getDefaults as getReviewDefaults,
  parseProjectedDischarge,
  ReviewOptions,
  ReviewType,
} from 'models/Review';

type ProjectedDischargeData = {
  date: string;
  notificationSent: boolean;
};

export interface ProjectedDischargeOptions extends ReviewOptions {
  type: ReviewType.PROJECTED_DISCHARGE;
  data: Partial<ProjectedDischargeData & BaseReviewDataOptions>;
}

export function getDefaults(): ProjectedDischargeOptions {
  const defaults = getReviewDefaults();

  return {
    ...defaults,
    type: ReviewType.PROJECTED_DISCHARGE,
    data: {
      ...defaults.data,
      date: '',
      notificationSent: false,
    },
  };
}

export default class ProjectedDischarge extends Review {
  declare type: ReviewType.PROJECTED_DISCHARGE;
  declare data: ProjectedDischargeData & BaseReviewData;

  constructor(options: Partial<ProjectedDischargeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  static get type() {
    return ReviewType.PROJECTED_DISCHARGE;
  }

  get date() {
    return parseProjectedDischarge(this.data.date);
  }
}
