export default function GearIcon({ width = 24, height = 24, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Gear</title>
      <g id='Olio-UI-Guide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Page-Structure' transform='translate(-167.000000, -2238.000000)' fill='currentColor' fillRule='nonzero'>
          <g id='settings-gear' transform='translate(167.000000, 2238.000000)'>
            <path
              d='M11.6375,4.55 L12.6,2.7125 L11.375,1.4875 L9.5375,2.45 C9.275,2.275 8.925,2.1875 8.575,2.1 L7.875,0 L6.125,0 L5.425,2.0125 C5.1625,2.1 4.8125,2.1875 4.55,2.3625 L2.7125,1.4 L1.4,2.7125 L2.3625,4.55 C2.1875,4.8125 2.1,5.1625 2.0125,5.425 L0,6.125 L0,7.875 L2.0125,8.575 C2.1,8.925 2.275,9.1875 2.3625,9.5375 L1.4,11.375 L2.625,12.6 L4.4625,11.6375 C4.725,11.8125 5.075,11.9 5.425,11.9875 L6.125,14 L7.875,14 L8.575,11.9875 C8.925,11.9 9.1875,11.725 9.5375,11.6375 L11.375,12.6 L12.6,11.375 L11.6375,9.5375 C11.8125,9.275 11.9,8.925 11.9875,8.575 L14,7.875 L14,6.125 L11.9875,5.425 C11.9,5.1625 11.8125,4.8125 11.6375,4.55 Z M7,9.625 C5.5125,9.625 4.375,8.4875 4.375,7 C4.375,5.5125 5.5125,4.375 7,4.375 C8.4875,4.375 9.625,5.5125 9.625,7 C9.625,8.4875 8.4875,9.625 7,9.625 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
