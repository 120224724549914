import React from 'react';
import styled from 'styled-components';

import { H3 } from 'styles/typography';

function SectionHeader({ children, ...rest }) {
  return (
    <SectionHeaderContainer {...rest}>
      <H3>{children}</H3>
    </SectionHeaderContainer>
  );
}

export default SectionHeader;

const SectionHeaderContainer = styled.div`
  margin-bottom: 16px;
`;
