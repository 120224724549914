/**
 * @class Credential
 * @property {string} id - The credential's id
 * @property {string} description - The credential's description
 * @property {string} title - The credential's title
 */
export default class Credential {
  id: string;
  description: string;
  title: string;

  constructor(options: Partial<Credential> = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.description = opts.description;
    this.title = opts.title;
  }
}

export interface CredentialOptions {
  id: string;
  description: string;
  title: string;
}

const defaults: CredentialOptions = {
  id: '',
  description: '',
  title: '',
};
