import { PERMISSIONS } from 'models/Profile';
import { RehabStateName } from 'models/RehabState';

type Rules = {
  [State in RehabStateName]: {
    accepts: RehabStateName[];
    rejects: {
      states: RehabStateName[];
      message: string;
    }[];
    permission: {
      permission?: string;
      message?: string;
    };
  };
};

export const transitionRules: Rules = {
  [RehabStateName.Queue]: {
    accepts: [RehabStateName.Queue, RehabStateName.Admission, RehabStateName.InTreatment],
    rejects: [
      {
        states: [RehabStateName.Discharged],
        message:
          'Patient must be admitted or removed from the Queue in Olio. Contact support@olio.health for assistance.',
      },
    ],
    permission: {},
  },
  [RehabStateName.Admission]: {
    accepts: [RehabStateName.Admission, RehabStateName.InTreatment, RehabStateName.Discharged],
    rejects: [
      {
        states: [RehabStateName.Queue],
        message:
          'Admitted patients cannot be moved back to Queue. If you need assistance, please contact support@olio.health.',
      },
    ],
    permission: {
      permission: PERMISSIONS.rehabStateAdmissionCreate,
      message:
        'You currently do not have permission to admit patients in Olio. Contact support@olio.health with questions. ',
    },
  },
  [RehabStateName.InTreatment]: {
    accepts: [RehabStateName.InTreatment, RehabStateName.Discharged],
    rejects: [
      {
        states: [RehabStateName.Queue, RehabStateName.Admission],
        message: 'A patient cannot be moved back to Admission or Queue after completing their first treatment.',
      },
    ],
    permission: {
      permission: PERMISSIONS.activityProgressUpdateCreate,
      message:
        'You do not have permission to move a patient to In Treatment. Contact support@olio.health for assistance.',
    },
  },
  [RehabStateName.Discharged]: {
    accepts: [RehabStateName.Discharged],
    rejects: [
      {
        states: [RehabStateName.Queue, RehabStateName.Admission, RehabStateName.InTreatment],
        message:
          'Discharged Patients cannot be moved back to Queue, Admission or In Treatment. If you need assistance, please contact support@olio.health',
      },
    ],
    permission: {
      permission: PERMISSIONS.rehabStateDischargeCreate,
      message:
        'You currently do not have permission to discharge patients in Olio. Contact support@olio.health with questions. ',
    },
  },
};
