import { useState } from 'react';
import styled from 'styled-components';

import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, formatDuration, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { useInsightsStore } from 'stores/insightsStore';
import colors from 'styles/theme/colors';
import PriorityCountIcon from 'svg/PriorityCountIcon';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { DataRow, getRollup, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import MetricTile from './MetricTile';

type ChartData = {
  [date: string]: {
    responseTime: number;
    prioritiesForDate: number;
  };
};

export default function PriorityNoteResponseTimeTile() {
  const filters = useInsightsStore((state) => state.filters);

  const [averageResponseTime, setAverageResponseTime] = useState(-1);
  const [totalPriorityNotes, setTotalPriorityNotes] = useState(-1);
  const [chartData, setChartData] = useState<ChartData>({});
  const request = {
    params: {
      source: SourceConstants.ESCALATION_ACKS,
      dimensions: [DimensionConstants.DATE],
      metrics: [MetricConstants.BUSINESS_RESPONSE_TIME, MetricConstants.ID_COUNT],
      rollups: true,
      escalationType: 'priority_note',
    },

    processData: (data: DataRow[]) => {
      const [responseTimeAverage, totalPriorityNotes] = getRollup(data);

      setAverageResponseTime(responseTimeAverage);
      setTotalPriorityNotes(totalPriorityNotes);

      const parsedData = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
        (acc, formattedDate) => {
          acc[formattedDate] = { responseTime: 0, prioritiesForDate: 0 };
          return acc;
        },
        {}
      );

      data.forEach((row) => {
        const dimensionValues = parseDimensionValues(row, false) as string[];
        const metricValues = parseMetricValues(row);

        const date = dimensionValues[0];
        const responseTime = metricValues[0];
        const prioritiesForDate = metricValues[1];

        if (date) {
          parsedData[date] = { responseTime, prioritiesForDate };
        }
      });

      setChartData(parsedData);
    },
  };

  const { loading, customRef } = useDataFetch([request], { onIntersecting: true });

  return (
    <MetricTile
      ref={customRef}
      label='Priority Note Response Time'
      showValue={true}
      loading={loading}
      hasData={
        averageResponseTime !== -1 &&
        Object.values(chartData)
          .map((data) => data.responseTime)
          .some((x) => x > 0)
      }
      value={`${formatDuration(averageResponseTime, true)}`}
      navigateTo={RouteConstants.PRIORITIES_NOTE_RESPONSE}>
      <AreaChart
        formatTooltipSupertext={(x) => `${formatDateLabel(x as string)}`}
        formatTooltipSubtext={(y, key) =>
          `<div style="padding-top: 4px;">
            <span style="font-size: 12px;">Average Response Time: ${y ? formatDuration(y, true) : 'N/A'}</span></div>
            <div>
            <span style="font-size: 12px;">Priorities: ${chartData[key].prioritiesForDate}</span></div>`
        }
        categories={Object.keys(chartData)}
        values={Object.values(chartData).map((data) => data.responseTime)}
        config={{
          yAxis: {
            tickAmount: 3,
            labels: {
              formatter: function (this) {
                return formatDuration(this.value);
              },
            },
          },
          xAxis: {
            labels: {
              formatter: function (this) {
                return formatDateLabel(this.value as string);
              },
            },
          },
        }}
      />
      <PriorityNoteCountContainer>
        <Left>
          <PriorityCountIcon />
          <p>Priorities</p>
        </Left>
        <div>
          <p>{totalPriorityNotes}</p>
        </div>
      </PriorityNoteCountContainer>
    </MetricTile>
  );
}

const PriorityNoteCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 8px;
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  padding: 12px 16px;

  div {
    display: flex;

    p {
      font-size: 10px;
      font-weight: bold;
    }
  }
`;

const Left = styled.div`
  p {
    margin-left: 8px;
  }
`;
