import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  color?: 'green' | 'red';
}

export default function Badge(props: Props) {
  const { color, ...rest } = props;

  return <ColoredBadge data-cy='badge' color={color} {...rest} />;
}

function getColors(color: Props['color'] = 'green') {
  const colorMap = {
    green: {
      backgroundColor: 'rgba(46,163,16,0.1)',
      color: '#2EA310',
    },
    red: {
      backgroundColor: 'rgba(209,13,68,0.1)',
      color: '#DC2853',
    },
  };

  return colorMap[color];
}

const ColoredBadge = styled.span<{ color: Props['color'] }>`
  padding: 2px 12px;
  box-sizing: border-box;
  border-radius: 24px;
  color: ${({ color }) => getColors(color).color};
  background-color: ${({ color }) => getColors(color).backgroundColor};
  font-size: 10px;
`;
