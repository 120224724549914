export default function ChartIconLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none' {...props}>
      <path
        d='M21.5 39.2975L22.9787 40.12L30.0612 25.9563L52.0125 42.135C52.2126 42.2826 52.4401 42.3888 52.6817 42.4476C52.9233 42.5064 53.1742 42.5166 53.4198 42.4775C53.6653 42.4384 53.9007 42.3509 54.1121 42.22C54.3235 42.0891 54.5068 41.9174 54.6512 41.715L77.6162 9.56379L74.5637 7.38379L52.705 37.9875L30.4875 21.615C30.2674 21.4528 30.0145 21.3407 29.7466 21.2864C29.4786 21.2322 29.202 21.2373 28.9362 21.3012C28.6704 21.3652 28.4218 21.4865 28.2078 21.6567C27.9938 21.8268 27.8196 22.0417 27.6975 22.2863L19.46 38.7613C20.1735 38.7715 20.8737 38.9556 21.5 39.2975Z'
        fill='currentColor'
        fillOpacity='0.25'
      />
      <path
        d='M18.2412 47.5H15.0324L2.4762 72.0662L5.8162 73.7738L19.0224 47.9338L18.2412 47.5Z'
        fill='currentColor'
        fillOpacity='0.25'
      />
      <path
        d='M53.125 63.75C52.8063 63.7501 52.4927 63.6692 52.2137 63.515L18.8888 45H2.5V41.25H19.375C19.6937 41.2501 20.0072 41.331 20.2863 41.485L53 59.6613L75.4375 43.6338L77.6175 46.6862L54.215 63.4013C53.8969 63.6283 53.5158 63.7502 53.125 63.75Z'
        fill='currentColor'
        fillOpacity='0.25'
      />
    </svg>
  );
}
