import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { UserPreferenceType } from 'models/UserPreference';
import { InsightsFilterValue } from 'models/userPreferences/InsightsFilter';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import http from 'services/http';
import { PortfolioFilterState } from 'stores/portfolioStore';

type InsightsFilterUpsertParams = {
  value: InsightsFilterValue;
  clientId: string;
};

export async function upsertInsightsFilter(params: InsightsFilterUpsertParams) {
  return http.put<void>(`me/preferences/insights_filter${params.clientId ? `/${params.clientId}` : ''}`, {
    type: UserPreferenceType.INSIGHTS_FILTER,
    ...params,
  });
}

type PortfolioFilterUpsertParams = {
  value: PortfolioFilterValue;
  clientId: string;
};

export async function upsertPortfolioFilter(params: PortfolioFilterUpsertParams) {
  const { sorts, providerType, ...filters } = params.value;

  return http.put<void>(`me/preferences/portfolio_filter${params.clientId ? `/${params.clientId}` : ''}`, {
    type: UserPreferenceType.PORTFOLIO_FILTER,
    value: {
      ...serializeFilters(filters),
      sorts,
      providerType,
    },
  });
}

const serializeFilters = (filters: PortfolioFilterState) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if ((value instanceof FilterValueArray && !value.length) || (value instanceof FilterValueBoolean && !value.value)) {
      return acc;
    }

    acc[key] = value.serialize();

    return acc;
  }, {});
};
