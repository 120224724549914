import styled from 'styled-components';

import Bread from 'components/shared/Bread.tsx';
import { useToastStore } from 'stores/toastStore';

const Toaster = () => {
  const toasts = useToastStore((state) => state.toasts);

  return (
    <HeatingElement>
      {toasts.map((toast) => (
        <Bread text={toast.text} id={toast.id} key={toast.id} />
      ))}
    </HeatingElement>
  );
};

export default Toaster;

const HeatingElement = styled.ul`
  position: absolute;
  z-index: 999;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
  list-style: none;
  width: 60%;
`;
