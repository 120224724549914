export default function PatientAuthNotAuthorizedIcon({
  width = 40,
  height = 40,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='40' height='40' rx='3' fill='#0F1226' fillOpacity='0.05' />
      <path
        d='M26.5829 19.1672C25.5116 19.1672 24.4644 19.4849 23.5737 20.08C22.683 20.6752 21.9888 21.5211 21.5788 22.5108C21.1689 23.5006 21.0616 24.5896 21.2706 25.6403C21.4796 26.691 21.9954 27.6561 22.7529 28.4136C23.5104 29.1711 24.4755 29.687 25.5262 29.896C26.5769 30.1049 27.666 29.9977 28.6557 29.5877C29.6454 29.1778 30.4913 28.4835 31.0865 27.5928C31.6817 26.7021 31.9993 25.6549 31.9993 24.5836C31.9971 23.1478 31.4257 21.7714 30.4105 20.7561C29.3952 19.7408 28.0188 19.1694 26.5829 19.1672ZM28.6661 25.2086H24.4997C24.3339 25.2086 24.175 25.1427 24.0578 25.0255C23.9406 24.9083 23.8747 24.7494 23.8747 24.5836C23.8747 24.4179 23.9406 24.2589 24.0578 24.1417C24.175 24.0245 24.3339 23.9586 24.4997 23.9586H28.6661C28.8319 23.9586 28.9909 24.0245 29.1081 24.1417C29.2253 24.2589 29.2911 24.4179 29.2911 24.5836C29.2911 24.7494 29.2253 24.9083 29.1081 25.0255C28.9909 25.1427 28.8319 25.2086 28.6661 25.2086Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M16.1665 18.5422H21.5829C21.7486 18.5422 21.9076 18.4764 22.0248 18.3592C22.142 18.242 22.2078 18.083 22.2078 17.9173C22.2078 17.7515 22.142 17.5925 22.0248 17.4753C21.9076 17.3581 21.7486 17.2923 21.5829 17.2923H16.1665C16.0007 17.2923 15.8418 17.3581 15.7246 17.4753C15.6073 17.5925 15.5415 17.7515 15.5415 17.9173C15.5415 18.083 15.6073 18.242 15.7246 18.3592C15.8418 18.4764 16.0007 18.5422 16.1665 18.5422Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M16.1665 21.4587H18.6664C18.8321 21.4587 18.9911 21.3929 19.1083 21.2757C19.2255 21.1585 19.2913 20.9995 19.2913 20.8338C19.2913 20.668 19.2255 20.5091 19.1083 20.3919C18.9911 20.2746 18.8321 20.2088 18.6664 20.2088H16.1665C16.0007 20.2088 15.8418 20.2746 15.7246 20.3919C15.6073 20.5091 15.5415 20.668 15.5415 20.8338C15.5415 20.9995 15.6073 21.1585 15.7246 21.2757C15.8418 21.3929 16.0007 21.4587 16.1665 21.4587Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M16.1665 24.3753H18.458C18.6238 24.3753 18.7827 24.3094 18.8999 24.1922C19.0172 24.075 19.083 23.9161 19.083 23.7503C19.083 23.5846 19.0172 23.4256 18.8999 23.3084C18.7827 23.1912 18.6238 23.1253 18.458 23.1253H16.1665C16.0007 23.1253 15.8418 23.1912 15.7246 23.3084C15.6073 23.4256 15.5415 23.5846 15.5415 23.7503C15.5415 23.9161 15.6073 24.075 15.7246 24.1922C15.8418 24.3094 16.0007 24.3753 16.1665 24.3753Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M16.3748 15.209H23.4578C23.5131 15.209 23.5661 15.1871 23.6051 15.148C23.6442 15.1089 23.6661 15.056 23.6661 15.0007V12.5008C23.6661 12.4456 23.6442 12.3926 23.6051 12.3535C23.5661 12.3144 23.5131 12.2925 23.4578 12.2925H21.9996V12.0842C21.9984 11.7625 21.9231 11.4454 21.7794 11.1576C21.6358 10.8698 21.4277 10.619 21.1713 10.4247C20.9149 10.2304 20.6172 10.0978 20.3013 10.0373C19.9853 9.97685 19.6597 9.99006 19.3497 10.0759C18.9043 10.2103 18.5151 10.4866 18.2413 10.8627C17.9675 11.2388 17.8242 11.6941 17.8331 12.1592V12.2925H16.3748C16.3196 12.2925 16.2666 12.3144 16.2275 12.3535C16.1885 12.3926 16.1665 12.4456 16.1665 12.5008V15.0007C16.1665 15.056 16.1885 15.1089 16.2275 15.148C16.2666 15.1871 16.3196 15.209 16.3748 15.209ZM19.9163 11.2509C20.0399 11.2509 20.1608 11.2875 20.2635 11.3562C20.3663 11.4249 20.4464 11.5225 20.4937 11.6367C20.541 11.7509 20.5534 11.8765 20.5293 11.9978C20.5052 12.119 20.4456 12.2304 20.3582 12.3178C20.2708 12.4052 20.1595 12.4647 20.0382 12.4888C19.917 12.5129 19.7914 12.5005 19.6772 12.4532C19.563 12.4059 19.4654 12.3258 19.3967 12.2231C19.328 12.1203 19.2914 11.9995 19.2914 11.8758C19.2935 11.7108 19.36 11.553 19.4768 11.4363C19.5935 11.3196 19.7512 11.253 19.9163 11.2509Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M21.1662 28.4168C21.1464 28.3914 21.1211 28.3707 21.0923 28.3563C21.0635 28.3419 21.0318 28.3341 20.9996 28.3334H14.0832C13.9727 28.3334 13.8668 28.2895 13.7886 28.2114C13.7105 28.1333 13.6666 28.0273 13.6666 27.9168V14.3757C13.6666 14.2652 13.7105 14.1593 13.7886 14.0811C13.8668 14.003 13.9727 13.9591 14.0832 13.9591H14.7082C14.7635 13.9591 14.8164 13.9371 14.8555 13.8981C14.8946 13.859 14.9165 13.806 14.9165 13.7508V12.5008C14.9165 12.4456 14.8946 12.3926 14.8555 12.3535C14.8164 12.3144 14.7635 12.2925 14.7082 12.2925H13.6666C13.2246 12.2925 12.8007 12.4681 12.4881 12.7806C12.1756 13.0932 12 13.5171 12 13.9591V28.3334C12 28.7754 12.1756 29.1993 12.4881 29.5119C12.8007 29.8244 13.2246 30 13.6666 30H22.0995C22.142 29.999 22.1834 29.9859 22.2188 29.9623C22.2542 29.9387 22.2822 29.9056 22.2995 29.8667C22.3151 29.8265 22.3179 29.7826 22.3074 29.7408C22.297 29.699 22.2738 29.6615 22.2412 29.6334C21.8346 29.2731 21.4736 28.8646 21.1662 28.4168Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
      <path
        d='M25.1243 13.9591H25.7493C25.8598 13.9591 25.9658 14.003 26.0439 14.0811C26.1221 14.1593 26.166 14.2652 26.166 14.3757V17.7089C26.1663 17.7371 26.1724 17.765 26.1839 17.7908C26.1954 17.8165 26.2119 17.8397 26.2326 17.8589C26.2733 17.8969 26.327 17.9178 26.3826 17.9172H26.5826C26.9205 17.9153 27.2579 17.9432 27.5909 18.0006C27.6206 18.0053 27.6511 18.0033 27.6799 17.9946C27.7088 17.986 27.7353 17.9709 27.7575 17.9506C27.7808 17.9312 27.7995 17.907 27.8125 17.8797C27.8254 17.8523 27.8323 17.8225 27.8325 17.7922V13.9591C27.8325 13.5171 27.657 13.0932 27.3444 12.7806C27.0319 12.4681 26.608 12.2925 26.166 12.2925H25.1243C25.0691 12.2925 25.0161 12.3144 24.977 12.3535C24.938 12.3926 24.916 12.4456 24.916 12.5008V13.7508C24.916 13.806 24.938 13.859 24.977 13.8981C25.0161 13.9371 25.0691 13.9591 25.1243 13.9591Z'
        fill='#0F1226'
        fillOpacity='0.5'
      />
    </svg>
  );
}
