import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import Page from 'components/layouts/page/Page';
import FilterChips from 'components/shared/FilterChips';
import FilterBar from 'components/shared/filters/FilterBar';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import ControlledTable from 'components/shared/table/ControlledTable';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import { ClientType } from 'models/Client';
import { importedPatientsIndex } from 'services/api/importedPatients';
import { serializeSort, useConnectPatientsActions, useConnectPatientsStore } from 'stores/patientFiltersStore';
import { BodySmall } from 'styles/typography';

import { getFilterSections } from './helpers/filters';
import connectPatientsTableColumns from './connectPatientsTableColumns';

function ConnectPatientsPage() {
  const isMobile = useIsMobile();
  const { profile } = useProfile();
  const navigate = useNavigate();

  const sort = useConnectPatientsStore((state) => state.sort);
  const filters = useConnectPatientsStore((state) => state.filters);
  const search = useConnectPatientsStore((state) => state.search);
  const { removeFilter, setFilters, setSearch, setSort } = useConnectPatientsActions();

  const pagination = usePagination();
  const patientCount = useRef(0);

  useEffect(() => () => setSearch(''), [setSearch]);

  const filterSections = useMemo(
    () => getFilterSections(profile?.actingClient?.clientType ?? ''),
    [profile?.actingClient?.clientType]
  );

  const { locationTitle } = useMemo(() => {
    const clientTypeMap = {
      [ClientType.PAYOR]: { locationTitle: 'Payer' },
      [ClientType.PHYSICIAN_GROUP]: { locationTitle: 'Physician Group' },
      [ClientType.HEALTH_SYSTEM]: { locationTitle: 'Hospital' },
    };

    return profile.clientType ? clientTypeMap[profile.clientType] : clientTypeMap[ClientType.HEALTH_SYSTEM];
  }, [profile.clientType]);

  const handleConnectClick = useCallback((id: string) => navigate(`/connect-patients/${id}`), [navigate]);

  const columns = useMemo(
    () =>
      connectPatientsTableColumns({
        locationTitle,
        onConnectClick: handleConnectClick,
        userClientType: profile.clientType,
        showConnect: profile.canCreatePatient,
      }),
    [handleConnectClick, locationTitle, profile.canCreatePatient, profile.clientType]
  );

  const handleRemoveFilter = useCallback((filterKey: string, id: string) => {
    removeFilter(filterKey, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyFilters = useCallback(
    ({ search, ...filters }) => {
      setFilters(filters);
      setSearch(search);

      if (search) {
        setSort([{ id: 'search', desc: true }]);
      } else {
        setSort([{ id: 'patientName', desc: false }]);
      }
    },
    [setFilters, setSort, setSearch]
  );

  const queryParams = useMemo(() => {
    return {
      ...pagination.paging,
      sortBy: serializeSort(sort),
      search,
      filters,
      include: 'owner',
    };
  }, [pagination.paging, search, filters, sort]);

  const { data: patients, isLoading } = useQuery({
    queryKey: ['importedPatients', { ...queryParams }],
    queryFn: ({ signal }) => importedPatientsIndex(queryParams, signal),
    select: (data) => {
      patientCount.current = data.meta.totalRecords;
      return data;
    },
  });

  useEffect(() => {
    pagination.setPageIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filters]);

  return (
    <Page>
      <FilterBar
        applyFilters={handleApplyFilters}
        filters={filters}
        filterSections={filterSections}
        queryFn={importedPatientsIndex}
        onClearFilters={() => setFilters({})}
        title='Connect Patients'
      />
      <StyledPageSubHeader>
        <DesktopOnly>
          <FilterChips filters={filters} onRemoveFilter={handleRemoveFilter} />
        </DesktopOnly>
        <BodySmall>{patientCount.current} patients found</BodySmall>
      </StyledPageSubHeader>
      <Page.Content>
        <ControlledTable
          data={patients?.data ?? []}
          filters={filters}
          columns={columns}
          loading={isLoading}
          pageCount={patients?.meta.totalPages ?? 1}
          pagination={pagination}
          sorting={{ sortBy: sort, setSortBy: setSort }}
          state={{
            columnVisibility: { dateOfBirth: !isMobile, ownerName: !isMobile, hospitalAdmissionDate: !isMobile },
          }}
        />
      </Page.Content>
    </Page>
  );
}

export default ConnectPatientsPage;

const StyledPageSubHeader = styled(Page.SubHeader)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 18px;
  }
`;
