import styled from 'styled-components';

import Card from '../Card';

const TableContainer = styled(Card)`
  min-height: 200px;
  max-height: 540px;
  max-width: 1280px;
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;
`;

export default TableContainer;
