import colors from 'styles/theme/colors';
import devices from 'styles/theme/devices';
import dimensions from 'styles/theme/dimensions';
import textStyles from 'styles/theme/textStyles';

export const theme = {
  colors,
  dimensions,
  textStyles,
  devices,
};

export default theme;
