import React from 'react';

/**
 * The `Slot` component allows us to merge the behavior and props passed into the Slot component directly into the children.
 *
 * For example:
 *
 * ```tsx
 * function Toggler(props: React.HTMLAttributes<HTMLButtonElement>) {
 *   const toggle = () => {
 *     console.log('clicked!');
 *   };
 *
 *   return (
 *     <Slot onClick={ toggle } { ...props } />
 *   );
 * }
 * ```
 *
 * Because an onClick handler is passed into the Slot, the
 * button will inherit it because the button is the child.
 *
 * ```tsx
 * <Toggler>
 *  <button>click me</button>
 * </Toggler>
 * ```
 */

type SlotProps = React.HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode;
};

const Slot = React.forwardRef<HTMLElement, SlotProps>((props, forwardedRef) => {
  const { children, ...rest } = props;

  if (React.Children.count(children) > 1) {
    React.Children.only(null);
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...rest,
      ...children.props,
      ref: forwardedRef,
    });
  }

  return null;
});

export default Slot;
