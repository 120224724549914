import { create, StoreApi, UseBoundStore } from 'zustand';

import { SortingState } from '@tanstack/react-table';

import FilterValueArray from 'models/filterValues/FilterValueArray';

import { FilterState } from './types';

export type PatientFilterState = FilterState<FilterValueArray>;

type Actions = {
  setSearch: (search: string) => void;
  setSort: (sort: SortingState) => void;
  setFilters: (filters: PatientFilterState) => void;
  removeFilter: (filterKey: string, id: string) => void;
  reset: () => void;
};

type State = {
  search: string;
  sort: SortingState;
  filters: PatientFilterState;
  actions: Actions;
};

const createPatientFiltersStore = (): [useStore: UseBoundStore<StoreApi<State>>, useActions: () => Actions] => {
  const useStore = create<State>((set) => ({
    search: '',
    sort: [{ id: 'patientName', desc: false }],
    filters: {},
    actions: {
      setSearch: (search) => set({ search }),
      setFilters: (filters) => set({ filters }),
      setSort: (sort) => set({ sort }),
      removeFilter: (filterKey, id) =>
        set((state) => ({
          filters: {
            ...state.filters,
            ...(state.filters[filterKey] ? { [filterKey]: state.filters[filterKey].removeFilter(id) } : {}),
          },
        })),
      reset: () => set({ search: '', filters: {} }),
    },
  }));

  const useActions = () => useStore((state) => state.actions);

  return [useStore, useActions];
};

export const [useConnectPatientsStore, useConnectPatientsActions] = createPatientFiltersStore();

export const [useAllPatientsStore, useAllPatientsActions] = createPatientFiltersStore();

export const serializeSort = (sortBy: SortingState) =>
  sortBy
    .map(({ id, desc }) => {
      let sortTerm = `${id} ${desc ? 'desc' : 'asc'}`;

      if (id === 'lengthOfStay') {
        sortTerm = 'trackLos desc,' + sortTerm;
      }

      return sortTerm;
    })
    .join(',');
