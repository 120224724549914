export enum UserPreferenceType {
  NOTIFICATION = 'notification',
  INSIGHTS_FILTER = 'insights_filter',
  PORTFOLIO_FILTER = 'portfolio_filter',
}

export interface UserPreferenceOptions {
  id: string;
  clientId: string;
  type: UserPreferenceType;
  // Models inheriting from this class should define the shape of `value`
  value: Record<string, any>;
}

export function getDefaults(): UserPreferenceOptions {
  return {
    id: '',
    clientId: '',
    type: UserPreferenceType.PORTFOLIO_FILTER,
    value: {},
  };
}

export default class UserPreference {
  id: string;
  clientId: string;
  type: UserPreferenceType;
  // Models inherting from this class should define the shape of `value`
  value: Record<string, any>;

  constructor(options: Partial<UserPreferenceOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.clientId = opts.clientId;
    this.type = opts.type;
    this.value = opts.value;
  }
}
