import LocationEpisode, { LocationEpisodeOptions } from 'models/LocationEpisode';
import { RehabStateName } from 'models/RehabState';
import http from 'services/http';

type CreateParams = {
  locationEpisodeId: string;
  states: {
    rehabState: RehabStateName;
    enteredAt: string;
  }[];
};
export async function createDateChanges(params: CreateParams) {
  return http
    .post<LocationEpisodeOptions>(`/location_episodes/${params.locationEpisodeId}/date_changes`, params)
    .then((res) => new LocationEpisode(res.data));
}
