import { create } from 'zustand';

import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';

import { FilterState } from './types';

export type PortfolioFilterState = FilterState<FilterValueArray | FilterValueBoolean>;

export type PortfolioSorts = Record<string, PortfolioSortValue>;

export type PortfolioSortValue = {
  key: string;
  attributeName: string;
  rehabStateApiName: string;
  direction: 'asc' | 'desc';
};

type Actions = {
  setSelectedTab: (selectedTab: string) => void;
  setSearch: (value: string) => void;
  setFilter: (filterKey: string, values: FilterValueArray | FilterValueBoolean) => void;
  setFilters: (filters: PortfolioFilterState) => void;
  removeFilter: (filterKey: string, id: string) => void;
  setSorts: (sorts: PortfolioSorts) => void;
  setSort: (sortKey: string, value: PortfolioSortValue) => void;
  reset: () => void;
};

type State = {
  selectedTab: string;
  search: string;
  filters: PortfolioFilterState;
  sorts: PortfolioSorts;
  actions: Actions;
};

export const usePortfolioStore = create<State>((set) => ({
  selectedTab: '',
  search: '',
  filters: {},
  sorts: {},
  actions: {
    setSelectedTab: (selectedTab) => set({ selectedTab }),
    setSearch: (value) => set({ search: value }),
    setFilter: (filterKey, values) =>
      set((state) => ({
        filters: {
          ...state.filters,
          [filterKey]: values,
        },
      })),
    setFilters: (filters) => set({ filters }),
    removeFilter: (filterKey, id) =>
      set((state) => ({
        filters: {
          ...state.filters,
          ...(state.filters[filterKey] ? { [filterKey]: state.filters[filterKey].removeFilter(id) } : {}),
        },
      })),
    setSorts: (sorts) => set({ sorts }),
    setSort: (sortKey, value) =>
      set((state) => ({
        sorts: {
          ...state.sorts,
          [sortKey]: value,
        },
      })),
    reset: () => set({ search: '', filters: {}, sorts: {}, selectedTab: '' }),
  },
}));

export const usePortfolioActions = () => usePortfolioStore((state) => state.actions);
