export default function NoteIcon({ width = 24, height = 24, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' {...props}>
      <g fill='currentColor' fillRule='nonzero' opacity='1'>
        <path d='M15 0H2a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V8h-7a1 1 0 0 1-1-1V0zM5 17h14v2H5v-2zm0-5h14v2H5v-2zm6-3H5V7h6v2z' />
        <path d='M22.414 6H17V.586z' />
      </g>
    </svg>
  );
}
