import React from 'react';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { filterKeysToWords } from 'constants/filterKeysConstants';
import { humanize } from 'lib/util';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { InsightsFilterState } from 'stores/insightsStore';
import { PatientFilterState } from 'stores/patientFiltersStore';
import { PortfolioFilterState } from 'stores/portfolioStore';
import { FilterOption } from 'stores/types';
import { colors } from 'styles/theme/colors';
import XIcon from 'svg/XIcon';

import { getFilterCount } from './filters/helpers';

type Props = {
  filters: InsightsFilterState | PatientFilterState | PortfolioFilterState;
  onRemoveFilter: (filterKey: string, id: string) => void;
};

function getChipLabel(filterKey: string, value: FilterOption | FilterValueBoolean) {
  if (value instanceof FilterValueBoolean) {
    return value.label;
  }

  if (value.id === 'null') {
    return `No ${filterKeysToWords[filterKey] ?? capitalize(humanize(filterKey))} Assigned`;
  }

  return value.name;
}

export default function FilterChips(props: Props) {
  const { filters, onRemoveFilter } = props;

  const totalFilters = getFilterCount(filters);

  if (!totalFilters) {
    return <></>;
  }

  const getChips = (filterKey: string, value: FilterValueArray | FilterValueBoolean) => {
    const chips: React.JSX.Element[] = [];
    if (value instanceof FilterValueBoolean && value.value) {
      chips.push(
        <Chip key={value.name}>
          {getChipLabel(filterKey, value)}
          <div onClick={() => onRemoveFilter(filterKey, '')}>
            <XIcon color={colors.black} height={8} width={8} />
          </div>
        </Chip>
      );
    } else if (value instanceof FilterValueArray) {
      value.forEach((val) => {
        chips.push(
          <Chip key={val.id}>
            {getChipLabel(filterKey, val)}
            <div onClick={() => onRemoveFilter(filterKey, val.id as string)}>
              <XIcon color={colors.black} height={8} width={8} />
            </div>
          </Chip>
        );
      });
    }

    return chips;
  };

  return <ChipBag>{Object.entries(filters).map(([filterKey, values]) => getChips(filterKey, values))}</ChipBag>;
}

const ChipBag = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Chip = styled.div`
  background-color: var(--black-10);
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  padding: 4px 12px;

  font-size: 12px;
  font-weight: bold;
  color: var(--black);
  line-height: 16px;
  div {
    cursor: pointer;
  }
`;
