import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';
import User, { UserOptions } from 'models/User';

export interface AcknowledgementOptions extends ActivityOptions {
  type: ActivityType.ACKNOWLEDGEMENT;
  data: Partial<
    BaseActivityDataOptions & {
      acknowledgedBy: Partial<UserOptions>;
      activityType: string;
    }
  >;
}

export function getDefaults(): AcknowledgementOptions {
  const activityDefaults = getActivityDefaults();
  return {
    ...activityDefaults,
    type: ActivityType.ACKNOWLEDGEMENT,
    data: {
      ...activityDefaults.data,
      acknowledgedBy: {},
      activityType: '',
    },
  };
}

export default class Acknowledgement extends Activity {
  declare type: ActivityType.ACKNOWLEDGEMENT;
  declare data: BaseActivityData & { acknowledgedBy: User; activityType: string };

  constructor(options: Partial<AcknowledgementOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.data = {
      ...this.data,
      acknowledgedBy: new User(opts.data.acknowledgedBy),
      activityType: opts.data.activityType ?? '',
    };
  }
}
