import useIsMobile from 'hooks/useIsMobile';

export default function MobileOnly({ children }: { children: React.ReactNode }) {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return null;
  }

  return <>{children}</>;
}
