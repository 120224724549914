export default function ThumbsDownIcon({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <title>thumb-down</title>
      <g id='update-progress-(stoplight)' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Form' transform='translate(-637.000000, -288.000000)' fill='currentColor' fillRule='nonzero'>
          <g id='Group-18' transform='translate(290.000000, 108.000000)'>
            <g id='Group-31' transform='translate(52.000000, 132.000000)'>
              <g id='Group-33' transform='translate(0.500000, 35.000000)'>
                <g id='thumb-down' transform='translate(294.500000, 13.000000)'>
                  <path
                    d='M2.66666667,8.66666667 L0.666666667,8.66666667 C0.298476833,8.66666667 0,8.36818983 0,8 L0,0.666666667 C0,0.298476833 0.298476833,0 0.666666667,0 L2.66666667,0 L2.66666667,8.66666667 Z'
                    id='ThumbsDown'
                  />
                  <path
                    d='M15.026,9.07 C14.5188526,9.66094964 13.7787294,10.0006903 13,10 L8.66666667,10 L8.66666667,13.3333333 C8.66666667,14.804 8.13733333,16 6.66666667,16 C6.36595193,16 6.1024213,15.798795 6.02333333,15.5086667 L4,8.66666667 L4,-2.91920437e-05 L12.284,-2.91920437e-05 C13.6025755,-0.00614066715 14.72549,0.957168893 14.92,2.26133333 L15.6373333,6.928 C15.755805,7.6969945 15.5325106,8.47937734 15.026,9.07 Z'
                    id='ThumbsDown'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
