import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import {
  DimensionConstants,
  MetricConstants,
  RouteConstants,
  SortOrder,
  SourceConstants,
} from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import Card from 'components/insights/layout/Card';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import {
  ActivitiesPerPatientPerWeekData,
  ActivitiesPerPatientPerWeekTableRow,
  generateExtendedChartConfig,
} from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import LocalExport from 'components/insights/layout/details/LocalExport';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import { DataRow, parseDimensionValues, parseMetricValues } from 'components/insights/layout/helpers/dataUtils';
import useDataFetch from 'components/insights/layout/helpers/useDataFetch';
import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

const ActivitiesPerPatientWeekDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const [chartData, setChartData] = useState<ActivitiesPerPatientPerWeekData>({
    categories: [],
    values: [],
    average: 0,
    patients: [],
    activities: [],
  });

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );
  const [totalPatients, setTotalPatients] = useState(-1);
  const [totalActivities, setTotalActivities] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [selectedDimension],
        metrics: [
          MetricConstants.ACTIVITIES_PER_PATIENT_PER_WEEK,
          MetricConstants.LOCATION_EPISODE_ID_COUNT,
          MetricConstants.OWNER_ACTIVITIES_SUM,
        ],
        sortBy: `${MetricConstants.LOCATION_EPISODE_ID_COUNT} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const activitiesPerPatientPerWeek = metricValues[0];
            const patients = metricValues[1];
            const activities = metricValues[2];

            if (row.grouping === 1) {
              acc.average = activitiesPerPatientPerWeek;
              acc.totalPatients = patients;
              acc.totalActivities = activities;
            } else {
              acc.categories.push(groupName || 'None');
              acc.values.push(activitiesPerPatientPerWeek);
              acc.activities.push(activities);
              acc.patients.push(patients);
            }

            return acc;
          },
          {
            categories: [],
            values: [],
            average: 0,
            patients: [],
            activities: [],
            totalPatients: 0,
            totalActivities: 0,
          } as {
            categories: string[];
            values: number[];
            average: number;
            patients: number[];
            activities: number[];
            totalPatients: number;
            totalActivities: number;
          }
        );

        const { totalPatients, totalActivities, ...chartData } = parsedData;

        setChartData(chartData);
        setTotalPatients(totalPatients);
        setTotalActivities(totalActivities);

        return { ...parsedData };
      },
    }),
    [selectedDimension]
  );
  const { loading } = useDataFetch([request]);
  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);
  const getAverageString = () => {
    if (chartData.average < 0) return;

    return `${chartData.average.toFixed(1)}`;
  };

  const tableData = useMemo<ActivitiesPerPatientPerWeekTableRow[]>(() => {
    const { categories, values, patients, activities } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      activitiesPerPatientPerWeek: values[i],
      totalPatients: patients[i],
      totalActivities: activities[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<ActivitiesPerPatientPerWeekTableRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('activitiesPerPatientPerWeek', {
        header: 'Activities Per Patient Per Week',
        cell: (data) => `${data.getValue().toFixed(1)}`,
        footer: () => `${chartData.average.toFixed(1)} (Avg)`,
      }),
      columnHelper.accessor('totalActivities', {
        header: 'Activities',
        cell: (data) => `${data.getValue()}`,
        footer: () => `${totalActivities} (Total)`,
      }),
      columnHelper.accessor('totalPatients', {
        header: 'Patients',
        cell: (data) => `${data.getValue()}`,
        footer: () => `${totalPatients} (Total)`,
      }),
    ],
    [chartData.average, columnHelper, getTableHeader, totalActivities, totalPatients]
  );
  const desktopOnlyColumns = ['totalActivities', 'totalPatients'];

  const defaultSort = [
    {
      id: 'totalPatients',
      desc: true,
    },
  ];
  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={
          <>
            <MetricDetailHeader label='Activities Per Patient Per Week' loading={loading} value={getAverageString()} />
            <Separator />
            <MetricDetailHeader
              label='Activities'
              loading={loading}
              value={totalActivities > 0 ? totalActivities.toString() : '0'}
            />
            <Separator />
            <MetricDetailHeader
              label='Patients'
              loading={loading}
              value={totalPatients > 0 ? totalPatients.toString() : '0'}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <TableTitleContainer>
          <Card.Title>Activities Per Patient Per Week Overview</Card.Title>
          <LocalExport
            columns={columns}
            data={tableData}
            loading={loading}
            filePrefix='activities-per-patient-per-week'
          />
        </TableTitleContainer>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          desktopOnlyColumns={desktopOnlyColumns}
          loading={loading}
        />
      </TableContainer>
    </>
  );
};

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

export default ActivitiesPerPatientWeekDetail;
