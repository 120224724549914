import { useEffect } from 'react';

import { useAuth } from 'context/auth';
import { useEnvironment } from 'context/environment';
import http from 'services/http';

export default function AxiosProvider({ children }) {
  const { token } = useAuth();
  const { environment } = useEnvironment();

  useEffect(() => {
    if (!token) return;

    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }, [token]);

  useEffect(() => {
    if (!environment) return;

    const domain = environment.OLIO_API_DOMAIN ?? environment.INTEGRATION_API_DOMAIN;
    const url = `${domain}/${environment?.OLIO_API_DOMAIN ? 'api' : 'olio'}`;

    http.defaults.baseURL = url;
  }, [environment]);

  return children;
}
