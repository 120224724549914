import React, { useCallback, useState } from 'react';

import Attachment from 'models/Attachment';

import ImageViewer from './ImageViewer';
import Thumbnail from './Thumbnail';

type AttachedImageType = {
  removed?: boolean;
  attachment: Attachment;
};
export default function AttachedImage({ removed, attachment }: AttachedImageType) {
  const [modalState, setModalState] = useState(false);

  const handleToggleImageModal = useCallback(() => {
    if (removed) return;
    setModalState(!modalState);
  }, [modalState, removed]);

  return (
    <div>
      <Thumbnail attachment={attachment} onClick={handleToggleImageModal} removed={removed ?? false} />
      <ImageViewer attachment={attachment} onClose={handleToggleImageModal} show={modalState} />
    </div>
  );
}
