import { FormatOptionLabelMeta } from 'react-select';
import styled from 'styled-components';

import { colors } from 'styles/theme/colors';

export const useAddressOptions = () => {
  const getOptionLabelProps = (option) => {
    const { name, address, client } = option;
    const city = address?.city;
    const state = address?.state;
    const clientName = client?.name;

    return { city, clientName, name, state };
  };
  const formatOptionForMenu = (option, selectValue) => {
    const { city, clientName, name, state } = getOptionLabelProps(option);

    const isSelectedValue = selectValue.some((value) => value.id === option.id);

    const address = city && state ? ` — ${city}, ${state}` : '';

    return (
      <StyledOption>
        {name}
        <StyledAddress selectedValue={isSelectedValue}>
          {clientName}
          {address}
        </StyledAddress>
      </StyledOption>
    );
  };
  const formatOptionForValue = (option) => {
    const { name } = option;

    return <span>{name}</span>;
  };

  const formatOptionLabel = (option: any, { context, selectValue }: FormatOptionLabelMeta<any>) => {
    return context === 'value' ? formatOptionForValue(option) : formatOptionForMenu(option, selectValue);
  };

  const getOptionLabel = (option) => {
    const { city, state, clientName, name } = getOptionLabelProps(option);

    return `${name} ${clientName} - ${city}, ${state}`;
  };

  return {
    formatOptionLabel,
    getOptionLabel,
    minMenuHeight: 300,
  };
};

const StyledOption = styled.span`
  display: flex;
  flex-direction: column;
`;

const StyledAddress = styled.span<{ selectedValue: boolean }>`
  color: ${({ selectedValue }) => (selectedValue ? colors.white : colors.black50)};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
