import { GenericAbortSignal } from 'axios';

import { PHYSICIAN_TEAM } from 'constants/filterKeysConstants';
import ImportedPatient from 'models/ImportedPatient';
import { Paginated } from 'models/Paginated';
import { PatientOptions } from 'models/Patient';
import { BaseIndexQueryParams, BaseQueryParams } from 'services/api/types';
import http from 'services/http';
import { PatientFilterState } from 'stores/patientFiltersStore';

export const importedPatientsQueryKeys = {
  index: ['importedPatients'] as const,
  show: (id: string) => ['importedPatients', id] as const,
};

function serializeFilters(filters: IndexParams['filters']) {
  return Object.entries(filters).reduce((acc, [key, values]) => {
    if (!values.length) return acc;

    if (key === PHYSICIAN_TEAM) {
      acc[key] = values.map((x) => x.name).join(',');
    } else {
      acc[key] = values.map((x) => x.id).join(',');
    }

    return acc;
  }, {});
}

type IndexParams = {
  filters: PatientFilterState;
} & BaseIndexQueryParams;

export async function importedPatientsIndex({ filters, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return http
    .get<Paginated<PatientOptions>>(`/imported_patients`, { params: serializedParams, signal })
    .then((response) => ({
      ...response.data,
      data: response.data.data.map((x) => new ImportedPatient(x)),
    }));
}

type ShowParams = {
  id: string;
} & BaseQueryParams;
export async function showImportedPatient(params: ShowParams) {
  return http
    .get<PatientOptions>(`/imported_patients/${params.id}`, { params })
    .then((response) => new ImportedPatient(response.data));
}
