import { useMemo } from 'react';
import styled from 'styled-components';

import { useEnvironment } from 'context/environment';
import { useProfile } from 'context/profile';
import AllPatientsIcon from 'svg/AllPatientsIcon';
import ConnectPatientsIcon from 'svg/ConnectPatientsIcon';
import GearIcon from 'svg/GearIcon';
import InsightsIcon from 'svg/InsightsIcon';
import PatientPortfolioIcon from 'svg/PatientPortfolioIcon';

import SideNavLink from './SideNavLink';

const SideNavbar = () => {
  const { environment } = useEnvironment();
  const { profile } = useProfile();

  const showConnectPatients = useMemo(() => {
    return profile.isAcute && (profile.isAdmin || (!profile.isPhysicianTeamUser && !profile.isAffiliateUser));
  }, [profile]);

  return (
    <NavContainer>
      <NavContent>
        <SideNavLink to='/patients' title='Patient Portfolio' icon={<PatientPortfolioIcon width={18} height={18} />} />
        <SideNavLink to='/all-patients' title='All Patients' icon={<AllPatientsIcon width={18} height={18} />} />
        <SideNavLink
          to='/connect-patients'
          title='Connect Patients'
          icon={<ConnectPatientsIcon width={18} height={18} />}
          visible={showConnectPatients}
        />
        <SideNavLink to='/insights' title='Olio Insights' icon={<InsightsIcon width={18} height={18} />} />
        <SideNavLink
          to='/'
          domain={environment?.ADMIN_UI_DOMAIN}
          title='Admin'
          icon={<GearIcon width={18} height={18} />}
          visible={profile.isAdmin}
        />
      </NavContent>
    </NavContainer>
  );
};

export default SideNavbar;

const NavContainer = styled.div`
  height: 100%;
  min-width: ${({ theme }) => theme.dimensions.sideNavWidth};
  background-color: ${({ theme }) => theme.colors.black};
  flex: 0;
  display: none;

  @media ${({ theme }) => theme.devices.desktop} {
    display: flex;
  }
`;

const NavContent = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
