import { create } from 'zustand';

type Actions = {
  setSelectViewOpen: (open: boolean) => void;
};

type State = {
  selectViewOpen: boolean;
  actions: Actions;
};

export const useSelectViewStore = create<State>((set) => ({
  selectViewOpen: false,
  actions: {
    setSelectViewOpen: (open) => set({ selectViewOpen: open }),
  },
}));

export const useSelectViewActions = () => useSelectViewStore((state) => state.actions);
