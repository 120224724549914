import styled from 'styled-components';

const ButtonGroup = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  gap: 8px;

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

export default ButtonGroup;
