import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import DischargeInformation, {
  DischargeFormMeta,
  DischargeFormValues,
} from 'components/dischargeForm/DischargeInformation';
import dischargeFormValidation from 'components/dischargeForm/validation';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import StandardModal, { PatientName } from 'components/shared/StandardModal';
import { Form, FormProvider } from 'context/form';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types';
import LocationEpisode from 'models/LocationEpisode';
import { ReviewStatus } from 'models/Review';
import AltcsApplication from 'models/reviews/AltcsApplication';
import { updateReview } from 'services/api/reviews';
import { useToastActions } from 'stores/toastStore';

type Props = {
  setShow: (b: boolean) => void;
  onSuccess: () => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  altcsApplication: AltcsApplication;
};

export default function AltcsDenialModal(props: Props) {
  const { altcsApplication, setShow, patientName, locationEpisode, onSuccess } = props;
  const { addToast } = useToastActions();

  const { mutate } = useMutation({
    mutationFn: updateReview,
  });

  const cancel = () => {
    setShow(false);
  };

  const handleSubmit = async (
    values: FormValues<DischargeFormValues>,
    _meta: FormMeta<DischargeFormMeta>,
    helpers: FormHelpers<DischargeFormValues, DischargeFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);
    delete values.note?.escalationType;

    mutate(
      {
        id: altcsApplication.id,
        status: ReviewStatus.REJECTED,
        dischargeReasonId: values.dischargeReason?.id ?? undefined,
        dischargedGroupId: values.dischargedGroup?.id ?? undefined,
        dischargedGroupTypeId: values.dischargeReason?.dischargedToGroupTypeId ?? undefined,
        dischargedLocationOther: values.dischargedLocationOther ?? undefined,
        againstMedicalAdvice: values.againstMedicalAdvice || false,
        note: values.note
          ? {
              ...values.note,
              attachments: values.note.attachments?.map((upload) => upload.serialize()),
            }
          : undefined,
      },
      {
        onSuccess: () => {
          onSuccess();
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  return (
    <StandardModal title='Confirm ALTCS Pending' onCancel={cancel} data-cy='altcsDenialConfirmationModal'>
      <PatientName>Patient: {patientName}</PatientName>
      <FormProvider<DischargeFormValues, DischargeFormMeta>
        onSubmit={handleSubmit}
        yupSchema={dischargeFormValidation()}>
        {({ valid, meta, dirty }) => (
          <Form>
            <DischargeInformation locationEpisode={locationEpisode} />
            <Actions>
              <Button variant='ghost' type='button' onClick={cancel}>
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={!valid || !dirty || meta.isUploadingAttachments}
                loading={meta.isSubmitting}>
                Confirm
              </Button>
            </Actions>
          </Form>
        )}
      </FormProvider>
    </StandardModal>
  );
}

const Actions = styled(ButtonGroup)`
  margin-top: 24px;
  justify-content: flex-end;
`;
